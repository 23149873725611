//Form.js

// import React, { useEffect, useState } from "react";

// // Form Module css
// import style from "./Form.module.css";

// // MUI Components
// import { Box, Stepper, Step, StepLabel } from "@mui/material";
// import classNames from "classnames";

// // Importing Forms
// import Step1 from "./Steps/Step-1";
// import Step2 from "./Steps/Step-2";
// import Step3 from "./Steps/Step-3";
// import Step3_Commercial from "./Steps/Step-3-Commercial";
// import Step4 from "./Steps/Step-4";
// // import Step6 from "./Steps/Step-6-ori";

// // Redux Hooks
// import { useDispatch, useSelector } from "react-redux";

// // Actions
// import {
//   getProductRisks,
//   getProductRisksAttributes,
//   getProductTypeDetails,
//   UpdateSteps,
// } from "store/actions/products";
// import { login } from "store/actions/auth";
// import useWindowDimensions from "utils/useWindowDimension";
// import { attribute } from "dom-helpers";
// import { RESTRICTED_ATTRIBUTES } from "utils/constants";
// import { getAgentOnboarding } from "store/actions/auth";

// const Form = () => {
//   // Dynamic width from Screen sizes
//   const { width } = useWindowDimensions();

//   // Initial steps or presisted if user resume
//   const intitalStep = useSelector((state) => state.products.activeStep);
//   const commercialStep = useSelector((state) => state.products.isCommercial);

//   // Active steps according to forms
//   const [activeStep, setActiveStep] = useState(intitalStep || 0);

//   // Loader state
//   // const [isLoading, setIsLoading] = useState(false);

//   // Dispatch Hooks
//   const dispatch = useDispatch();

//   // Function performs Next Step
//   const handleNext = () => {
//     window.scrollTo(0, 0);
//     setActiveStep((activeStep) => activeStep + 1);
//   };

//   // Function performs previous Step
//   const handleBack = (step) => {
//     window.scrollTo(0, 0);
//     setActiveStep((activeStep) => activeStep - 1);
//   };

//   // Updating Active steps to the store
//   useEffect(() => {
//     dispatch(UpdateSteps(activeStep));
//   }, [activeStep, dispatch]);

//   // Risk from the Product
//   const risks = useSelector((state) =>
//     state.products?.risks?.risks?.sort((a, b) =>
//       a.sequence < b.sequence ? 1 : -1
//     )
//   );

//   // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
//   const risksAttributes = useSelector(
//     (state) => state.products?.riskAttributes
//   );

//   const agentCode = useSelector((state) => state.auth.agentCode);

//   const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

//   // Consolidating the risks and attributes (Array of the Risk attributes)
//   useEffect(() => {
//     (async () => {
//       try {
//         await dispatch(login());
//         if(Object.keys(agentOnBoarding ||  {}).length ===0){
//           await dispatch(getAgentOnboarding(agentCode));
//         }
//         if (!risksAttributes.length) {
//           await dispatch(getProductRisks());
//         }
//       } catch (err) {}
//     })();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [dispatch]);

//   useEffect(() => {
//     if (risks?.length) {
//       (async () => {
//         await dispatch(getProductTypeDetails());
//         risks?.forEach(async (risk, index) => {
//           await dispatch(getProductRisksAttributes(risk.instanceId, index));
//           if (index === risks.length - 1) {
//           }
//         });
//       })();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [risks, dispatch]);

//   const switchSteps = (step) => {
//     switch (step) {
//       case 0:
//         return (
//           <Step1
//             handleNext={handleNext}
//             data={risksAttributes?.find((attr) => attr.code === "DIR")}
//           />
//         );
//       case 1:
//         return <Step2 handleNext={handleNext} handleBack={handleBack} />;
//       case 2:
//         if(commercialStep){
//           return(<Step3_Commercial
//             handleNext={handleNext}
//             handleBack={handleBack}
//             activeStep={activeStep}
//             data={{...risksAttributes.find((attr) => attr.code === "DRD"), attributes: risksAttributes.find((attr) => attr.code === "DRD").attributes.filter((x) => !RESTRICTED_ATTRIBUTES.includes(x.instanceId))}}
//           />
//           );
//         }else{
//         return (
//           <Step3
//             handleNext={handleNext}
//             handleBack={handleBack}
//             activeStep={activeStep}
//             data={{...risksAttributes.find((attr) => attr.code === "DRD"), attributes: risksAttributes.find((attr) => attr.code === "DRD").attributes.filter((x) => !RESTRICTED_ATTRIBUTES.includes(x.instanceId))}}
//           />
//         );
//       }

//       case 3:
//         return (
//           <Step4
//             handleNext={handleNext}
//             handleBack={handleBack}
//             activeStep={activeStep}
//             data={risksAttributes[activeStep]}
//           />
//         );
//       default:
//         return;
//     }
//   };

//   return (
//     <div className="container mt-5 mb-5">
//       <div className="d-flex justify-content-center flex-column">
//         {/* <------- HEADER --------> */}
//         <h3 className={style.header}>
//           Drone insurance in 4 easy steps
//         </h3>

//         {/* <------- STEPPER STARTS --------> */}

//         <Box className="mt-5">
//           <Stepper activeStep={activeStep} alternativeLabel>
//             {steps.map((label, index) => (
//               <Step key={index}>
//                 <StepLabel>{width > 430 ? label.label : ""}</StepLabel>
//               </Step>
//             ))}
//           </Stepper>
//         </Box>

//         {/* <------- STEPPER ENDS --------> */}

//         {/* <------- FORMS STARTS --------> */}

//         <div className={classNames("container", style.boxBorder)}>
//           {switchSteps(activeStep)}
//         </div>
//         {/* <------- FORMS ENDS --------> */}
//       </div>
//     </div>
//   );
// };

// const steps = [
//   {
//     label: "Choose your cover",
//     title: "Cyber SME Business",
//     sub: "Choose a limit of indemnity between R 250 000 and R10 000 000",
//   },
//   {
//     label: "Get a quote",
//     title: "Cyber SME Business",
//     sub: "Select a quote",
//   },
//   {
//     label: "Enter Your Personal & Payment Details",
//     title: "Cyber SME Business",
//     sub: "Add your details",
//   },
//   {
//     label: "Receive Your Policy Confirmation",
//     title: "Cyber SME Business",
//     sub: "Add your details",
//   },
// ];

// export default Form;

////--Commercial----//////
import React, { useEffect, useState } from "react";

// Form Module css
import style from "./Form.module.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// MUI Components
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import classNames from "classnames";

// Importing Forms
import Step1 from "./Steps/Step-1";
import Step2 from "./Steps/Step-2";
import Step3 from "./Steps/Step-3";
import Step3_Commercial from "./Steps/Step-3-Commercial";
import Step4 from "./Steps/Step-4";
import PaymentCommercial from "../Forms/Payment-Commercial";
// import Step6 from "./Steps/Step-6-ori";

// Redux Hooks
import { useDispatch, useSelector } from "react-redux";
import GIFLoader from "../../assets/images/loader.gif";

// Actions
import {
  getProductRisks,
  getProductRisksAttributes,
  getProductTypeDetails,
  UpdateSteps,
} from "store/actions/products";
import { login, updateLoading, getAgentDetails } from "store/actions/auth";
import useWindowDimensions from "utils/useWindowDimension";
import { attribute } from "dom-helpers";
import { RESTRICTED_ATTRIBUTES } from "utils/constants";
import { getAgentOnboarding } from "store/actions/auth";
import CONSTANTS from "utils/constants";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

const Form = () => {
  // Dynamic width from Screen sizes
  const { width } = useWindowDimensions();

  // Initial steps or presisted if user resume
  const intitalStep = useSelector((state) => state.products.activeStep);
  const commercialStep = useSelector((state) => state.products.isCommercial);
  const companyDetails = useSelector((state) => state.auth.companyDetails);

  // Active steps according to forms
  const [activeStep, setActiveStep] = useState(intitalStep || 0);

  // Loader state
  // const [isLoading, setIsLoading] = useState(false);

  // Dispatch Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  // Function performs Next Step
  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep((activeStep) => activeStep + 1);
  };

  // Function performs previous Step
  const handleBack = (step) => {
    window.scrollTo(0, 0);
    setActiveStep((activeStep) => activeStep - 1);
  };

  function parseQuery(queryString) {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }

  // Updating Active steps to the store
  useEffect(() => {
    dispatch(UpdateSteps(activeStep));
  }, [activeStep, dispatch]);

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((a, b) =>
      a.sequence < b.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector(
    (state) => state.products?.riskAttributes
  );

  const agentCode = useSelector((state) => state.auth.agentCode);

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);
  const isLoading = useSelector((state) => state.auth.loading);

  // Consolidating the risks and attributes (Array of the Risk attributes)
  ///---without passing token----////
  // useEffect(() => {
  //     (async () => {
  //         await dispatch(login(commercialStep ? "COMMERCIAL_DRONE" : "DRONE"));
  //       if (agentCode !== parseQuery(location.search).token) {
  //         if (parseQuery(location.search).token) {
  //           await dispatch(getAgentDetails(parseQuery(location.search).token));
  //         } else {
  //           if (Object.keys(companyDetails).length === 0) {
  //             await dispatch(getAgentDetails(process.env.REACT_APP_ENV_TYPE === 'PROD' ? "SOFS-4" : "TPI-1"));
  //           }
  //         }
  //       } else {
  //         dispatch(updateLoading(false));
  //       }
  //     })();
  // }, [commercialStep]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //         await dispatch(login(commercialStep ? "COMMERCIAL_DRONE" : "DRONE"));
  //         if(Object.keys(agentOnBoarding ||  {}).length ===0){
  //           await dispatch(getAgentOnboarding(agentCode,commercialStep ? "COMMERCIAL_DRONE" : "DRONE"));
  //         }
  //         if (!risksAttributes.length) {
  //           await dispatch(getProductRisks(commercialStep ? `${CONSTANTS.COMMERCIAL_PRODUCT_INSTANCE_ID}` : `${CONSTANTS.PRODUCT_INSTANCE_ID}`));
  //         }
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   })();
  // }, [dispatch,commercialStep]);

  ////----passing token in url---////
  useEffect(() => {
    (async () => {
      await dispatch(login(commercialStep ? "COMMERCIAL_DRONE" : "DRONE"));
      // if (agentCode !== parseQuery(location.search).token) {
      if (parseQuery(location.search).token) {
        await dispatch(getAgentDetails(parseQuery(location.search).token));
      }
      // else if (Object.keys(companyDetails).length === 0) {
      else if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentDetails(agentCode));
      }
      // }
      else {
        dispatch(updateLoading(false));
      }
    })();
  }, [commercialStep]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(login(commercialStep ? "COMMERCIAL_DRONE" : "DRONE"));
        if (parseQuery(location.search).token) {
          await dispatch(
            getAgentOnboarding(
              parseQuery(location.search).token,
              commercialStep ? "COMMERCIAL_DRONE" : "DRONE"
            )
          );
        }
        if (Object.keys(agentOnBoarding || {}).length === 0) {
          await dispatch(
            getAgentOnboarding(
              agentCode,
              commercialStep ? "COMMERCIAL_DRONE" : "DRONE"
            )
          );
        }
        if (!risksAttributes.length) {
          await dispatch(
            getProductRisks(
              commercialStep
                ? `${CONSTANTS.COMMERCIAL_PRODUCT_INSTANCE_ID}`
                : `${CONSTANTS.PRODUCT_INSTANCE_ID}`
            )
          );
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [dispatch, commercialStep]);

  useEffect(() => {
    if (risks?.length) {
      (async () => {
        await dispatch(
          getProductTypeDetails(
            commercialStep
              ? `${CONSTANTS.COMMERCIAL_PRODUCT_INSTANCE_ID}`
              : `${CONSTANTS.PRODUCT_INSTANCE_ID}`
          )
        );
        risks?.forEach(async (risk, index) => {
          await dispatch(getProductRisksAttributes(risk.instanceId, index));
          if (index === risks.length - 1) {
          }
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [risks, dispatch, commercialStep]);

  const switchSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <Step1
            handleNext={handleNext}
            data={risksAttributes?.find((attr) => attr.code === "DIR")}
          />
        );
      case 1:
        return <Step2 handleNext={handleNext} handleBack={handleBack} />;
      case 2:
        if (commercialStep) {
          return (
            <Step3_Commercial
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              data={{
                ...risksAttributes.find((attr) => attr.code === "DRD"),
                attributes: risksAttributes
                  .find((attr) => attr.code === "DRD")
                  .attributes.filter(
                    (x) => !RESTRICTED_ATTRIBUTES.includes(x.instanceId)
                  ),
              }}
            />
          );
        } else {
          return (
            <Step3
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              data={{
                ...risksAttributes.find((attr) => attr.code === "DRD"),
                attributes: risksAttributes
                  .find((attr) => attr.code === "DRD")
                  .attributes.filter(
                    (x) => !RESTRICTED_ATTRIBUTES.includes(x.instanceId)
                  ),
              }}
            />
          );
        }

      case 3:
        return (
          <Step4
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            data={risksAttributes[activeStep]}
          />
        );
      default:
        return;
    }
  };

  const switchStepsCommercial = (step) => {
    switch (step) {
      case 0:
        return (
          <Step3_Commercial
            handleNext={handleNext}
            data={risksAttributes?.find((attr) => attr.code === "APD")}
          />
        );
      case 1:
        return (
          <PaymentCommercial
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            // return <Step2 handleNext={handleNext} handleBack={handleBack} activeStep={activeStep}
          />
        );
      default:
        return;
    }
  };
  return !isLoading ? (
    // return (
    <div className="container mt-5 mb-5">
      <div className="d-flex justify-content-center flex-column">
        {/* <------- HEADER --------> */}
        {commercialStep ? (
          <>
            <h3 className={style.header}>
              Commercial Drone insurance in 2 easy steps
            </h3>
          </>
        ) : (
          <>
            <h3 className={style.header}>Drone insurance in 4 easy steps</h3>
          </>
        )}

        {/* <------- STEPPER STARTS --------> */}
        {commercialStep ? (
          <>
            <Box className="mt-5">
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps_commercial.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{width > 430 ? label.label : ""}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </>
        ) : (
          <>
            <Box className="mt-5">
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{width > 430 ? label.label : ""}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </>
        )}

        {/* <------- STEPPER ENDS --------> */}

        {/* <------- FORMS STARTS --------> */}

        <div className={classNames("container", style.boxBorderCommercial)}>
          {/* <div> */}
          {commercialStep
            ? switchStepsCommercial(activeStep)
            : switchSteps(activeStep)}
        </div>
        {/* <------- FORMS ENDS --------> */}
      </div>
    </div>
  ) : (
    <div className="gif-loader-full">
      <img src={GIFLoader} alt="GIF LOADER"></img>
    </div>
  );
};

const steps = [
  {
    label: "Choose your cover",
    title: "Cyber SME Business",
    sub: "Choose a limit of indemnity between R 250 000 and R10 000 000",
  },
  {
    label: "Get a quote",
    title: "Cyber SME Business",
    sub: "Select a quote",
  },
  {
    label: "Enter Your Personal & Payment Details",
    title: "Cyber SME Business",
    sub: "Add your details",
  },
  {
    label: "Receive Your Policy Confirmation",
    title: "Cyber SME Business",
    sub: "Add your details",
  },
];

const steps_commercial = [
  {
    label: "Enter Your Personal",
    title: "Cyber SME Business",
    sub: "Add your details",
  },
  {
    label: "Download Application",
    title: "Cyber SME Business",
    sub: "Add your details",
  },
];

export default Form;

