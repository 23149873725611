// initial state of the reducer
const initialState = {
  companyDetails: {},
  currentUserToken: null,
  agentCode: process.env.REACT_APP_ENV_TYPE === "PROD" ? "SOFS-4" : "TPI-1",
  loading: true,
  agentOnboarding: {},
};

// function to update state
function auth(state = initialState, action) {
  // destructuring the action from function
  const { type, payload } = action;

  // switch to match type and update payload
  switch (type) {
    case "LOGIN":
      return {
        ...state,
        ...payload,
      };

    case "AGENT_ONBOARDING":
      return{
        ...state,
        agentOnboarding: payload
      }

    case "STORE_COMPANY_DETAILS":
      return {
        ...state,
        companyDetails: payload,
        agentCode: action.agentCode
      }

    case "LOADING": 
      return {
        ...state,
        loading: payload
      }

    case "CURRENT_USER_TOKEN":
        return {
          ...state,
          currentUserToken: payload,
        };


    // return default state if the type doesnt match any case
    default:
      return state;
  }
}

export default auth;
