import classNames from "classnames";
import { Divider } from "components";
import React from "react";

// import stylesheet
import style from "./ProductCard.module.css";

const SummaryCard = ({
  containerStyle,
  title,
  policyNumber,
  policyHolder,
  premiumPaid,
  policyStartDate,
  type,
  policyRenewalDate,
  liability,
  theoryCourse,
  practicalCourse,
  policyEndDate,
  proratePremium,
  monthlyProratePremium,
  prorataPremiumFirstMonth,
  premiumPaidMonthly,
  selectedProduct,
  monthlyPremium,
  prorataPremium,
  policyResponse,
  companyDetails,
  debitDate,
}) => {
  return (
    <div
      className={classNames(style.container)}
      style={containerStyle && containerStyle}
    >
      <h3 className={style.title}>Policy: {title}</h3>
      <div
        className={classNames(
          "d-flex justify-content-between",
          style.policyPrimaryContainer
        )}
      >
        <h6 className={style.policyPrimary}>Policy Number: </h6>
        <h6 className={style.policyPrimary}>{policyNumber}</h6>
      </div>

      <div
        className={classNames(
          "d-flex justify-content-between",
          style.policySecondaryContainer
        )}
      >
        <h6 className={style.policySecondary}>Policy Holder: </h6>
        <h6 className={style.policySecondary}>{policyHolder}</h6>
      </div>

      <Divider />

      {type === "credit" ? (
        <div>
          <div
            className={classNames(
              "d-flex mt-3 justify-content-between",
              style.policyPrimaryContainer
            )}
          >
            <h6 className={style.policyPrimary}>Premium Paid: </h6>
            <h6 className={style.policyPrimary}>{premiumPaid}</h6>
          </div>

          <div
            className={classNames(
              "d-flex justify-content-between",
              style.policySecondaryContainer
            )}
          >
            <h6 className={style.policySecondary}>Annual Premium: </h6>
            <h6 className={style.policySecondary}>{prorataPremium}</h6>
          </div>


          <Divider />

          <div
            className={classNames(
              "d-flex justify-content-between mt-3",
              style.policySecondaryContainer
            )}
          >
            <h6 className={style.policySecondary}>Policy Start Date: </h6>
            <h6 className={style.policySecondary}>{policyStartDate}</h6>
          </div>

          <div
            className={classNames(
              "d-flex justify-content-between",
              style.policySecondaryContainer
            )}
          >
            <h6 className={style.policySecondary}>Policy End Date: </h6>
            <h6 className={style.policySecondary}>{policyEndDate}</h6>
          </div>

          <div
            className={classNames(
              "d-flex justify-content-between",
              style.policySecondaryContainer
            )}
          >
            <h6 className={style.policySecondary}>Policy Renewal Date: </h6>
            <h6 className={style.policySecondary}>{policyRenewalDate}</h6>
          </div>

          <Divider />

          <div
            className={classNames(
              "d-flex mt-3 justify-content-between",
              style.policyPrimaryContainer
            )}
          >
            <h6 className={style.policyPrimary}>{liability.name} Amount: </h6>
            <h6 className={style.policyPrimary}>{liability.value}</h6>
          </div>

          <div
            className={classNames(
              "d-flex justify-content-between",
              style.policySecondaryContainer
            )}
          >
            <h6 className={style.policySecondary}>Accredited {theoryCourse.name}: </h6>
            <h6 className={style.policySecondary}>{theoryCourse.value}</h6>
          </div>

          <div
            className={classNames(
              "d-flex justify-content-between",
              style.policySecondaryContainer
            )}
          >
            <h6 className={style.policySecondary}>Accredited {practicalCourse.name}: </h6>
            <h6 className={style.policySecondary}>{practicalCourse.value}</h6>
          </div>
          
        </div>
      ) : (
        <div>
          <div
            className={classNames(
              "d-flex mt-3 justify-content-between",
              style.policyPrimaryContainer
            )}
          >
            <h6 className={style.policyPrimary}>First Month's Premium: </h6>
            <h6 className={style.policyPrimary}>{prorataPremiumFirstMonth}</h6>
          </div>

          <div
            className={classNames(
              "d-flex justify-content-between",
              style.policySecondaryContainer
            )}
          >
            <h6 className={style.policySecondary}>Pro Rata Premium: </h6>
            <h6 className={style.policySecondary}>{prorataPremium}</h6>
          </div>

          <div
            className={classNames(
              "d-flex justify-content-between",
              style.policySecondaryContainer
            )}
          >
            <h6 className={classNames(style.policySecondary, "m-0 p-0")}>
              Monthly Premium:{" "}
            </h6>
            <h6 className={style.policySecondary}>
              {monthlyPremium}
            </h6>
          </div>

          <div
            className={classNames(
              "d-flex justify-content-between",
              style.policyPrimaryContainer
            )}
          >
            <h6 className={style.policyPrimary}>First Debit Date: </h6>
            <h6 className={style.policyPrimary}>{debitDate}</h6>
          </div>
          <p className={style.spanText}>*and then monthly there after</p>

          <Divider />

          <div
            className={classNames(
              "d-flex justify-content-between mt-2",
              style.policySecondaryContainer
            )}
          >
            <h6 className={style.policySecondary}>Policy Start Date: </h6>
            <h6 className={style.policySecondary}>{policyStartDate}</h6>
          </div>

          <div
            className={classNames(
              "d-flex justify-content-between",
              style.policySecondaryContainer
            )}
          >
            <h6 className={style.policySecondary}>Policy End Date: </h6>
            <h6 className={style.policySecondary}>{policyEndDate}</h6>
          </div>

          <div
            className={classNames(
              "d-flex justify-content-between",
              style.policySecondaryContainer
            )}
          >
            <h6 className={style.policySecondary}>Policy Renewal Date: </h6>
            <h6 className={style.policySecondary}>{policyRenewalDate}</h6>
          </div>
        </div>
      )}

      <Divider />

      <div className={style.cover}>Claims Contact Details:-</div>
      <ul className="mt-2">
        <li className={style.description_1}>info@dronecovered.com</li>
        <li className={style.description_1}>
          +27 87 550 9796 | info@dronecovered.com
        </li>
      </ul>
    </div>
  );
};

export default SummaryCard;
