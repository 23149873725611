// default imports for react project
import React from "react";

// import default styles from react-bootstrap package
import { Col, Row } from "react-bootstrap";

// import custom component
import { Divider, VerticalSpacer } from "../index";
import Button from "../Buttons/Button";

// import classname to combine module css and normal css
import classNames from "classnames";

// import stylesheet
import style from "./ProductCard.module.css";
import { quoteFooterImg } from "assets/images/images";
import { Checkbox, FormControlLabel } from "@mui/material";
import { H6Grey } from "components/Heading";
import { H4Grey } from "components/Heading";
import { useSelector } from "react-redux";

// React functional component starts here..
const ProductCard = ({
  title,
  sub,
  liability,
  theoryCourse,
  practicalCourse,
  price,
  onPress,
  description,
  disabled,
  excess,
  containerStyle,
  footer,
  tCChecked,
  monthlyPremium,
  annualTotal,
  prorataPremium,
  prorataPremiumFirstMonth,
  debitDate,
  handleChange,
  errors,
  termsAndConditions,
  prorataAnnualPremium
}) => {

  const paymentFrequency = useSelector(state => state.products?.paymentFrequency);

  const ANNUALLY = 'Annually';
  // return jsx to display on screen
  return (
    <div
      className={classNames(style.container)}
      style={containerStyle && containerStyle}
    >
      <h3 className={style.title}>{title}</h3>
    
      {!termsAndConditions && <>
        <h6 className={style.sub}>{sub}</h6>
        <h5 className={style.price}>{price}</h5>

        <Divider />
        <VerticalSpacer rows={1} />
      </>}

     {termsAndConditions && <>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <p className={style.turnover}>
            {paymentFrequency ===  ANNUALLY ?  'Annual Premium:' : 'Pro rata Premium 1st Month:' }
          </p>
          <p className={style.coverNumber}>
            {paymentFrequency === ANNUALLY ? annualTotal : prorataPremiumFirstMonth}
          </p>
        </div>
        <Divider />
        <VerticalSpacer rows={1} />
       {paymentFrequency !== ANNUALLY && <div className="d-flex justify-content-between align-items-center mb-2">
          <p className={style.turnover}>
            Pro rata Premium (once off): 
          </p>
          <p className={style.turnoverNumber}>
            {prorataPremium}
          </p>
        </div>}
        <div 
          className="d-flex flex-row justify-content-between align-items-center"
        >
          <p className={style.cover1}>
            { paymentFrequency === ANNUALLY ? 'Pro-rata Premium 1st year' : 'Monthly Premium:' }
          </p>
          <p className={style.coverNumber}>
            {paymentFrequency === ANNUALLY ? prorataAnnualPremium : monthlyPremium}
          </p>
        </div>
        <VerticalSpacer rows={1} />
        <div className="d-flex justify-content-between align-items-center">
          <p className={style.cover1}>
            1st Debit Date:  <br/>
            <span className={style.small}>{paymentFrequency === ANNUALLY ? 'Annually' : 'Month'} thereafter</span>
          </p>
          <p className={style.coverNumber}>
            {debitDate}
          </p>
        </div>
        <VerticalSpacer rows={1} />
        <Divider />
        
      </>}

      <p className={style.cover}>
        {liability.name} Amount: {liability.value}
      </p>
      <p className={style.turnover}>
        Accredited {theoryCourse.name}: {theoryCourse.value}
      </p>
      <p className={style.excess}>
        Accredited {practicalCourse.name}: {practicalCourse.value}
      </p>

      <Divider />

      {termsAndConditions && <> <FormControlLabel
                className="mt-3 mb-2"
                onClick={handleChange}
                control={
                  <>
                    <div className="d-flex align-items-start">
                      <Checkbox
                        name="tCChecked"
                        id="tCChecked"
                        checked={tCChecked ? "off" : null}
                        sx={{
                          "&.Mui-checked": {
                            color: "#DA291C",
                            fontSize: 8,
                          },
                        }}
                      />
                      <label htmlFor="tCChecked">
                        <div style={{marginTop: 10}}> 
                          <b > I confirm and understand, should I claim:</b>
                        </div>
                    
                        <ul>
                          <li>
                            My flight records will be checked.
                          </li>
                          <li>I am only covered for legal drone flights.</li>
                          <li>I had written permission to fly on private property or within 50m of roads, buildings, people.</li>
                          <li>My flight was not within 10km of an airport and was below 155ft.</li>
                        </ul>
                        <ul>
                          <li>
                          <b>Your insurance permiums</b> will be collected by debit order from your account as per the Payment Terms and Conditions. I hereby declare that I have read the <a className="text-danger" href="/payment-terms-and-conditions" target="_blank" >Payment Terms & Conditions</a> and accept it.
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <b>I have read the</b> <a className="text-danger" href="/terms-and-conditions" target="_blank" ><b>Terms and Conditions</b></a>
                          </li>
                        </ul>
                      </label>
                    </div>
                  </>
                }
                label=""
              />
              <div className="errors pb-3">{errors && errors?.tCChecked}</div></>}
      {!disabled && footer && (
        <Row>
          <Col sm={6} className="mt-4">
            <Button
              border="1px solid #D0D0D0"
              onClick={() => price ? onPress() : null }
              shadow={false}
              name="Select quote"
              bg="white"
              color="#DA291C"
            />
          </Col>
          <Col sm={6} className="mt-4">
            <img src={quoteFooterImg} alt="footerimg" width="auto" />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProductCard;
