import Button from "components/Buttons/Button";
import React, { useEffect, useState } from "react";

// <--------- React Bootstrap -------->
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { sendOTP } from "store/actions/products";
import { issuePolicy } from "store/actions/products";
import { validate } from "validate.js";


import ProductCard from "components/Forms/ProductCard";

// <--------- Essential Components -------->
import { Divider, Select, TextInput, DatePicker } from "../../../components";
import ButtonWithDownArrow from "components/Buttons/ButtonWithArrow";
import { toast } from "react-toastify";
import GIFLoader from "../../../assets/images/loader.gif";

// <------------------- Actions --------------------->
import {
  validateOTP,
  clearState,
  updateTryCount,
} from "store/actions/products";
import useWindowDimensions from "utils/useWindowDimension";


const Step6 = ({ handleBack }) => {
  // Attributes  i.e Account holder or Account number
  const [attributes, setAttributes] = useState({});

  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [disabledDoubleClick, setDisabledDoubleClick] = useState(false);


  // Loader state
  const [isLoading, setIsLoading] = useState(false);

  const [submitClicked, setSubmitClicked] = useState(false);

  // Bank details data from the store
  const banks = useSelector((state) => state?.products?.bankDetails);



  // React-router dom
  const history = useHistory();
  const location = useLocation();

  const {width} = useWindowDimensions();

  // functions performs parsing the query from the location URL
  function parseQuery(queryString) {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }

  // Variable defines the status of the form from the payment gateway.
  const status = parseQuery(location.search);

  // Variable defines number of failed tries.
  const tryCount = useSelector((state) => state?.products?.tryCount);

  // Reduct hooks
  const dispatch = useDispatch();

  useEffect(() => {
    // if the status is failed for more then 3 times, it will redirect to the homepage..
    if (status.status === "error") {
      if (tryCount >= 3) {
        toast.error(
          "You've tried maximum submission. Please try again after sometime."
        );
        setTimeout(() => {
          history.push("/");
          dispatch(clearState());
        }, 3000);
      } else {
        dispatch(updateTryCount(tryCount + 1));
        toast.error("Payment Failed");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const selectedProduct = useSelector(
    (state) => state?.products?.selectedProduct
  );

  // Handling synthetic events
  const handleChange = (evt) => {
    let obj = {};
    if (evt.target.name === "tCChecked") {
      obj = {
        ...attributes,
        tCChecked: attributes.tCChecked ? null : true,
      };
      setAttributes(obj);
    } else {
      obj = {
        ...attributes,
        [evt.target.name]: evt.target.value,
      };
      setAttributes(obj);
    }

   if(submitClicked){
    const errors = validate(obj, debitSchema);
    setErrors(errors);
   }
  };
  // policy response form CreatePolicy from the store
  const policyResponse = useSelector((state) => state?.products?.policyResponse);

  const [errors, setErrors] = useState({});

  const [mobileOTP, setMobileOTP] = useState("");
  // function performs the sending the OTP
  const handleSendOTP = async () => {
    if(attributes.tCChecked === null || attributes.tCChecked === undefined){
      setIsChecked(true);
      return;
    }else{
      setIsChecked(false)
    }
    setSubmitClicked(true)
    try {
      setDisabledDoubleClick(true)
      const errors = validate(attributes, debitSchema);
      if (errors) {
        setErrors(errors);
        setTimeout(() => {setDisabledDoubleClick(false);}, 60000);
        return;
      }
      const res = await dispatch(sendOTP());
      // setMobileOTP(res.data);
      setMobileOTP(res?.data?.data);
      setIsOTPSent(true);
      setTimeout(() => {setDisabledDoubleClick(false);}, 60000);
    } catch (err) {
      setTimeout(() => {setDisabledDoubleClick(false);}, 60000);
    }
  };

  // functions perform submitting the form
  const handleSubmit = async () => {
    try {
      // <---- Errors check ---->
      const errors = validate(attributes, debitSchema);
      if (errors) {
        setErrors(errors);
        return;
      }

      setIsLoading(true);

      // Validating the OTP
      const res = await dispatch(validateOTP(mobileOTP));

      if (res) {
        // Dispatch the policy values and hitting the issue policy API.
        dispatch(
          issuePolicy({
            externalReference: policyResponse.externalReference,
            policySaleReference: policyResponse.policySaleReference,
            comment: "Issue Sale from Web App",
            policies: [
              {
                policyReference:
                  policyResponse.policies?.length &&
                  policyResponse.policies[0].policyReference,
                bankAccounts: [
                  {
                    externalReference: "CREATE_BANK_100",
                    type: attributes.type,
                    pennyDropStatus: "verified",
                    pennyDropMessage: "Verified by XYZ",
                    bank: attributes.bank,
                    accountNumber: attributes.accountNumber,
                    accountHolder: attributes.accountHolder,
                    branch: attributes.branch,
                    branchCode: attributes.branchCode,
                    iban: "IB-200",
                    swift: "SW-200",
                  },
                ],
              },
            ],
            webhook: {
              webhookUrl:
                "https://briisk-i-dev-policies.azurewebsites.net/webhook/saveWebhook",
            },
          })
        );
        setIsLoading(false);
        // Redirects to payment-success
        history.push({
          pathname: "/payment-success",
          state: { type: "DEBIT" },
        });
      } else {
        alert("Wrong OTP. Please try again later.");
        setIsLoading(false);
      }
    } catch (err) {}
  };

  // Company details from the store
  const companyDetails = useSelector((state) => state.products.companyDetails);

  const handleBackButton = () => {
    handleBack(4);
  };

  const getProrate = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => { const val = a.split(":"); obj[val[0]] = val[1]; })
    return obj;
  }

  const decimalCheck = (string) => {
    if (string.split(".").length <= 1) {
      return `${string}.00`;
    } else  if(string.split(".")[1].length <= 1){
      return `${string}0`;
    }else {
      return string;
    }
  };

  return (
    <div>
      {/* <---------- Title -------> */}
      <h4>Purchase Summary</h4>

      {/* <------- Sub title ------> */}
      <h6>Details</h6>

      <Divider row={2} />

      {/* <------- Attributes Starts ------> */}
      <div>
        <div className={`d-flex justify-content-center mt-4 align-items-start ${width > 700 ? 'flex-row' : 'flex-column'}  w-100`}>
          <ProductCard
            title={selectedProduct?.name}
            sub={
              selectedProduct?.body.risks[0].attributes[3].value === "Monthly"
                ? "Total monthly payment"
                : "One off annual payment"
            }
            liability={selectedProduct?.body.risks[0].attributes[4]}
            theoryCourse={selectedProduct?.body.risks[0].attributes[1]}
            practicalCourse={selectedProduct?.body.risks[0].attributes[2]}
            price={`R ${decimalCheck(parseFloat(selectedProduct?.rating.price.toFixed(2))
              .toLocaleString("fi-FI")
              .replaceAll(",", "."))}
              ${
                selectedProduct?.body.risks[0]
                  .attributes[3].value === "Monthly"
                  ? "pm"
                  : "pa"
              }`}
            prorataPremiumFirstMonth={`R ${decimalCheck(parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message)?.prorataPremium).toLocaleString("fi-FI").replaceAll(",", "."))}`}
            monthlyPremium={`R ${decimalCheck(parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message)?.monthlyPremium).toLocaleString("fi-FI").replaceAll(",", "."))} pm`}
            prorataPremium={`R ${decimalCheck(parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message)?.annualPremium).toLocaleString("fi-FI").replaceAll(",", "."))}`}
            annualTotal={`R ${decimalCheck(parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message)?.annualTotal).toLocaleString("fi-FI").replaceAll(",", "."))}`}
            prorataAnnualPremium={`R ${decimalCheck(parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message)?.prorataAnnualPremium).toLocaleString("fi-FI").replaceAll(",", "."))}`}
            debitDate={selectedProduct?.body.risks[0].attributes[6]?.value}
            footer={false}
            pr
            tCChecked={attributes.tCChecked}
            handleChange={handleChange}
            errors={errors}
            termsAndConditions={true}
          />
          <div className={`d-flex align-items-center flex-column ${width > 770 ? 'w-50' : 'w-100'}`}>
          <Col xs={12} md={8} >
            <TextInput
              label="Account holder name"
              //placeholder="- full name"
              value={attributes.accountHolder}
              name="accountHolder"
              onChange={handleChange}
              errors={errors && errors.accountHolder}
            />
            <TextInput
              label="Account number"
              //placeholder="- Your account number"
              value={attributes.accountNumber}
              name="accountNumber"
              onChange={handleChange}
              errors={errors && errors.accountNumber}
            />
            <Select
              label="Account type"
              //placeholder="- Select type"
              options={["Savings", "Current"]}
              value={attributes.type}
              name="type"
              droneTheory="YES"
              onChange={handleChange}
              errors={errors && errors.type}
            />
            <Select
              label="Bank name"
              //placeholder="- Select your bank"
              options={banks.items.map((bank) => bank.code)}
              value={attributes.bank}
              name="bank"
              droneTheory="YES"
              onChange={(e) => {
                handleChange(e);
                setAttributes({
                  ...attributes,
                  bank: e.target.value,
                  branchCode: banks.items.find(
                    (find) => find.code === e.target.value
                  )?.value2,
                });
              }}
              errors={errors && errors.bank}
            />
            <TextInput
              label="Branch Code"
              //placeholder="- Your branch code"
              value={attributes.branchCode}
              name="branchCode"
              onChange={handleChange}
              disabled={true}
              errors={attributes.branchCode ? null : errors.branchCode}
            />
          </Col>
          
          <Col xs={12} md={8}>
                <Button
                  name="Request OTP"
                  color="#24B03C"
                  bg="#fff"
                  containerStyle={{
                    width: "100%",
                  }}
                  disabled={disabledDoubleClick}
                  onClick={handleSendOTP}
                  border="1px solid #24B03C"
                />
                {isChecked && (
                <>
                <div className="errors mb-3 mt-2">
                  Please accept the{" "}
                  <a
                    className="errors"
                    target="_blank"
                    href="/terms-and-conditions"
                  >
                    T&C’s
                  </a>{" "}
                  and{" "}
                  <a className="errors" target="_blank" href="/debit-mandate">
                    debit order mandate
                  </a>
                </div>

              </>
            )}
            
            {isOTPSent && (
              <>
                <TextInput
                  placeholder="- Enter your OTP"
                  removeMarginBottom={true}
                  onChange={(e) =>
                    setMobileOTP({ ...mobileOTP, password: e.target.value })
                  }
                  value={mobileOTP?.password}
                />
                <Button
                  name="Confirm Purchase"
                  onClick={() => {
                    handleSubmit();
                  }}
                  color="#fff"
                  containerStyle={{
                    width: "100%",
                  }}
                  bg="#24B03C"
                />
               </>
            )} 

          </Col>
          </div>
        </div>
      </div>
      {/* )} */}

      <Row className="mt-5">
        <Col sm={2}>
          <ButtonWithDownArrow
            onClick={handleBackButton}
            type="left"
            name="Back"
            bg="#dd302a"
            color="white"
          />
        </Col>
        <Col sm={10} />
      </Row>

      {isLoading && (
        <div className="gif-loader-fullscreen">
          <img src={GIFLoader} alt="GIFLoader"></img>
        </div>
      )}
    </div>
  );
};

export default Step6;

const debitSchema = {
  accountHolder: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  accountNumber: {
    presence: { allowEmpty: false },
    format: {
      pattern: "^[0-9]+$",
      message: "can only contain number",
    },
  },
  type: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  bank: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  branchCode: {
    presence: { allowEmpty: false },
  },
  tCChecked: {
    presence: {
      allowEmpty: false,
      message: "^Please accept the terms and condition",
    },
  },
};
