// default imports for react project
import React, { useLayoutEffect, useEffect } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// import dispatch hook to dispatch actions
import { useDispatch, useSelector } from "react-redux";

// import function from actions
import { addCrumb } from "store/actions/common";

// import data from custom component
import { droneFaqs } from "./data";

//   import custom component
import { H1Grey, H2Grey, H3Red } from "components/Heading";
import Accordion from "components/Accordion";
import BodyButton from "components/Buttons/BodyButton";
import BreadCrumb from "components/Breadcrumb";
import CardHeader from "components/Card/CardHeader";

// import util to get current window dimensions
import useWindowDimensions from "utils/useWindowDimension";

// import stylesheet
import Style from "./fastfacts.module.css";

// Import images from assets folder
import { main } from "assets/images/images";

// import classname package to combine module css and normal css
import classNames from "classnames";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

// React functional component starts here..
function FAQ() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentToken =  useSelector(state => state.auth.currentUserToken)
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  // react layout hook to perform initial layout operations
  useLayoutEffect(() => {
    // scroll window to top of page on navigation
    window.scrollTo({top:0,behavior:"smooth"});

    // dispatching an action to update breadcrumb
    dispatch(
      addCrumb([
        { link: "/fast-facts", name: "Fast Facts" },
        { link: "/faq", name: "FAQ" },
      ])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload:parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])


  // Get width from util
  const { width } = useWindowDimensions();

  return (
    <div>
      <Container>
        <BreadCrumb />
        <Row className={classNames("mt-5")}>
          <H1Grey>
            Drone Covered <span className="color-red">Frequently Asked Questions</span>
          </H1Grey>
          <H2Grey>
            Covered by an insurance leader
          </H2Grey>
        </Row>
        <Row className="mt-5 px-2 align-items-center">
          <Col
            lg={5}
            md={7}
            xs={12}
            className={classNames(Style.card__header__col)}
          >
            <CardHeader image={main} title="Drone FAQs" disableButton={true} />
          </Col>
          <Col lg={7} md={5} className="p-0">
            <div
              className={`card__footer__bottom  ${
                width < 430
                  ? "mt-2 text-center"
                  : "mt-4 d-flex justify-content-end"
              }`}
            >
              <div className="amount" style={{ color: "var(--primary)" }}>
                <span>From:</span>
                <span> R 43 pm</span>
              </div>
              <div
                className={width < 430 ? `d-flex justify-content-center` : ""}
              >
                <BodyButton
                  color="white"
                  bg="var(--tertiary)"
                  name="Get a Quote"
                  size={16}
                  marginL={width > 770 && 100}
                  weight={700}
                  width={width > 770 ? 180 : 120}
                  marginT={0}
                  black={width > 430 ? false : true}
                  link="/contact"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className={classNames(Style.content__answer, "")}>
          <div className={classNames("mt-4")}>
            With the considerable growth of the drone industry in recent years
            and its continued expansion expected, Drone Covered is proud to be
            the first specialist drone insurance available to non-commercial
            drone pilots in South Africa, offering hull, loss and theft cover,
            as well as third party and liability cover up to R2,5 million.
          </div>
        </Row>
        <Row>
          {droneFaqs?.map((plan, i) => (
            <Accordion key={i} title={plan?.title}>
              <div>
                {/* {plan?.answer.split("<br/>").map((answer,i) => ( */}
                {/* <p key={i}>{answer}</p> */}
                {/* ))} */}
                <p
                  dangerouslySetInnerHTML={{ __html: plan?.answer }}
                  style={{ lineHeight: "1.7rem", fontWeight: "600" }}
                ></p>
                <ul>
                  {plan?.subAnswer?.map((ans, i) => (
                    <li key={i}>{ans}</li>
                  ))}
                </ul>
              </div>
            </Accordion>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default FAQ;
