import {
  AUTH_EMAIL,
  AUTH_PASSWORD,
  BANK_BRANCH_GUID,
  COMPANY_ID,
  ENV,
  ENV_TYPE,
  LITE_MERCHANT_APP_ID,
  NETWORK_ID,
  OCP_APIM_SUBSCRIPTION_KEY,
  PRODUCT_INSTANCE_ID,
  COMMERCIAL_PRODUCT_OPTION_REF,
  COMMERCIAL_PRODUCT_INSTANCE_ID,
  MIDDLEWARE_URL,
  RECAPTCHA_SITE_KEY
} from "./api-constants";

// <-------- Constants ------->

// Define the ENV TYPE in ENV_VAR
// const ENV_VAR = ENV_TYPE.DEV;

const ENV_VAR = ENV_TYPE[process.env.REACT_APP_ENV_TYPE];
 
const CONSTANTS = {
  BASEURL: ENV[ENV_VAR],
  ENV: ENV_VAR,
  OCP_APIM_SUBSCRIPTION_KEY: OCP_APIM_SUBSCRIPTION_KEY[ENV_VAR],
  PRODUCT_INSTANCE_ID: PRODUCT_INSTANCE_ID[ENV_VAR],
  COMMERCIAL_PRODUCT_OPTION_REF: COMMERCIAL_PRODUCT_OPTION_REF[ENV_VAR],
  COMMERCIAL_PRODUCT_INSTANCE_ID: COMMERCIAL_PRODUCT_INSTANCE_ID[ENV_VAR],
  AUTH_EMAIL: AUTH_EMAIL[ENV_VAR],
  AUTH_PASSWORD: AUTH_PASSWORD[ENV_VAR],
  IVERY_BASEURL: 'https://portal.iveri.net/Lite/Authorise.aspx',
  LITE_MERCHANT_APP_ID: LITE_MERCHANT_APP_ID[ENV_VAR],
  BANK_BRANCH_GUID: BANK_BRANCH_GUID[ENV_VAR],
  NETWORK_ID: NETWORK_ID[ENV_VAR],
  COMPANY_ID: COMPANY_ID[ENV_VAR],
  REDIRECTION_SUCCESS_URL: `${MIDDLEWARE_URL[ENV_VAR]}/payment-success`,
  REDIRECTION_ERROR_URL: `${MIDDLEWARE_URL[ENV_VAR]}/payment-error`,
  MIDDLEWARE_URL: MIDDLEWARE_URL[ENV_VAR],
  CURRENCY_CODE: 'ZAR',
  RECAPTCHA_KEY: RECAPTCHA_SITE_KEY[ENV_VAR],
};

export default CONSTANTS;

export const PRODUCTION_CONSTANTS = {
  REFERER_BASE_URL_PROD: 'https://policy-cache-itp2-prod-za.azurewebsites.net',
  COMPANY_DETAILS: 'https://organisation-itp2-prod-za.azurewebsites.net',
  PRODUCT_RISKS: 'https://product-itp2-prod-za.azurewebsites.net',
  BANK_LOOKUPS: 'https://maintaindata-itp2-prod-za.azurewebsites.net',
  POLICY: 'https://policy-itp2-prod-za.azurewebsites.net'
}

// <-------- Common Messages ------->
export const COMMONMESSAGES = {};

export const RESTRICTED_ATTRIBUTES = [
  // I cofirm and Claim Attributes (DEV)
  '569266eb-fb19-a64a-c51c-521c90c5dc8c',
  // I cofirm and Claim Attributes (UAT)
  '55af0672-062b-412b-aec0-983d07b7c99c'
  // TODO Need to add for UAT
]

export const HIDDEN_QUESTION = ["f0ae08fd-870e-f7af-4873-f3473050c131"];
