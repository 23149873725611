// // default imports for react project
// import React from "react";

// // import default style component from react-bootstrap package
// import { Col, Container, Row } from "react-bootstrap";

// // Import image from assets folder
// import { main } from "assets/images/images";

// //   import custom component
// import BodyButton from "../../components/Buttons/BodyButton";
// import Card2 from "../../components/Card/Card2";

// // import util to get current window dimensions
// import useWindowDimensions from "../../utils/useWindowDimension";

// // Importing Brochure
// import Brochure from "../../assets/documents/brochure.pdf";
// import { useEffect } from "react";
// import { clearState } from "store/actions/products";
// import { useDispatch } from "react-redux";
// import { useLocation } from "react-router";

// // React functional component starts here..
// function Homepage() {
//   // scroll window to top of page on navigation
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   // Get width from util
//   const { width } = useWindowDimensions();
//   const dispatch = useDispatch();
//   const location = useLocation();

//   useEffect(() => {
//     dispatch(clearState());
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [location]);


//   return (
//     <Container>
//       <Row className="py-3">
//         <Col lg={6} xs={12} className="text-center">
//           <img className="main__img" src={main} alt="main" />
//         </Col>
//         <Col lg={6} xs={12} className="homepage__main__text">
//           <Row className="homepage1__heading1 app__header__one m-0">
//             We got you covered on and off the ground. Specialist in Commercial and Leisure
//             drone insurance cover
//           </Row>
//           <Row className="homepage1__heading2 m-0 pt-2">
//             Inflight cover for loss, theft, damage and 3rd party liability
//           </Row>
//           <Row
//             className={
//               width < 770 ? `py-4 m-0 justify-content-between` : `py-4 m-0`
//             }
//           >
//             <Col lg={4} md={5} className="p-0">
//               {/* <BodyButton
//                 color="white"
//                 bg="#828486"
//                 name={width > 770 ? "See Cover Details" : "Buy Insurance"}
//                 size={16}
//                 marginL={0}
//                 marginT={width > 770 ? 0 : 20}
//                 weight={700}
//                 width={width > 770 ? "auto" : "100%"}
//                 align={width > 770 ? "left" : "center"}
//                 link={width > 770 ? "/fast-facts" : "/forms"}
//                 hover={true}
//               /> */}
//               <BodyButton
//                   color="white"
//                   bg="#828486"
//                   name="Get Leisure Cover"
//                   size={16}
//                   marginL={0}
//                   marginT={width > 770 ? 0 : 20}
//                   weight={700}
//                   width={width > 770 ? "auto" : "100%"}
//                   align={width > 770 ? "left" : "center"}
//                   hover={true}
//                   // marginR={20}
//                   // marginL={width > 770 && 100}
//                   // weight={900}
//                   // width={180}
//                   // marginT={0}
//                   // black={width > 430 ? false : true}
//                   // link="/forms"
//                   link="/drone-covered"
//                 />
//             </Col>
//             <Col lg={4} md={5} className="p-0">
//               {/* <a
//                 href={'/forms'}
//                 rel="noreferrer"
//                 style={{ textDecoration: "none" }}
                
//               >
//                 <BodyButton
//                   color="white"
//                   bg="#828486"
//                   name="Get a Quote"
//                   size={16}
//                   marginL={width > 770 ? 30 : 0}
//                   marginT={width > 770 ? 0 : 20}
//                   weight={700}
//                   width={width > 770 ? "auto" : "100%"}
//                   align={width > 770 ? "left" : "center"}
//                   hover={true}
//                 />
//               </a> */}
//               <BodyButton
//                   color="white"
//                   bg="#828486"
//                   name="Get Commercial Cover"
//                   size={16}
//                   marginL={width > 770 ? 30 : 0}
//                   marginT={width > 770 ? 0 : 20}
//                   weight={700}
//                   width={width > 770 ? "100%" : "100%"}
//                   align={width > 770 ? "left" : "center"}
//                   hover={true}
//                   // link="/forms"
//                   link="/Commercial-drone"
//                   isCommercial={true}
//                 />
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//       <Row>
//         <div className="app__header__two">
//           Inflight cover for loss, theft, damage and 3rd party liability for commercial or leisure cover
//         </div>
//         <div style={{ textAlign: width < 430 ? "center" : "" }}>
//           Comprehensive drone insurance that provides cover for loss, theft and
//           damage of your drone as well as third party liability cover. The cover
//           attached to this policy is subject to terms and conditions stated in
//           the policy wording to be read in conjunction with the policy schedule.
//         </div>
//       </Row>
//       <Row className="mt-5">
//         <Col sm={12}>
//           <Card2 width={width} isCommercial={true}/>
//         </Col>
//       </Row>
//     </Container>
//   );
// }

// export default Homepage;


// default imports for react project
import React from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// Import image from assets folder
import { main } from "assets/images/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";
import { login, updateLoading, getAgentDetails, getAgentOnboarding } from "store/actions/auth";

// Importing Brochure
import Brochure from "../../assets/documents/brochure.pdf";
import { useEffect } from "react";
import { clearState } from "store/actions/products";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { parseQuery, setParam } from "utils/utils";
import GIFLoader from "../../assets/images/loader.gif";

// React functional component starts here..
function Homepage() {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentToken =  useSelector(state => state.auth.currentUserToken)
  const isLoading = useSelector((state) => state.auth.loading);

  useEffect(() => {
    dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload: parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])


  const agentCode = useSelector((state) => state.auth.agentCode);
  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  function parseQuery(queryString) { 
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }


  // return (
    return !isLoading ? (
    <Container>
      <Row className="py-3">
        <Col lg={5} xs={12} className="text-center">
          <img className="main__img" src={main} alt="main" />
        </Col>
        <Col lg={7} xs={12} className="homepage__main__text">
          <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
            We got you covered on and off the ground. Specialist in Commercial and Leisure
            drone insurance cover
          </Row>
          <Row className="homepage1__heading2 m-0 pt-2">
            Inflight cover for loss, theft, damage and 3rd party liability
          </Row>
          <Row
            className={
              width < 770 ? `py-4 m-0 justify-content-between` : `py-4 m-0`
            }
          >
            <Col lg={4} md={5} className="p-0">
              {/* <BodyButton
                color="white"
                bg="#828486"
                name={width > 770 ? "See Cover Details" : "Buy Insurance"}
                size={16}
                marginL={0}
                marginT={width > 770 ? 0 : 20}
                weight={700}
                width={width > 770 ? "auto" : "100%"}
                align={width > 770 ? "left" : "center"}
                link={width > 770 ? "/fast-facts" : "/forms"}
                hover={true}
              /> */}
              <BodyButton
                  color="white"
                  bg="#828486"
                  name="Get Leisure Cover"
                  size={16}
                  marginL={0}
                  marginT={width > 770 ? 0 : 20}
                  weight={700}
                  width={width > 770 ? "auto" : "100%"}
                  align={width > 770 ? "left" : "center"}
                  hover={true}
                  // marginR={20}
                  // marginL={width > 770 && 100}
                  // weight={900}
                  // width={180}
                  // marginT={0}
                  // black={width > 430 ? false : true}
                  // link="/forms"
                  link="/contact"
                />
            </Col>
            <Col lg={4} md={5} className="p-0">
              {/* <a
                href={'/forms'}
                rel="noreferrer"
                style={{ textDecoration: "none" }}
                
              >
                <BodyButton
                  color="white"
                  bg="#828486"
                  name="Get a Quote"
                  size={16}
                  marginL={width > 770 ? 30 : 0}
                  marginT={width > 770 ? 0 : 20}
                  weight={700}
                  width={width > 770 ? "auto" : "100%"}
                  align={width > 770 ? "left" : "center"}
                  hover={true}
                />
              </a> */}
              <BodyButton
                  color="white"
                  bg="#828486"
                  name="Get Commercial Cover"
                  size={16}
                  marginL={width > 770 ? 30 : 0}
                  marginT={width > 770 ? 0 : 20}
                  weight={700}
                  width={width > 770 ? "100%" : "100%"}
                  align={width > 770 ? "left" : "center"}
                  hover={true}
                  // link="/forms"
                  link="/Commercial-drone"
                  isCommercial={true}
                />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <div className="app__header__two">
          Inflight cover for loss, theft, damage and 3rd party liability for commercial or leisure cover
        </div>
        <div style={{ textAlign: width < 430 ? "center" : "" }}>
          Comprehensive drone insurance that provides cover for loss, theft and
          damage of your drone as well as third party liability cover. The cover
          attached to this policy is subject to terms and conditions stated in
          the policy wording to be read in conjunction with the policy schedule.
        </div>
      </Row>
      <Row className="mt-5">
        <Col sm={12}>
          <Card2 width={width} isCommercial={true}/>
        </Col>
      </Row>
    </Container>
    ) : (
      <div className="gif-loader-full">
        <img src={GIFLoader} alt="GIF LOADER"></img>
      </div>
  );
}

export default Homepage;
