// initial state of the reducer
const initialState = {
  risks: {},
  types: {},
  riskAttributes: [],
  selectedOption: null,
  companyDetails: {
    workCompanyOptions: "Company",
  },
  activeStep: 0,
  productOptions: [],
  bankDetails: {},
  tryCount: 0,
  paymentFrequency: ''
};

// function to update state
function products(state = initialState, action) {
  // destructuring the action from function
  const { type, payload } = action;

  // switch to match type and update payload
  switch (type) {
    case "PRODUCT_RISKS":
      return {
        ...state,
        risks: payload,
      };

    case "PAYMENT_FREQUENCY":
      return {
        ...state,
        paymentFrequency: payload
      }

    case "UPDATE_T&C":
      return {
        ...state,
        tCChecked: payload,
      };

    case "PRODUCT_TYPES":
      return {
        ...state,
        types: payload,
      };

    case "STORE_BUB_REQUESTED_BODY":
      return {
        ...state,
        requestedPayload: payload,
      };

    case "PRODUCT_RISKS_ATTRIBUTES":
      return {
        ...state,
        riskAttributes: [...payload],
      };

    case "PRODUCT_OPTIONS":
      return {
        ...state,
        productOptions: [...payload],
      };

    case "UPDATE_ATTRIBUTE_1":
      return {
        ...state,
        riskAttributes: [...payload],
      };

    case "SELECT_PRODUCT":
      return {
        ...state,
        selectedProduct: payload,
      };
    case "SELECTED_OPTION":
      return {
        ...state,
        selectedOption: payload,
      };

    case "UPDATE_COMPANY_DETAILS":
      return {
        ...state,
        companyDetails: payload,
      };

    case "CREATE_POLICY":
      return {
        ...state,
        policyResponse: payload,
      };

    case "UPDATE_STEP":
      return {
        ...state,
        activeStep: payload,
      };

    case "BANK_OPTIONS":
      return {
        ...state,
        bankDetails: payload,
      };

    case "UPDATE_TRY_COUNT":
      return {
        ...state,
        tryCount: payload,
      };

      case "IS_COMMERCIAL":
      return {
        ...state,
        isCommercial: payload,
      };

    case "CLEAR_STATE":
      return initialState;

    // return default state if the type doesnt match any case
    default:
      return state;
  }
}
export default products;
