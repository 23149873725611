// default imports for react project
import React from "react";

// import default styles from react-bootstrap package
import { Col, Row } from "react-bootstrap";

// Import icon from assets folder
import {
  EmailIcon,
  PhoneIphoneRoundedIcon,
  YouTubeIcon,
  LinkedInIcon,
  InstagramIcon,
  TwitterIcon,
  FacebookIcon,
} from "assets/icons";

// import classname to combine module css and normal css
import classNames from "classnames";

// import stylesheet
import Style from "./Navbar.module.css";
import { useSelector } from "react-redux";

// React functional component starts here..
function TopNavbar() {
  const companyDetails = useSelector(
    (state) => state.auth.companyDetails?.companyContactViewModel
  );

  return (
    <div className={classNames(Style.topnavbar, " py-2")}>
      <Row className={classNames(Style.topnavbar_row, "container p-0 ")}>
        <Col lg={3} md={4} sm={12} className="d-flex appbar1__content">
          <div>
            <EmailIcon />
          </div>
          <div className="mx-2">
            {companyDetails?.contactEMail
              ? companyDetails?.contactEMail
              : "admin@dronecovered.com"}
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} className="d-flex appbar1__content">
          <div>
            <PhoneIphoneRoundedIcon />
          </div>
          <div className="mx-2">
            {companyDetails?.contactNumber
              ? companyDetails?.contactNumber
              : "087 550 9796"}
          </div>
        </Col>
        <Col
          lg={5}
          md={4}
          sm={12}
          className={classNames(
            Style.appbar1__content,
            "d-flex justify-content-end"
          )}
        >
          {companyDetails?.facebook ? <a
            rel="noreferrer noopener"
            target="_blank"
            href={companyDetails?.facebook}
            role="button"
            className="ml-auto"
          >
            <FacebookIcon />
          </a> : null}
          {companyDetails?.twitter ? <a
            rel="noreferrer noopener"
            target="_blank"
            href={companyDetails?.twitter}
            role="button"
            className="ml-3"
          >
            <TwitterIcon />
          </a> : null}
          {companyDetails?.instagram ?  <a
            rel="noreferrer noopener"
            target="_blank"
            href={companyDetails?.instagram}
            role="button"
            className="ml-3"
          >
            <InstagramIcon />
          </a> : null}
          {companyDetails?.linkedin ? <a
            rel="noreferrer noopener"
            target="_blank"
            href={companyDetails?.linkedin}
            role="button"
            className="ml-3"
          >
            <LinkedInIcon />
          </a> : null}
          
        </Col>
      </Row>
    </div>
  );
}

export default TopNavbar;
