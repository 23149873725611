// default imports for react project
import React, { useLayoutEffect, useEffect } from "react";

// import for dispatching action in react
import { useDispatch, useSelector } from "react-redux";

// import default style component from react-bootstrap package
import { Container, Row } from "react-bootstrap";

// import classname package to combine module css and normal css
import classNames from "classnames";

//   import custom component
import BreadCrumb from "components/Breadcrumb";
import { H1Grey, H2Grey,H1Red, H4Red } from "components/Heading";
import { parseQuery, setParam } from "utils/utils";

// import function from actions
import { addCrumb } from "store/actions/common";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";


// React functional component starts here..
function About() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentToken =  useSelector(state => state.auth.currentUserToken)

  // react layout hook to perform init operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(addCrumb([{ link: "/about", name: "About" }]));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload:parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])


  // return jsx to display on screen
  return (
    <div>
      <Container>
        <BreadCrumb />
        <Row className={classNames("mt-5")}>
          <H1Grey>About Us,</H1Grey>
          <H2Grey>There are more to Drones</H2Grey>
        </Row>
        <Row className={classNames("content mt-5")}>
          <H1Red>About Us</H1Red>{" "}
          <div className={classNames("mt-2")}>
              With the considerable growth of the drone industry in recent years and its continued expansion expected, Drone Covered has sourced two insurers to provide comprehensive cover for your leisure or commercial drone on the ground and in the air. 
          </div>
          <div className={classNames("mt-4")}>
          <b> Drone Leisure Policy </b>
            <br />
            If you are a leisure pilot using your drone for personal use within the SACAA rules for legal flight, the leisure policy underwritten by Mutual and Federal Risk Financing Limited is for you. You can also benefit with increased cover from completing a recognised proficiency course. {" "}
            <br />
            <br />
            Please note: your flight logs will be requested when submitting a claim. Please be aware of the SACAA regulations on legal flight as you will not be covered if your flight route is illegal or for commercial use. 
          </div>
          <div className={classNames("mt-4")}>
            <b> Drone Commercial Policy </b>
            <br />
            If you are using your drone for commercial work, the commercial policy underwritten by iToo is for you. From small photography drones to large agricultural drones, iToo’s policy provides the cover you need to protect your drone and the liabilities that can arise with their use. Should larger liability limits be needed for film shoots, iToo can quote up to R20million. 
            <br />
            <br />
            Please note: You need to have a valid Remote Pilots License (RPL) and RPAS Operator Certificate (ROC) to apply for a commercial policy. 
          </div>
          <div className={classNames("mt-4")}>
            <b>
            Drone Covered is owned [SureStart Online Financial Services] who is a registered Financial Services Provider, FSP. 47233. 
            </b>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default About;
