// default imports for react project
import React, { useState, useEffect } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";


// Import image from assets folder
//import { contactmap } from "assets/images/images";

// import classname package to combine module css and normal css
import classNames from "classnames";

//   import custom component
import { useDispatch,useSelector } from "react-redux";
import { H1Red } from "components/Heading";
import { phoneIcon } from "assets/images/images";
import { mailIcon } from "assets/images/images";
import { mapIcon } from "assets/images/images";
import { facebookIcon } from "assets/images/images";
import { twitterIcon } from "assets/images/images";
import { instagramIcon } from "assets/images/images";
import { SocialIcons } from "components";
import useWindowDimensions from "utils/useWindowDimension";
import axios from "axios";
import CONSTANTS from "utils/constants";
import { toast } from "react-toastify";
import { validate } from "validate.js";
import ReCAPTCHA from "react-google-recaptcha";
import { H1Grey } from "components/Heading";
import { H3Grey } from "components/Heading";
import { H2Grey } from "components/Heading";
import { Divider } from "components";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

// React functional component starts here..
function Contact() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentToken =  useSelector(state => state.auth.currentUserToken)
  const [contact, setContact] = useState({});
  
  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload:parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])


  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setContact({ ...contact, [name]: value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const submitContact = async (e) => {
    e.preventDefault();

    const errors = validate(contact, contactSchema);

    if (errors) {
      setErrors(errors);
      return;
    }
    try {
      setLoading(true);
      const res = await axios.post(`${CONSTANTS.MIDDLEWARE_URL}/contact`, {
        ...contact,
        broker_email_address: companyDetails.contactEMail.length ? companyDetails.contactEMail : 'admin@dronecovered.com'
      }, {});
      setContact({});
      setErrors({});
      setLoading(false);
      toast.success(
        "Thank you for contacting us. Our team will reach out to you soon."
      );
      console.log(res);
    } catch (err) {
      setLoading(false);
      toast.success("Something wrong. Please try again later.");
    }
  };

  const { width } = useWindowDimensions();

  const companyDetails = useSelector(
    (state) => state.auth.companyDetails?.companyContactViewModel
  );

  const socials = [
    {
      link: companyDetails?.contactNumber,
      label: companyDetails?.contactNumber,
      icon: phoneIcon,
      disabled: true,
      type: 'phone'
    },
    {
      link: companyDetails?.contactEMail?.length ? `mailto:${companyDetails.contactEMail}` : '',
      label: companyDetails?.contactEMail,
      icon: mailIcon,
      type: 'mail'
    },
    {
      link: companyDetails?.address,
      label: companyDetails?.address,
      icon: mapIcon,
      disabled: true,
      type: 'text'
    },
    {
      link:  companyDetails?.facebook,
      label: 'Facebook',
      icon: facebookIcon,
      type: 'text'
    },
    {
      link: companyDetails?.twitter,
      label: 'Twitter',
      icon: twitterIcon,
      type: 'text'
    },
    {
      link: companyDetails?.instagram,
      label: 'Instagram',
      icon: instagramIcon,
      type: 'text'
    },
  ];

  const [isRecaptchaComplete, setIsRecaptachaComplete] = useState(false);

  return (
    <div style={{backgroundColor: '#F9F9F9'}}>
      <Container>
        <Row className="mt-5">
          <H1Grey style={{ textAlign: 'center', marginTop: '50px' }}>
           Contact Us
          </H1Grey>
        </Row>
        <Row >
          <Col lg={12} className="d-flex mt-5 justify-content-center align-items-center flex-column">
            <H2Grey>Physical Address</H2Grey> <br />
            <span className="text-center">
            1st Floor, Block B, North Park, Black River Park <br/>

            2 Fir Street, Observatory, Cape Town, <br/>

            Western Cape, 7925, <br/>

            South Africa
            </span>
          </Col>
          <div className="mt-5" style={{ opacity: 0.3 }}>
            <Divider />
          </div>
          <Col lg={12} className="d-flex mt-5 mb-5 justify-content-center align-items-center flex-column">
            <H2Grey>Contact Details</H2Grey> <br />
            <span className="text-center">
                <b>Email:</b> info@dronecovered.com
                <br/>
                <b>Phone:</b> +27 87 550 9796
                <br/>
                <b>Operating hours:</b>
                <br/>
                Monday - Thursday 8:00am - 5:00pm,
                <br/>
                Friday 8:00am - 4:00pm
                <br/>
                We are closed on weekends & public holidays
                <br/>
                Should you have any queries regarding your insurance, kindly email us and we will get back to you.
            </span>
          </Col>
        </Row>
        
        
      </Container>
    </div>
  );
}

export default Contact;

const contactSchema = {
  name: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  email: {
    presence: { allowEmpty: false },
    email: true,
  },
  message: {
    presence: { allowEmpty: false },
  },
};
