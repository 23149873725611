// default imports for react project
import React, { useLayoutEffect, useEffect } from "react";

// import for dispatching action in react
import { useDispatch, useSelector } from "react-redux";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// import function from actions
import { addCrumb } from "store/actions/common";

// import util to get current window dimensions
import useWindowDimensions from "utils/useWindowDimension";

// import stylesheet
import Style from "./fastfacts.module.css";

// import classname package to combine module css and normal css
import classNames from "classnames";

// import custom data
import { topcardData, accordion1Data } from "./data";

//   import custom component
import Accordion from "../../components/Accordion";
import BreadCrumb from "components/Breadcrumb";
import BodyButton from "components/Buttons/BodyButton";
import CardHeader from "components/Card/CardHeader";
import { H3Grey } from "components/Heading";
import { H1Grey } from "components/Heading";
import { main } from "assets/images/images";
import { card_header_img } from "assets/images/images";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

// React functional component starts here..
function LeisureFacts() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentToken =  useSelector(state => state.auth.currentUserToken)

  // react layout hook to perform initial layout operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(addCrumb([{ link: "/fast-facts", name: "Fast Facts" }]));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();

  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload:parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])


  return (
    <Container>
      <BreadCrumb />
      <Row className="mt-5 ">
        <H1Grey style={{ color: "#0b749c" }}>
          Get the correct cover for your drone
        </H1Grey>
        <H3Grey style={{ color: "#0b749c" }}>
          Leisure or commercial cover for loss, theft, damage and 3rd party liability
        </H3Grey>
      </Row>
      <Row className="mt-5 align-items-center">
        <Col
          lg={5}
          md={7}
          xs={12}
          className={classNames(Style.card__header__col)}
        >
          <CardHeader image={card_header_img} title="Leisure Drone Fast facts" />
        </Col>
        <Col lg={7} md={5} className="p-0">
          <div
            className={`card__footer__bottom  ${
              width < 430
                ? "mt-2 text-center"
                : "mt-4 d-flex justify-content-end"
            }`}
          >
            <div className="amount" style={{ color: "var(--primary)" }}>
              <span>From:</span>
              <span> R 43 pm</span>
            </div>
            <div className={width < 430 ? `d-flex justify-content-center` : ""}>
              <BodyButton
                color="white"
                bg="var(--tertiary)"
                name="Get a Quote"
                width={width > 770 && 180}
                size={16}
                marginL={width > 770 && 100}
                weight={800}
                marginT={0}
                black={width > 430 ? false : true}
                link="/forms"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className={classNames(Style.topcard)}>
        <div className={classNames(Style.topcard__theader, "pt-5")}>
          {topcardData?.header}
        </div>
        <div className={classNames("py-4")}>
          {topcardData?.li.map((l, i) => (
            <ul className="pl-0 m-0" key={i}>
              <li>{l}</li>
            </ul>
          ))}
        </div>
        <div>
          <strong>{topcardData?.imp}</strong>
        </div>
        <div>
          {topcardData?.content?.map(({ title, answer }, i) => (
            <div key={i} className="my-3">
              <span className={classNames(Style.topcard__header)}>
                {title}:{" "}
              </span>
              <span className={classNames(Style.topcard__answer)}>
                {answer.map((a, i) => {
                  if (answer.length > 1) {
                    return (
                      <li key={i} style={{ fontWeight: "500" }}>
                        {a.split(' ').map((x) => (x === 'practical' || x === 'theory' || x === 'course:') ? <span style={{fontWeight: 700}}>{x} </span> : `${x} `)}
                      </li>
                    );
                  }
                  return (
                    <div key={i} style={{ fontWeight: "500" }}>
                      {a}
                    </div>
                  );
                })}
              </span>
            </div>
          ))}
        </div>
      </Row>

      <Row>
        <Accordion title={accordion1Data?.header}>
          <div
            className={classNames("mt-4")}
            dangerouslySetInnerHTML={{ __html: accordion1Data?.answer }}
          ></div>
        </Accordion>
      </Row>
      <Row className="m-0 mt-5">
        <div
          className={`card__footer__bottom  ${
            width < 430
              ? "mt-2 text-center"
              : "mt-4 d-flex justify-content-start"
          }`}
        >
          <div className="amount" style={{ color: "var(--primary)" }}>
            <span>From:</span>
            <span> R 43 pm</span>
          </div>
          <div className={width < 430 ? `d-flex justify-content-center` : ""}>
            <BodyButton
              color="white"
              bg="var(--tertiary)"
              name="Get cover now"
              size={16}
              width={width > 770 && 180}
              marginL={width > 770 && 100}
              weight={700}
              marginT={0}
              black={width > 430 ? false : true}
              link="/contact"
            />
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default LeisureFacts;
