// default imports for react project
import React, { useLayoutEffect } from "react";

// default import for routing in react
import { useDispatch } from "react-redux";

// import default style component from react-bootstrap package
import { Container, Row } from "react-bootstrap";

// import classname package to combine module css and normal css
import classNames from "classnames";

//   import custom component
import BreadCrumb from "components/Breadcrumb";
import { H2Red, H1Grey, H2Grey, H4Grey } from "components/Heading";

// import function from actions
import { addCrumb } from "store/actions/common";
import { H4Red } from "components/Heading";

// React functional component starts here..
function PaymentTermsAndConditions() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();

  // react layout hook to perform initial layout operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(
      addCrumb([
        { link: "/payment-terms-and-conditions", name: "Payment Terms & Conditions" },
      ])
    );
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Container className={classNames("content")}>
        <BreadCrumb />
        <Row className={classNames("mt-5")}>
          <H1Grey className={classNames("mb-5")}>
            We’re always thinking about tomorrow,
          </H1Grey>
          <H2Grey style={{fontWeight: 600}}>so you can concentrate on making today count.</H2Grey>
        </Row>
        <Row className={classNames("mt-5")}>
          <H2Red>Payment Terms & Conditions</H2Red>
          <br />
          <div className={classNames("mt-2")}>
            <H4Red>A: OUR CONTRACT (“the Agreement”)</H4Red>
            <br />
            I/We hereby authorise you to issue and deliver payment instructions to Paysoft for collection against my/our abovementioned account at my/our abovementioned bank on condition that the sum of such payment instructions will never exceed my/our obligations as agreed to in the Agreement.
            <br />
            The individual payment instructions so authorised to be issued must be issued and delivered monthly (interval) on or after the dates when the obligation in terms of the Agreement is due and the amount of each individual payment instruction may not be more or less than the obligation due.
            <br />
            The payment instructions so authorised to be issued must carry a number, which number must be included in the said payment instructions and if provided to you should enable you to identify the Agreement.
            <br />
            I/we agree that the first payment instruction will be issued and delivered on or after the issue date. Subsequent payment instructions will continue to be delivered in terms of this authority until the obligations in terms of the Agreement have been paid or until this authority is cancelled by me/us by giving you notice in writing of not less than the interval (as indicated in clause 2 above) and sent by prepaid registered post, electronic communication or delivered to your address indicated above.
          </div>
          <div className={classNames("mt-5")}>
            <H4Red>B. MANDATE</H4Red>
            <br />
            I/we acknowledge that all payment instructions issued by you shall be treated by my/our abovementioned bank as if the instructions had been issued by me/us personally.
          </div>
          <div className={classNames("mt-5")}>
            <H4Red>C. CANCELLATION</H4Red>
            <br />
            I/we agree that although this authority and mandate may be cancelled by me/us, such cancellation will not cancel the Agreement. 
            <br />
            I/we also understand that I/we cannot reclaim amounts, which have been withdrawn from my/our account (paid) in terms of this authority and mandate if such amounts were legally owing to you.
          </div>
          <div className={classNames("mt-5")}>
            <H4Red>D. ASSIGNMENT:</H4Red>
            <br />
            I/We acknowledge that the party hereby authorised to effect the drawing(s) against my/our account may not cede or assign any of its rights to any third party without my/our written consent and that I/we may not delegate any of my/our obligations in terms of this contract/authority to any third party without prior written consent of the authorised party.
          </div>
          <div className={classNames("mt-5")}>
            <H4Red>E. AGREEMENT REFERENCENUMBER</H4Red>
            <br />
            THE AGREEMENT REFERENCE NUMBER IS your policy number
          </div>
        </Row>
        
      </Container>
    </div>
  );
}

export default PaymentTermsAndConditions;
