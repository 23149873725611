// default imports for react project
import React from "react";

// default import for routing in react
import { Link } from "react-router-dom";

// import default styles from react-bootstrap package
import { Col, Container, Row, Navbar } from "react-bootstrap";

// import classname to combine module css and normal css
import classNames from "classnames";

// Import icon and images from assets folder
import {
  LinkedInIcon,
  InstagramIcon,
  TwitterIcon,
  FacebookIcon,
} from "assets/icons";
import { logofooter, hollard } from "assets/images/images";

// import custom component
import { Href } from "components/Heading";

// import stylesheet
import Style from "./Navbar.module.css";

// import util to get current window dimensions
import useWindowDimensions from "utils/useWindowDimension";
import { useSelector } from "react-redux";
import { logoSS } from "assets/images/images";

// React functional component starts here..
function Footer() {
  // Get width from util
  const { width } = useWindowDimensions();

  const companyDetails = useSelector(
    (state) => state.auth.companyDetails?.companyContactViewModel
  );


  // return jsx to display on screen
  return (
    <div
      className={classNames(
        width > 770 ? Style.footer_container : Style.footer_container_xs
      )}
    >
      <Container>
        <Row>
          <Col
            lg={2}
            xs={12}
            className={classNames(`${width > 770 ? "py-0 px-4" : "p-0 pb-4"}`)}
            style={width > 770 ? {} : { width: "70%" }}
          >
            <a href="https://www.surestart.co.za/" target="_blank">
              <img
                src={logoSS}
                alt="Drone Covered"
                width={width > 770 ? "100%" : "162px"}
                className={classNames(
                  `${width > 770 ? "footer_logo_lg" : "footer_logo mb-2"}`
                )}
              />
            </a>
            <div style={{ fontSize: "10px" }}>
              Administered by Surestart Online Financial Services (Pty) Ltd, FSP
              No. 47233
            </div>
          </Col>
          <Col lg={2} md={6} xs={6} className={classNames("p-0")}>
            <div
              style={{ fontSize: "15px" }}
              className={classNames(Style.footer_header)}
            >
              Let Us Help You
            </div>
            <div style={{ fontSize: "12px" }}>
              <Href link="/faq">F.A.Q</Href>
              <Href link="/terms-and-conditions">Terms & Conditions</Href>
              <Href link="/privacy-policy">Privacy Policy</Href>
              <Href link="/disclosure">Disclosures</Href>        
            </div>
          </Col>
          <Col lg={2} md={6} xs={6}>
            <div
              style={{ fontSize: "15px" }}
              className={classNames(Style.footer_header)}
            >
              Get to Know us
            </div>
            <div style={{ fontSize: "12px" }}>
              <Href link="/contact">Contact</Href>
              <Href link="/about">About</Href>
              {/* <Href link="/forms">Buy Insurance</Href> */}
              <Href link="/contact">Buy Insurance</Href>
              <Href link="/claims">Submit Claim</Href>
            </div>
          </Col>
          <Col
            lg={4}
            xs={12}
            className={classNames(width < 770 ? "mt-5 p-0" : "")}
          >
            <div
              style={width < 430 ? { fontSize: "15px", padding: 0 } : {}}
              className={classNames(Style.footer_header)}
            >
              Address
            </div>
            <div style={{ fontSize: "12px" }}>
              {companyDetails?.address
                ? companyDetails?.address
                : "Address 1st Floor, Block B, North Park, Black River Park 2 Fir Street, Observatory, Cape Town, Western Cape, 7925 "}{" "}
              <br />
              {companyDetails?.contactNumber
                ? companyDetails.contactNumber
                : "087 550 9796"}{" "}
              <br />{" "}
              {companyDetails?.contactEMail
                ? companyDetails.contactEMail
                : "info@dronecovered.com"}
            </div>
          </Col>
          <Col lg={2} xs={12} className={classNames(width < 770 ? "mt-5" : "")}>
            
            <Row>
              {/* <div className={classNames("p-0 pt-0 pb-1")}>
                <img
                  src={hollard}
                  alt="mutual&federal"
                  className="footer_sec_logo"
                  width="170px"
                />
              </div>
              <span className="p-0" style={{ fontSize: "10px" }}>
                {" "}
                Underwritten by Mutual and Federal Risk Finance. Administered by
                Surestart Online Financial Services (Pty) Ltd, FSP No. 47233
              </span> */}
              <Navbar.Brand href="/">
              <img
                className={Style.logo}
                src={logofooter}
                alt="logo"
                width={width < 770 ? "130px" : "150px"}
                style={{marginLeft:"54px"}}
              />
            </Navbar.Brand>

            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
