import CONSTANTS from "utils/constants";
import axios from "axios";
import { store } from "store/store";
import { toast } from "react-toastify";
import { PRODUCTION_CONSTANTS } from "utils/constants";


//********************************* NOTES **********************************//
// 1. Please refer the TYPE from the according reducers.                    //
// 2. All the actions will be exporting to the components.                  //
// 3. Each action may call API Calls and returning the response to store.   //
//********************************* NOTES **********************************//

export const clearState = () => async (dispatch) => {
  // Dispatching to clear all the values in the store
  dispatch({
    type: "CLEAR_STATE",
  });
};

export const UpdateSteps = (payload) => async (dispatch) => {
  // Dispatching --> update the activeStep in the store
  dispatch({
    type: "UPDATE_STEP",
    payload,
  });
};

export const paymentFrequency = (payload) => async (dispatch) => {
  dispatch({
    type: 'PAYMENT_FREQUENCY',
    payload
  })
}

export const getProductRisks = (PRODUCT_INSTANCE_ID) => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.jwtToken;

    // Calling the product risk API
      const response = await axios.get(
        `${CONSTANTS.MIDDLEWARE_URL}/products/getProductRisks/${PRODUCT_INSTANCE_ID}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
    // Calling the bank details
    const response1 = await axios.get(
      `${CONSTANTS.MIDDLEWARE_URL}/products/getLookupItemsList/${CONSTANTS.BANK_BRANCH_GUID}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
    // Dispatching ----> response data to the store
    // Refer the types withe reducer
    dispatch({ type: "PRODUCT_RISKS", payload: response.data.data });
    dispatch({ type: "UPDATE_ATTRIBUTE_1", payload: response.data.data.risks });
    dispatch({ type: "BANK_OPTIONS", payload: response1.data.data });
  } catch (e) {}
};

export const getProductRisksAttributes =
  (risksInstanceID, i) => async (dispatch) => {
    try {
      // product risk from the store
      const risks = await store.getState().products.risks.risks;
      const risksAttributes = await store.getState().products.riskAttributes;
      // JWT token from the store
      const token = await store.getState().auth.jwtToken;

      // Calling specific risk attributes
        const response = await axios.get(
          `${CONSTANTS.MIDDLEWARE_URL}/products/getProductRiskAttributes/${risksInstanceID}`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
            token,
          },
        }
      );

      if (!risksAttributes[i].attributes) {
        // Consolidating and dispatching the values & response to the store
        const payload = [...risks];
        payload[i].attributes = response.data.data.attributes;

        dispatch({ type: "PRODUCT_RISKS_ATTRIBUTES", payload });
      }
      return risks;
    } catch (e) {}
  };

export const getProductRisksAttributesWithPresist =
  (risksInstanceID) => async (dispatch) => {
    try {
      // product risk from the store
      const riskAttributes = await store.getState().products.riskAttributes;

      // JWT token from the store
      const token = await store.getState().auth.jwtToken;

      // Calling specific risk attributes
        const response = await axios.get(
          `${CONSTANTS.MIDDLEWARE_URL}/products/getProductRiskAttributes/${risksInstanceID}`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
            token,
          },
        }
      );

      const elementIndex = riskAttributes?.findIndex(
        (ele) => ele.code === response.data.data.risk.code
      );
      var arr = [...riskAttributes];
      arr[elementIndex] = {
        ...arr[elementIndex],
        attributes: response.data.data.attributes,
      };

      dispatch({ type: "UPDATE_ATTRIBUTE_1", payload: arr });
    } catch (e) {}
  };

export const getProductTypeDetails = (PRODUCT_INSTANCE_ID) => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.jwtToken;

    // Calling the product types
      const response = await axios.get(
        `${CONSTANTS.MIDDLEWARE_URL}/products/getProductTypes/${CONSTANTS.PRODUCT_INSTANCE_ID}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
    // Dispatching ----> response data to the store
    dispatch({ type: "PRODUCT_TYPES", payload: response.data.data });
  } catch (err) {}
};

export const executeCalculator = (payload, i) => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.jwtToken;
    const productOptions = await store.getState().products.productOptions;
    // Calling the execute calculator
    const response = await axios.post(
      `${CONSTANTS.MIDDLEWARE_URL}/calculator`,
      payload,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );

    productOptions[i] = {
      ...response.data.data,
      productOptions: response.data.data.productOptions.map((ex) => ({
        ...ex,
        body: payload,
      })),
      requestBody: payload,
    };

    return dispatch({
      type: "PRODUCT_OPTIONS",
      payload: productOptions,
    });
  } catch (err) {
    throw err;
  }
};

export const updateValues = (payload) => async (dispatch) => {
  // Risk attributes from the store
  const riskAttributes = await store.getState().products.riskAttributes;
  var arr = riskAttributes.filter((attr) => attr.code !== payload.code);
  arr = [...arr, payload];
  // Dispatching ----> data to the store
  dispatch({ type: "UPDATE_ATTRIBUTE_1", payload: arr });
};

export const updateStepValue = (payload) => async (dispatch) => {
  // Risk attributes from the store
  const riskAttributes = await store.getState().products.riskAttributes;
  // const elementIndex = riskAttributes?.findIndex(
  //   (ele) => ele.code === payload.code
  // );
  // var arr = [...riskAttributes];
  var arr = riskAttributes.filter((attr) => attr.code !== payload.code);
  // arr[elementIndex] = { ...arr[elementIndex], attributes: payload.attributes };
  arr = [...arr, payload];
  // Dispatching ---->  data to the store
  dispatch({ type: "UPDATE_ATTRIBUTE_1", payload: arr });
};

export const CreatePolicy = (payload) => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.jwtToken;

    // Calling the CreatePolicy API
    const response = await axios.post(
      `${CONSTANTS.MIDDLEWARE_URL}/policy/createPolicySale`,
      payload,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
    // Dispatching ----> response data to the store
    dispatch({ type: "CREATE_POLICY", payload: response.data.data });
  } catch (err) {}
};

export const selectProduct = (payload) => async (dispatch) => {
  // Dispatching ---->  payload to the store
  dispatch({ type: "SELECT_PRODUCT", payload });
};

export const updateSelectedOption = (payload) => (dispatch) => {
  // Dispatching ---->  payload to the store
  dispatch({
    type: "SELECTED_OPTION",
    payload,
  });
};

export const updateCompanyDetails = (payload) => (dispatch) =>
  [
    // Dispatching ---->  payload to the store
    dispatch({
      type: "UPDATE_COMPANY_DETAILS",
      payload,
    }),
  ];

export const updateTryCount = (payload) => (dispatch) => {
  // Dispatching ---->  payload to the store
  dispatch({
    type: "UPDATE_TRY_COUNT",
    payload,
  });
};

export const issuePolicy = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.jwtToken;

  // Calling the issue policy sale API
  const response = await axios.post(
    `${CONSTANTS.MIDDLEWARE_URL}/policy/issuePolicy`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};

export const sendOTP = (payload) => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.jwtToken;

    // mobilePhone form the store
    const mobileNumber = await store.getState().products.companyDetails
      .mobilePhone;

    // Calling the SendOTP API
    const response = await axios.post(
      //   process.env.REACT_APP_ENV_TYPE === 'PROD' ?
      // `${PRODUCTION_CONSTANTS.POLICY}/policies/otp/sendOTP`
      //   :
      //   `${CONSTANTS.BASEURL}/pl/policies/otp/sendOTP`,
      `${CONSTANTS.MIDDLEWARE_URL}/policy/sendOTP`,
      {
        ...payload,
        productId: CONSTANTS.PRODUCT_INSTANCE_ID, // product instance ID from the constance
        mobilePhoneCode: "+27",
        mobilePhone: mobileNumber,
        // mobilePhoneCode: "+91",
        // mobilePhone: "94",
      },
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
    toast.success("OTP Sent successfully.");
    return response;
  } catch (err) {
    toast.error(err?.response?.data?.data || "Error occured. Please try again !"); 
  }
};

export const validateOTP = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.jwtToken;

  // Calling the Validate API
  const response = await axios.post(
    `${CONSTANTS.MIDDLEWARE_URL}/policy/validateOTP`,
    // process.env.REACT_APP_ENV_TYPE === 'PROD' ?
    // `${PRODUCTION_CONSTANTS.POLICY}/policies/otp/validateOTP`
    //   :
    // `${CONSTANTS.BASEURL}/pl/policies/otp/validateOTP`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response.data;
};

export const makeExternalPayment = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.jwtToken;

  // Calling the external payment API
  const response = await axios.post(
    // process.env.REACT_APP_ENV_TYPE === 'PROD' ?
    // `${PRODUCTION_CONSTANTS.POLICY}/policies/payments/submitExternalPayment`
    //   :
    // `${CONSTANTS.BASEURL}/pl/policies/payments/submitExternalPayment`,
    `${CONSTANTS.MIDDLEWARE_URL}/policy/submitExternalPayment`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};

export const makePolicySalePayment = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.jwtToken;

  // Calling the makePolicySalePayment API
  const response = await axios.post(
    // process.env.REACT_APP_ENV_TYPE === 'PROD' ?
    // `${PRODUCTION_CONSTANTS.POLICY}/policies/sale/makePolicySalePayment`
    //   :
    // `${CONSTANTS.BASEURL}/pl/policies/sale/makePolicySalePayment`,
    `${CONSTANTS.MIDDLEWARE_URL}/policy/makePolicySalePayment`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};

export const activatePolicy = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.jwtToken;

  // Calling the activatePolicy API
  const response = await axios.post(
    // process.env.REACT_APP_ENV_TYPE === 'PROD' ?
    // `${PRODUCTION_CONSTANTS.POLICY}/policies/sale/activatePolicySale`
    //   :
    // `${CONSTANTS.BASEURL}/pl/policies/sale/activatePolicySale`,
    `${CONSTANTS.MIDDLEWARE_URL}/policy/activatePolicySale`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};

export const checkTC = (payload) => (dispatch) => {
  dispatch({
    type: "UPDATE_T&C",
    payload,
  });
};

