// import React, { useState } from "react";

// const PaymentCommercial = ({ data, handleNext, handleBack }) => {
// return(
//     <div>hloo</div>
// );
// };

// export default PaymentCommercial;


import React, { useState } from "react";
import style from "./Form.module.css";
// <--------- Essential Components -------->
import {
  Header,
  Divider,
  VerticalSpacer,
  DescriptionPlaceholders,
} from "../../components";
import GIFLoader from "../../assets/images/loader.gif";

// <---------- Redux Hooks ----------->
import {useSelector } from "react-redux";

import SummaryCommercial from "components/Forms/Summary-Commercial";
import classNames from "classnames";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

const PaymentCommercial = () => {
  // Company details from the store

  // Selected product from the store
  const selectedProduct = useSelector(
    (state) => state.products.selectedProduct
  );

  // Policy response from the store
  const policyResponse = useSelector((state) => state.products.policyResponse);

  // Risk attributes from the store
  const [isLoading, setIsLoading] = useState(false);


  const companyDetails = useSelector((state) => state.products.companyDetails);

  const paymentFrequency = useSelector((state) => state?.products?.paymentFrequency);
  // External reference
  
  const getProrate = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => { const val = a.split(":"); obj[val[0]] = val[1]; })
    return obj;
  }

  const decimalCheck = (string) => {
    if (string.split(".").length <= 1) {
      return `${string}.00`;
    } else  if(string.split(".")[1].length <= 1){
      return `${string}0`;
    }else {
      return string;
    }
  };

  const handleSubmit = () => {
    console.log('subbmitt--')
  };


  return (
    <div className="container mt-5 mb-5">
      <div className="d-flex justify-content-center flex-column">
        {/* <---------- Title -------> */}
        <h3 className={style.header}>Download and Apply</h3>
        {/* <VerticalSpacer rows={5} /> */}
        {isLoading ? (
          <div className="gif-loader">
            <img src={GIFLoader} alt="Loader"></img>
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-center align-items-center w-100 text-center">
              <Header
                titleSize={20}
                textAlign="center"
                title={`Thank you for you interest in Commercial Drone Cover`}
              />
            </div>

            <div className={classNames("container", style.boxBorderCommercial)}>
            {/* <div> */}
                <Row>
                <div className="d-flex justify-content-center align-items-center w-100 text-center">
                <span style={{color:"#DA291C",fontWeight:"700"}}>{`An Email has be sent to ${companyDetails.eMail}`},</span>
                </div>
                </Row>
                <Row>
                <div className="d-flex mt-2 mb-3 justify-content-center align-items-center w-100 text-center">
                <span style={{color:"#DA291C",fontWeight:"700"}}>{"With your reference number and the Commercial Drone application form."}</span>
                </div>
                </Row>

              <Divider/>


              <Row>

                <Col>
                <div className="d-flex mt-3 justify-content-center align-items-center">
                <SummaryCommercial
                policyNumber={policyResponse.policies?.length && policyResponse.policies[0].policyReference}
                onClick={handleSubmit}
                />
              </div>

                </Col>
              </Row>          

            
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentCommercial;
