// default imports for react project
import React, { useLayoutEffect, useEffect } from "react";

// default import for routing in react
import { useDispatch, useSelector } from "react-redux";

// import default style component from react-bootstrap package
import { Container, Row } from "react-bootstrap";

// import classname package to combine module css and normal css
import classNames from "classnames";

//   import custom component
import BreadCrumb from "components/Breadcrumb";
import { H2Red, H1Grey, H2Grey, H4Grey, H6Grey } from "components/Heading";

// import function from actions
import { addCrumb } from "store/actions/common";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

// React functional component starts here..
function PrivacyPolicy() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentToken =  useSelector(state => state.auth.currentUserToken)

  // react layout hook to perform initial layout operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(addCrumb([{ link: "/privacy-policy", name: "Privacy Policy" }]));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload:parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])


  return (
    <div>
      <Container className={classNames("content")}>
        <BreadCrumb />
        <Row className={classNames("mt-5")}>
          <H1Grey className={classNames("mb-5")}>
            We’re always thinking about tomorrow,
          </H1Grey>
          <H2Grey>so you can concentrate on making today count.</H2Grey>
        </Row>
        <Row className={classNames("mt-5")}>
          <H2Red>Drone Covered Privacy Policy</H2Red>
          <br />
          <div className={classNames("mt-2")}>
            <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
              Privacy Policy
            </H4Grey>
            <br />
            By accessing and using this website, the user consents to the
            procession of their personal information by Surestart Online
            Financial Services (Pty) Ltd on the basis set out in this Privacy
            notice. If the user does not consent, the user must immediately stop
            accessing and/or using this website.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            1. Introduction
          </H4Grey>
          <br />
          <div>
            <H6Grey>1.1</H6Grey> Surestart Online Financial Services (Pty) Ltd
            collects, uses and, in some circumstances, shares the personal
            information of Users in and through this website.
          </div>
          <div>
            <H6Grey>1.2</H6Grey> Surestart Online Financial Services (Pty) Ltd
            respects the rights of Users whose personal information is collected
            and used by it, including their right to protection against the
            unlawful collection, retention, sharing and use of such personal
            information.
          </div>
          <div>
            <H6Grey>1.3</H6Grey> The purpose of this Privacy Policy is to
            provide Users with information about the information processing
            activities of Surestart Online Financial Services (Pty) Ltd and the
            manner in which their rights are protected.
          </div>
          <div>
            <H6Grey>1.4</H6Grey> This Privacy Policy shall serve as a blanket
            notification to Users about Surestart Online Financial Services
            (Pty) Ltd processing activities which will remain valid for as long
            as one has an active account with Surestart Online Financial
            Services (Pty) Ltd. Users will not be notified separately on each
            occasion that Surestart Online Financial Services (Pty) Ltd
            processes the same personal information in the same way over the
            period.{" "}
          </div>
          <div>
            <H6Grey>1.5</H6Grey> Surestart Online Financial Services (Pty) Ltd
            may amend the provisions of this Privacy Policy to comply with any
            changes in the law, and/or pursuant to any changes to its
            information processing activities or privacy practices. Such
            amendments will be published on the website and will become operable
            from the date of such publication.
          </div>
          <div>
            <H6Grey>1.6</H6Grey> This privacy policy, and the interpretation and
            enforcement thereof, will be governed by the laws of the Republic of
            South Africa.
          </div>
          <div>
            <H6Grey>1.7</H6Grey> The provisions of this Privacy Policy must, as
            far as possible, be incorporated into the agreement between
            Surestart Online Financial Services (Pty) Ltd and Users.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            2. Definitions and Interpretation
          </H4Grey>
          <br />
          <div>
            <H6Grey>2.1</H6Grey> In this Privacy Policy, the following words
            bear the following meanings:
          </div>
          <div>
            - “application “means the Surestart Online Financial Services (Pty)
            Ltd
          </div>
          <div>
            - “consent”means any voluntary , specific and information expression
            of will in terms of which permission is given by or on behalf of a
            User for the processing of their personal information;
          </div>
          <div>
            - “direct marketing”means to approach a data subject, either in
            person or by mail or electronic communication, for the direct or
            indirect purpose of promoting or offering to supply any goods or
            services to the data subject;
          </div>
          <div>
            - “operator” means an outside third party who processes personal
            information for or on behalf of Surestart Online Financial Services
            (Pty) Ltd in terms of a contract or mandate;
          </div>
          <div>
            - “personal information” means any information linked to a User or
            information that can identify a User, including but not limited to:
          </div>
          <div>
            • Information relating to a User’s gender, nationality, ethnic or
            social origin age, language, financial status and creditworthiness;
          </div>
          <div>
            • A User’s identity number, e-mail address, physical address,
            telephone number, location information or online identifier;
          </div>
          <div>• A User’s personal opinions, views or preferences;</div>
          <div>
            • Correspondence sent by a User which is of a private or
            confidential nature; and
          </div>
          <div>
            • The User’s name if it appears with other personal information
            relating to that User, or if the disclosure of their name on its own
            would reveal further personal information about that User;
          </div>
        </Row>

        <Row className={classNames("mt-4")}>
          <div>
            <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
              “POPIA”
            </H4Grey>{" "}
            means the Protection of Personal Information Act, including any
            regulations or codes of conduct promulgated under it;
          </div>
          <div>
            <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
              “PROATIA”
            </H4Grey>{" "}
            means the Promotion of Access to Information Act 2 of 2000;
          </div>
          <div>
            <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
              “Process of Processing“
            </H4Grey>{" "}
            means, in relation to personal information, any operation or
            activity or any set of operations, whether or not by automatic
            means, including:
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <div>
            The collection, receipt, recording, organisation, collation,
            storage, updating or modification, retrieval, alteration,
            consultation or use of that information;
          </div>
          <div>
            • Dissemination by means of transmission, distribution or making
            available in any other form; or{" "}
          </div>
          <div>
            • Merging, Linking, as well as restriction, degradation, erasure or
            destruction of that information;
          </div>
        </Row>

        <Row className={classNames("mt-4")}>
          <div>
            <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
              “User”
            </H4Grey>{" "}
            means a visitor or user of this website, or any of the content or
            services associated with this website or the application; and
          </div>
          <div>
            <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
              “The Website“
            </H4Grey>{" "}
            means the website or any part thereof which is accessible from{" "}
            {/* <a href="www.dronecovered.com" target="_blank"> */}
            www.dronecovered.com
            {/* </a> */}
          </div>
          <div>Any reference in this Privacy Policy to:</div>
          <div>• The singular includes the plural and vice versa;</div>
          <div>
            • Any one gender includes the other genders, as the case may be;{" "}
          </div>
          <div>
            • An act, regulation or other law is to the version of that law in
            force at the effective date of this Policy and includes any
            amendment or re-enactment made to that law after the effective date
            of this Policy.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <div>
            When calculating any number of days for the purposes of this Policy,
            the first day must be excluded and the last day of the relevant
            interval included, unless the last day is not a business day, then
            the last day will be the next succeeding business day.
          </div>
          <div>
            The word “include” means “include without limitation”. Use of the
            word "include" or "in particular" is for illustration or emphasis
            only and where followed by specific examples must not be interpreted
            as limiting the meaning of the general wording preceding it.
          </div>
        </Row>
        <Row>
          <div>
            A requirement that any Policy, request, demand or other
            communication made in terms of this Privacy Policy must be in
            writing will be met if it is in the form of a data message as
            defined in the Electronic Communications and Transactions Act, No.
            25 of 2002, and is accessible in a manner usable for subsequent
            reference.{" "}
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            3. Responsible Party
          </H4Grey>
          <div>
            <H6Grey>3.1</H6Grey> Surestart Online Financial Services (Pty) Ltd
            will be the party who will be collecting and processing a User’s
            personal information and as such is designated as the ‘’responsible
            party’’ for the purposes of this Policy.{" "}
          </div>
          <div>
            <H6Grey>3.2</H6Grey> Surestart Online Financial Services (Pty) Ltd
            contact details are as follows:
          </div>
          <div>
            <H6Grey>3.3</H6Grey> Email address: info@surestart.co.za.
          </div>
          <div>
            <H6Grey>3.4</H6Grey> Telephone number : +27 87 550 9060
          </div>
          <div>
            <H6Grey>3.5</H6Grey> Surestart Online Financial Services (Pty) Ltd
            may instruct third party operators from time to time to undertake
            certain processing activities relating to the User’s personal
            information.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            4. What Personal Information Is collected
          </H4Grey>
          <div>
            <H6Grey>4.1</H6Grey> Surestart Online Financial Services (Pty) Ltd
            may collect the following personal information from the User
          </div>
          <div>• Initials, first name, surname;</div>
          <div>• Date of birth;</div>
          <div>• Email address;</div>
          <div>• Age and language preference;</div>
          <div>• Gender;</div>
          <div>• Creditworthiness;</div>
          <div>• Location; and</div>
          <div>• Cellphone number.</div>
        </Row>
        <Row className={classNames("mt-4")}>
          <div>
            <H6Grey>4.2</H6Grey> The supply of personal information by the User
            to Surestart Online Financial Services (Pty) Ltd is voluntary and
            not mandatory. However, if the User refuses to supply any personal
            information, certain consequences may naturally flow from such a
            refusal, such as preventing Surestart Online Financial Services
            (Pty) Ltd from concluding or performing any contract with the User,
            or preventing Surestart Online Financial Services (Pty) Ltd from
            complying with one or more of its obligations in law.
          </div>
          <div>
            <H6Grey>4.3</H6Grey> Surestart Online Financial Services (Pty) Ltd
            is required in terms of laws regulating the provision of financial
            advice to collect certain personal information about a User.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            5. Purpose/s for Collection and Processing of Personal Information
          </H4Grey>
          <div>
            <H6Grey>5.1</H6Grey> Surestart Online Financial Services (Pty) Ltd
            is required in terms of laws regulating the provision of financial
            advice to collect certain personal information about a User.
          </div>
          <div>
            <div>
              <H6Grey>5.2</H6Grey> Surestart Online Financial Services (Pty) Ltd
              shall only collect a User’s personal information for a specific,
              explicitly defined and lawful purpose relating to a function or
              activity of Surestart‘s business.
            </div>
            <div>- Such purposes may include the following :</div>
            <div>• To enter into a contract with a User;</div>
            <div>
              • To perform any obligations under a contract with a User;
            </div>
            <div>• To comply with a legal obligation;</div>
            <div>
              • To pursue its own legitimate interests or the legitimate
              interests of a third party who it is sharing the information with
              (unless the User has specifically objected in writing to all or
              some of the processing activities on reasonable grounds);
            </div>
            <div>
              • To process personal information for direct marketing purposes
              (unless the User has opted out of receiving any direct marketing
              material);
            </div>
            <div>
              • To present content to the User in the most effective way for
              them and their viewing device and browser;
            </div>
            <div>
              • To customise and display content including, but not limited to
              products, articles, listings and advertisement to the User in a
              way that Surestart Online Financial Services (Pty) Ltd feels may
              interest the User or be most beneficial to them;
            </div>
            <div>
              • To send content including, but not limited to products,
              articles, listings and advertisement content to the User via email
              or other electronic media, where the User has consented to be
              contacted by Surestart Online Financial Services (Pty) Ltd with
              such content;
            </div>
            <div>
              • To enable the User to voluntarily participate in interactive
              features on the website or application;
            </div>
            <div>
              • To notify the User about changes to the website or application.
            </div>
          </div>
          <div>
            <H6Grey>5.3</H6Grey> Surestart Online Financial Services (Pty) Ltd
            intends to process a User’s personal information for any other
            purpose not listed in clause 5.2 or which is otherwise not
            automatically permitted by law, it shall ensure that it obtains the
            User’s written consent to do so.
          </div>
          <div>
            <H6Grey>5.4</H6Grey> Surestart Online Financial Services (Pty) Ltd
            will not sell a User’s personal information to any third party
            without the prior written consent of the User.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            6. Collection directly from User
          </H4Grey>
          <div>
            <div>
              <H6Grey>6.1</H6Grey> Surestart Online Financial Services (Pty) Ltd
              shall, as far as possible, always collect personal information
              about a User directly from the User, except in the following
              circumstances:
            </div>
            <div>
              • Where personal information is collected from a public record, or
              from another source if the information has already been made
              public by the User;
            </div>
            <div>
              • Where the User has given their written consent to Surestart
              Online Financial Services (Pty) Ltd to collect their information
              from another source;
            </div>
            <div>
              • Where the collection of a User’s personal information from
              another source will not prejudice any of the User’s legitimate
              interests;
            </div>
            <div>
              • Where the collection of personal information from another source
              is necessary to maintain Surestart’ legitimate interests or those
              of any third party it intends sharing the information with;
            </div>
            <div>
              • Where the collection of personal information directly from the
              User would prejudice the purpose for the collection;
            </div>
            <div>
              • Where the collection of personal information directly from the
              User is not reasonably practicable in the circumstances.
            </div>
          </div>
          <div>
            <H6Grey>6.2</H6Grey> Surestart Online Financial Services (Pty) Ltd
            collects personal information from a source other than the User, it
            shall record in writing the details of that source, including the
            full names and contact details of that source where applicable.
          </div>
          <div>
            <H6Grey>6.3</H6Grey> The User hereby consents to Surestart Online
            Financial Services (Pty) Ltd requesting and obtaining credit
            information pertaining to the User from any reputable credit
            reporting agency or institution for the purpose of concluding any
            transaction for the sale of goods or services to the User on this
            website.
          </div>
          <div>
            <H6Grey>6.4</H6Grey> Personal information may be collected from or
            supplied by the User in any of the following ways: during the
            process of registering as a member on this website or the
            application
          </div>
          <div>
            <div>
              <H6Grey>6.5</H6Grey> Personal information may be collected from or
              supplied by the User in any of the following ways:
            </div>
            <div>
              • Provided by the User as part of their profile pages on the
              website or the application;
            </div>
            <div>
              • When subscribing to a service, entering into a competition or
              promotion, or participating in a survey offered on this website or
              the application;
            </div>
            <div>
              • When posting a comment, review, reply or recommendation on this
              website or the application;
            </div>
            <div>
              • When requesting further services or information from Surestart
              Online Financial Services (Pty) Ltd;
            </div>
            <div>
              • When contacting Surestart Online Financial Services (Pty) Ltd to
              report a problem with the website or the application or for any
              other reason;
            </div>
            <div>
              • When completing any forms on the website or the application.
            </div>
          </div>
          <div>
            <H6Grey>6.6</H6Grey> The User may visit the website without
            providing any personal information. However, the website’s servers
            may still collect technical information regarding the use of the
            website, which is aggregated for analytical purposes, technical
            maintenance and for improving the content offered on the website.
            Such information may include details of the User’s visit,
            information about the User’s computer, including IP (Internet
            Protocol) address,operating system and browser type, the User’s
            location, and usage information. An individual User will not be
            identified from or by this information and Surestart Online
            Financial Services (Pty) Ltd is entitled to copy, distribute or
            otherwise use such information without limitation.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            7. Cookies
          </H4Grey>
          <div>
            <H6Grey>7.1</H6Grey> “Cookies” are small text files transferred by a
            webserver to a User’s hard drive and thereafter stored on their
            computer. The types of information a Cookie collects includes a
            User’s username, the date and time of their visits to the website,
            their browsing history and preferences.
          </div>
          <div>
            <H6Grey>7.2</H6Grey> “Surestart Online Financial Services (Pty) Ltd
            may use Cookies on this website to:
          </div>
          <div>
            <H6Grey>7.3</H6Grey> Distinguish one User from another on the
            website;
          </div>
          <div>
            <H6Grey>7.4</H6Grey> Remember the User’s last session when they
            return to the website;
          </div>
          <div>
            <H6Grey>7.5</H6Grey> Estimate the website's audience size and usage
            patterns;
          </div>
          <div>
            <H6Grey>7.6</H6Grey> Store information about the User’s preferences,
            which allows Surestart Online Financial Services (Pty) Ltd to
            customize the website and content according to the Users individual
            preferences; and
          </div>
          <div>
            <H6Grey>7.7</H6Grey> Speed up searches on the website.
          </div>
          <div>
            <H6Grey>7.8</H6Grey> The provisions of this clause are only
            applicable to Cookies used by Surestart Online Financial Services
            (Pty) Ltd. In some instances, third-party service providers may use
            Cookies on the website. Surestart Online Financial Services (Pty)
            Ltd cannot and does not control or access Cookies used by third
            party service providers and takes no responsibility therefore
          </div>
          <div>
            <H6Grey>7.9</H6Grey> The User has the right and ability to either
            accept or decline the use of Cookies on their computer’s web
            browser, whether they are logged in as a member, or simply casually
            visiting the website. However, declining the use of Cookies may
            limit a User’s access to certain features on the website.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            8. General Conditions for Processing Personal Information
          </H4Grey>
          <div>
            <H6Grey>8.1</H6Grey> Surestart Online Financial Services (Pty) Ltd
            shall comply with all laws, contracts or regulations when it
            processes a User’s personal information.
          </div>
          <div>
            <H6Grey>8.2</H6Grey> Surestart Online Financial Services (Pty) Ltd
            shall not act unreasonably when processing a User’s personal
            information. This means that it will collect and process a User’s
            personal information in a way that the User can reasonably expect
            and in a way that is fair.
          </div>
          <div>
            <H6Grey>8.3</H6Grey> Surestart Online Financial Services (Pty) Ltd
            shall respect the User’s right to privacy at all times. If there is
            another way in which it can achieve the same goal without posing any
            risk of harm to the privacy rights of the User, then it will choose
            that option.
          </div>
          <div>
            <H6Grey>8.4</H6Grey> Similarly, if Surestart Online Financial
            Services (Pty) Ltd needs to process personal information but there
            are less privacy-invasive methods of collecting, using and sharing
            that information, then it will use those methods.
          </div>
          <div>
            <H6Grey>8.5</H6Grey> Surestart Online Financial Services (Pty) Ltd
            shall ensure that the personal information that is collected and
            processed is and remains relevant to the identified purpose/s for
            such processing, and that such information is and remains adequate,
            but not excessive, for achieving the identified purpose/s.
          </div>
          <div>
            <H6Grey>8.6</H6Grey> If there are any alternative ways to achieve
            the identified purpose/s without processing personal information,
            Surestart Online Financial Services (Pty) Ltd shall not process that
            personal information.
          </div>
          <div>
            <H6Grey>8.7</H6Grey> Surestart Online Financial Services (Pty) Ltd
            shall ensure that the processing activities it chooses to apply are
            proportionate to achieving the identified purpose/s and that no less
            privacy invasive measures are available to achieve the same
            purpose/s.
          </div>
          <div>
            <H6Grey>8.8</H6Grey> Surestart Online Financial Services (Pty) Ltd
            shall ensure that, regardless of the stated purpose/s for processing
            personal information, the rights and interests of Users will not be
            unnecessarily prejudiced or infringed, unless it cannot be avoided,
            and then in such cases, it shall ensure that its own rights and/or
            interests justify such prejudice or infringement taking place.
            <br /> Once Surestart Online Financial Services (Pty) Ltd has
            achieved the purpose for the collection of the User’s personal
            information, it will destroy or delete such information, unless the
            User has directed otherwise in writing, or Surestart Online
            Financial Services (Pty) Ltd is required by law to retain the
            information for a longer period of time. <br /> If Surestart Online
            Financial Services (Pty) Ltd no longer needs to process personal
            information to achieve the purpose originally specified, it will
            stop using that information.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            9. Disclosure and Sharing of Personal Information
          </H4Grey>
          <div>
            <H6Grey>9.1</H6Grey> Surestart Online Financial Services (Pty) Ltd
            may, in the course of providing any content or services on this
            website, or for the purposes of concluding or performing any sale or
            other transaction with a User, share certain personal information
            with third party operators who perform certain processing activities
            on behalf of Surestart Online Financial Services (Pty) Ltd.
          </div>
          <div>
            <H6Grey>9.2</H6Grey> The information shared and the categories of
            third party operators with whom it is shared will always be notified
            to you prior to being shared.
          </div>
          <div>
            <H6Grey>9.3</H6Grey> Surestart Online Financial Services (Pty) Ltd
            may also share aggregated information about Users of this website
            and their usage patterns. Surestart Online Financial Services (Pty)
            Ltd may also use such aggregated information to help advertisers
            target specific audiences. Such aggregated information will be
            de-identified and the User’s personal information will not be
            disclosed.
          </div>
          <div>
            <H6Grey>9.4</H6Grey> Other than as stated in clause 9.1 and 9.3,
            Surestart Online Financial Services (Pty) Ltd shall not share a
            User’s personal information with any third parties unless it has the
            User’s express consent to do so.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            10. User's Rights in Relation to the Processing of their Personal
            Information
          </H4Grey>
          <div>
            <div>
              <H6Grey>10.1</H6Grey> Users shall have the following rights in
              relation to the processing of their personal information:
            </div>
            <div>
              • To access and correct any personal information held by Surestart
              Online Financial Services (Pty) Ltd about them;
            </div>
            <div>• To object to the processing of their information; and </div>
            <div>• To lodge a complaint with the Information Regulator.</div>
          </div>

          <div>
            <H6Grey>10.2</H6Grey> Users may make a request in terms of clause
            10.1 by following the process for making such a request as set out
            in Surestart Online Financial Services (Pty) Ltd PROATIA manual.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            11. Further Processing
          </H4Grey>
          <div>
            <div>
              <H6Grey>11.1</H6Grey> Surestart Online Financial Services (Pty)
              Ltd shall not process a User’s personal information for any
              purpose not previously specified except in the following
              circumstances:
            </div>
            <div>
              • Where the User has consented to such further processing;
            </div>
            <div>
              • Where the further processing is necessary for the exercise of
              any contractual rights or the fulfillment of any obligations
              between Surestart Online Financial Services (Pty) Ltd and the
              User;
            </div>
            <div>
              • Where the further processing activities are linked to or
              compatible with the original purpose;
            </div>
            <div>
              • Where the further processing is necessary for the prevention,
              detection, investigation, prosecution and punishment of an
              offence;
            </div>
            <div>
              • Where the further processing is necessary to enforce any law;
            </div>
            <div>
              • Where the further processing is necessary for the conduct of
              legal proceedings in any court or tribunal that have commenced or
              are reasonably contemplated;
            </div>
            <div>
              • Where the further processing is necessary to prevent or mitigate
              a serious and imminent threat to the life or health of the User or
              another individual;
            </div>
            <div>
              • Where the further processing is necessary for historical,
              statistical or research purposes. Surestart Online Financial
              Services (Pty) Ltd shall ensure that if it intends processing
              personal information for other purposes not previously specified,
              it shall notify the User of such further purposes and the possible
              consequences of the intended further processing for the User.
            </div>
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            12. Accuracy, Correctness and Completeness of Personal Information
          </H4Grey>
          <div>
            <H6Grey>12.1</H6Grey> Surestart Online Financial Services (Pty) Ltd
            shall take reasonably practicable steps to ensure that the personal
            information kept by it about Users is complete, accurate, not
            misleading and is updated when necessary.
          </div>
          <div>
            <H6Grey>12.2</H6Grey> However, if a User is aware of any personal
            information in Surestart Online Financial Services (Pty) Ltd custody
            that is incorrect, inaccurate or which needs to be updated, the User
            must make a written request to Surestart’s information officer at
            info@surestart.co.za. to update or correct the relevant information.
          </div>
          <div>
            <H6Grey>12.3</H6Grey> If a User has contested the accuracy of any
            personal information being used by Surestart Online Financial
            Services (Pty) Ltd, it shall immediately stop using that information
            until its accuracy has been verified.
            <br />
            Surestart Online Financial Services (Pty) Ltd reserves its right to
            only adhere to a request from a User in terms of clause 12.2 if the
            correction or updating of that information will result in the
            personal information being correct and accurate.
            <br />
            Where personal information that has been shared by Surestart Online
            Financial Services (Pty) Ltd with a third party is subsequently
            updated or corrected, Surestart Online Financial Services (Pty) Ltd
            shall ensure that all third parties, with whom that information was
            shared, receives the updated and/or corrected version of the
            information as soon as it has been updated and/or corrected.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            13. Security Safeguards
          </H4Grey>

          <div>
            <H6Grey>13.1</H6Grey> Surestart Online Financial Services (Pty) Ltd
            is committed to protecting the personal information in its custody
            against any loss of, damage to or unauthorised destruction of that
            information, and to prevent any unauthorised parties from accessing
            that information.
          </div>
          <div>
            <H6Grey>13.2</H6Grey> Surestart Online Financial Services (Pty) Ltd
            takes steps to continually identify and document any risks to the
            personal information it has in its possession or under its control
            and that appropriate security safeguards are in place against those
            risks.
          </div>
          <div>
            <div>
              <H6Grey>13.3</H6Grey> Surestart Online Financial Services (Pty)
              Ltd shall ensure that in any contracts entered into with third
              party operators who process personal information on Surestart
              Online Financial Services (Pty) Ltd behalf, include the following
              obligations:
            </div>
            <div>
              • The operator shall not process any personal information without
              Surestart Online Financial Services (Pty) Ltd knowledge and
              authority;
            </div>
            <div>
              • The operator shall treat all personal information given to it as
              confidential and shall not disclose it to any unauthorised third
              parties;
            </div>
            <div>
              • The operator shall establish and maintain adequate security
              measures which are the same or offer similar protection over the
              personal information as that employed by Surestart Online
              Financial Services (Pty) Ltd;
            </div>
            <div>
              • The operator shall notify Surestart Online Financial Services
              (Pty) Ltd immediately where there are reasonable grounds to
              believe that any personal information has been leaked to or
              accessed by any unauthorised person;
            </div>
            <div>
              • If the operator is situated in another country, it must comply
              with the data protection laws in that country and be able to
              provide verification that it is so compliant;
            </div>
            <div>
              • If an operator is legally obliged to disclose any personal
              information processed by them on Surestart Online Financial
              Services (Pty) Ltd behalf to other parties, it must notify
              Surestart Online Financial Services (Pty) Ltd beforehand to enable
              Surestart Online Financial Services (Pty) Ltd and/or individual
              Users to protect their rights if necessary.
            </div>
          </div>
          <div>
            <H6Grey>13.4</H6Grey> Users must maintain the secrecy of any
            passwords used to gain access to this website and should change such
            passwords regularly.
          </div>
          <div>
            <H6Grey>13.5</H6Grey> Surestart Online Financial Services (Pty) Ltd
            shall ensure that all personal information on its systems is
            properly backed-up and that back-up copies are stored separately
            from the live files.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            14. Notification of Breach of Security
          </H4Grey>
          <div>
            <H6Grey>14.1</H6Grey> If personal information about a User is
            inadvertently leaked or Surestart Online Financial Services (Pty)
            Ltd security has been unlawfully breached by any unauthorised party,
            Surestart Online Financial Services (Pty) Ltd Online Financial
            ServiesServices (Pty) Ltd shall immediately identify the relevant
            Users who may be affected by the security breach, and shall contact
            them at their last known email address or contact details or by the
            quickest means possible.
          </div>
          <div>
            <H6Grey>14.2</H6Grey> Surestart Online Financial Services (Pty) Ltd
            shall provide sufficient information to the User to allow him or her
            to take the necessary protective measures against the potential
            consequences of the compromise, or shall advise Users of the steps
            to be taken by them and the possible consequences that may ensue
            from the breach for them.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            15. Decisions Based on Personal Information Processed
          </H4Grey>
          <div>
            <H6Grey>15.1</H6Grey> If Surestart Online Financial Services (Pty)
            Ltd is required to make a decision about a User using any personal
            information that has been obtained, it shall ensure that a record of
            such information and the decision made is kept for a reasonable
            period of time to give the User an opportunity to request access to
            that record.
          </div>
          <div>
            <H6Grey>15.2</H6Grey> Surestart Online Financial Services (Pty) Ltd
            shall allow a User a reasonable opportunity to make representations
            before any decision is made solely on the basis of the personal
            information processed, if that decision will affect the legal
            position of the User, or will otherwise adversely affect them in
            some manner or form.
          </div>
          <div>
            <H6Grey>15.3</H6Grey> Surestart Online Financial Services (Pty) Ltd
            shall always ensure that the underlying logic behind any decision
            made pursuant to the automated processing of personal information is
            sound and that this underlying logic can be communicated to the User
            to enable them to make representations.
          </div>
          <div>
            <H6Grey>15.4</H6Grey> If Surestart Online Financial Services (Pty)
            Ltd has made a decisions based on incorrect personal information, it
            shall immediately revisit that decision as soon as it receive Policy
            or becomes aware of the error or inaccuracy of that information.
          </div>
        </Row>

        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            16. Linked Third Party Websites
          </H4Grey>
          <div>
            <H6Grey>16.1</H6Grey> This website may contain links or references
            to other websites, including those of advertisers (“third party
            websites“) which are not under Surestart Online Financial Services
            (Pty) Ltd control.
          </div>
          <div>
            <H6Grey>16.2</H6Grey> The provisions of this Privacy Policy are not
            applicable to third party websites and Surestart Online Financial
            Services (Pty) Ltd shall not responsible for the information
            processing practices and/or privacy policies of those third party
            websites, or the cookies that those websites may use.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            17. Direct Marketing
          </H4Grey>
          <div>
            <H6Grey>17.1</H6Grey> The User hereby consents to the processing of
            their personal information for the purpose of direct marketing by
            means of electronic communications including automatic calling
            machines, facsimile machines, SMS’s or electronic mail.
          </div>
          <div>
            <H6Grey>17.2</H6Grey> Where a User is a pre-existing customer of
            Surestart Online Financial Services (Pty) Ltd, Surestart Online
            Financial Services (Pty) Ltd shall be entitled,without the User’s
            consent, to sending electronic communications to the User for the
            purpose of marketing similar products or services offered by
            Surestart Online Financial Services (Pty) Ltd .
          </div>
          <div>
            <H6Grey>17.3</H6Grey> The User may object, free of charge, and
            without unnecessary formality, to the use of their details either
            when the information was first collected from them or when each
            subsequent electronic communication is sent to them by Surestart
            Online Financial Services (Pty) Ltd .
          </div>
          <div>
            <H6Grey>17.4</H6Grey> The User can opt out of receiving further
            marketing communications by un-checking certain boxes on the forms
            used on the website to collect their personal information, or by
            contacting Surestart Online Financial Services (Pty) Ltd at:
            info@surestart.co.za.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            18. Children’s Personal Information
          </H4Grey>
          <div>
            Surestart Online Financial Services (Pty) Ltd shall not process any
            personal information relating to a person under the age of 18 years
            unless it has obtained consent from that person’s parent or legal
            guardian. If this website is being accessed by the parent or
            guardian of a child under the age of 18 years, and personal
            information pertaining to that child is being provided by the parent
            or guardian, then they hereby expressly consent to Surestart Online
            Financial Services (Pty) Ltd processing such information according
            to the further provisions of this Privacy Policy.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            19. Cross Border Transfers of Personal Information
          </H4Grey>
          <div>
            <div>
              <H6Grey>19.1</H6Grey> Surestart Online Financial Services (Pty)
              Ltd may transfer personal information to another country in the
              following circumstances:
            </div>
            <div>
              • The transfer is necessary for the performance of a contract that
              Surestart Online Financial Services (Pty) Ltd has with the User;
            </div>
            <div>
              • The transfer is necessary for the conclusion or performance of a
              contract with a third party which is for the benefit of or in the
              interest of the User;
            </div>
            <div>
              • The transfer is otherwise for the benefit of the User; or
            </div>
            <div>
              • The User has consented to the transfer of their information.
            </div>
          </div>
          <div>
            <H6Grey>19.2</H6Grey> If Surestart Online Financial Services (Pty)
            Ltd is required to transfer personal information from South Africa
            to a third party in a foreign country, it shall ensure that the
            third party is subject to a law, binding code of conduct or contract
            which effectively upholds principles for the reasonable processing
            of personal information which are substantially similar to the data
            protection offered in the Republic of South Africa.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            20. Retention of Information
          </H4Grey>
          <div>
            <div>
              <H6Grey>20.1</H6Grey> Surestart Online Financial Services (Pty)
              Ltd will keep a record of any personal information collected for
              no longer than is necessary to achieve the specific purpose for
              which it collected such information in the first place unless:
            </div>
            <div>
              • It is required by law to keep a record of such information for a
              longer period of time; or
            </div>
            <div>
              • It needs to keep a record of such information for another lawful
              purpose; or
            </div>
            <div>
              • It has a contractual obligation to keep a record of such
              information; or
            </div>
          </div>
          <div>
            <H6Grey>20.2</H6Grey> The User has consented to their information
            being kept for a longer period.
            <br />
            Surestart Online Financial Services (Pty) Ltd may, if it has
            de-identified personal information, kept such information for
            historical, statistical or research purposes. Surestart Online
            Financial Services (Pty) Ltd shall ensure that appropriate
            safeguards are in place to prevent those records from being used for
            any other purposes, or against the information being re-identified.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            21. Returning, Destroying or Deleting Personal Information
          </H4Grey>
          <div>
            <div>
              <H6Grey>21.1</H6Grey>Where Surestart Online Financial Services
              (Pty) Ltd is no longer authorised to retain a record of any
              personal information, it shall either:
            </div>
            <div>
              • Ensure that the information is permanently destroyed or deleted
              as soon as reasonably practicable; or
            </div>
            <div>
              • Return the information to the User or transfer it to a third
              party, if requested by the User in writing to do so.
            </div>
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            22. Consent
          </H4Grey>
          <div>
            <H6Grey>22.1</H6Grey> The User hereby consents to the processing of
            their personal information in terms of the provisions of this
            Privacy Policy.
          </div>
          <div>
            <div>
              <H6Grey>22.2</H6Grey> The User acknowledges and agrees that such
              consent has been given voluntarily after the User has read and
              understood the provisions of this Privacy Policy, in particular,
              regarding the following:
            </div>
            <div>• The types of personal information to be processed;</div>
            <div>• The specific processing activities to be undertaken;</div>
            <div>• The specific purpose/s for such processing; and</div>
            <div>
              • The possible consequences for the User that may arise from such
              processing.
              <br /> Should a User wish to withdraw any consent previously given
              by the User, they must notify Surestart Online Financial Services
              (Pty) Ltd information officer in writing.
            </div>
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            23. Lodging an Objection
          </H4Grey>
          <div>
            A User may, on reasonable grounds, object to the processing of their
            personal information at any time after that processing has started.
            If a User wishes to object to the processing of their personal
            information, they must send written Policy of their objection to
            Surestart Online Financial Services (Pty) Ltd information officer,
            together with their reasons for doing so.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            24. Choice of Law
          </H4Grey>
          <div>
            This Privacy Policy shall be governed and interpreted in accordance
            with the laws of the Republic of South Africa
          </div>
        </Row>

        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
            25. Amendment of this Privacy Policy
          </H4Grey>
          <div>
            Surestart Online Financial Services (Pty) Ltd reserves the right to
            change, update, add, remove and/or amend any of the provisions of
            this Privacy Policy from time to time. Such changes, updates,
            additions, removals or amendments will become effective from the
            date of their publication on this website. <br />
            It is the User's obligation to periodically check the provisions of
            this Privacy Policy for any such changes, updates, additions,
            removals or amendments. <br />
            The User's continued use of this website following any changes,
            updates, additions, removals or amendments to this Privacy Policy
            will be considered Policy of the User's acceptance to abide by and
            be bound by this Privacy Policy, as amended.
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          <H4Grey style={{ fontFamily: "var(--primary-font)" }}>Contact</H4Grey>
          <div>
            For more information on your rights to Privacy over your
            information, or the information processing activities of Surestart
            Online Financial Services (Pty) Ltd , please do not hesitate to
            contact us directly on: info@surestart.co.za.
            <br />
            Date of last update: 8 July 2021
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
