import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import classNames from "classnames";
import style from "./Button.module.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "utils/useWindowDimension";


export default function DropdownButton({
    bg,
    color,
    name,
    isCommercial,
    link,
    float
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
const handleClickLeisure = () => {
    setAnchorEl(null);
    // history.push(link);
    history.push("/contact");
};

const handleClickCommercial = () => {
    setAnchorEl(null);
    dispatch({type: "IS_COMMERCIAL",
    payload:true,})
    // history.push(link);
    history.push("/Commercial-drone");
};
window.addEventListener("scroll",()=>{
    handleClose()
})
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
            background: `${bg}`,
            color: `${color}`,
            borderRadius: "5px",
            boxShadow: "0px 3px 8px #00000059",
            textTransform:"capitalize",
            marginRight: width > 770 ? "1rem" : "",
             float: float,
             height:"50px",
            fontSize: "16px",
          }}
          className={classNames(
            "px-3 py-2 d-flex",
            style.body__btn__hover,
            style.button__header
          )}
      >
         {name}{<ArrowDownwardIcon />}
      </Button>
     
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
      >
        <MenuItem onClick={handleClickLeisure} style={{fontFamily:"Nunito"}}>Leisure Drone </MenuItem>
        <MenuItem onClick={handleClickCommercial} style={{fontFamily:"Nunito"}}>Commercial Drone</MenuItem>
      </Menu>
    </div>
  );
}
