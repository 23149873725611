/* eslint-disable */

// <----- API URL  ------->
export const APIUrl = {
  login: "/lg/authorisation/login",
};

// <----- Environment types  ------->
export const ENV_TYPE = {
  DEV: 'dev',
  PROD: 'prod',
  UAT: 'uat',
};

// <----- Environments  ------->
export const ENV = {
  ["dev"]: "https://briisk-i-dev-api.azure-api.net", 
  ["uat"]: "https://briisk-i-uat-api.azure-api.net",
  ["prod"]: "https://briisk-i-prod-api.azure-api.net",
};

export const OCP_APIM_SUBSCRIPTION_KEY = {
  ["dev"]: "ec2659d3859d421d801776e4efad5780",
  ["uat"]: "3296a582dbb540b8ae4843332c638a3e",
  ["prod"]: "1d56ec9f78654750b7ff2ae8c90d8c6f",
};

export const PRODUCT_INSTANCE_ID = {
  ["dev"]: "03016f31-cd18-38fd-a1c5-548f626e3e5d",  //DRONE
  // ["dev"]: "e25c4155-df43-4016-8c72-3a764df1d5a8",  //COMMERCIAL_DRONE
  ["uat"]: "30fe5f6a-ed76-46a8-b75a-952c4d16e6fb",
  ["prod"]: "e8c50c12-0061-42d8-bd6d-f50b0df70f79"
};

export const COMMERCIAL_PRODUCT_OPTION_REF = {
  ["dev"]: "b7d525e6-473d-4929-ba04-17d3e135dea9",  //COMMERCIAL_DRONE
  ["uat"]: "a7c587b4-f635-49c8-b1fa-ed516db1e36d",
  ["prod"]: "a7c587b4-f635-49c8-b1fa-ed516db1e36d"
};

export const COMMERCIAL_PRODUCT_INSTANCE_ID = {
  // ["dev"]: "03016f31-cd18-38fd-a1c5-548f626e3e5d",  //DRONE
  ["dev"]: "e25c4155-df43-4016-8c72-3a764df1d5a8",  //COMMERCIAL_DRONE
  ["uat"]: "30fe5f6a-ed76-46a8-b75a-952c4d16e6fb",
  ["prod"]: "de2ee686-1ee1-4f4f-b2f9-66fa874535c5"
};

export const AUTH_EMAIL = {
  ["dev"]: "briisk@tpinsure.com",
  ["uat"]: "cybersme@itoo.co.za",
  ["prod"]: "TPIDroneFE@surestart.co.za"
};

export const AUTH_PASSWORD = {
  ["dev"]: "V3r53k3r",
  ["uat"]: "Cyb3r5M32021",
  ["prod"]:"a.:T8fiGh9Lu2!Q"
};

export const IVERY_BASEURL = {
  ["dev"]: "https://portal.iveri.net/Lite/Authorise.aspx",
  ["uat"]: "https://portal.iveri.net/Lite/Authorise.aspx",
  ["prod"]: "https://portal.iveri.net/Lite/Authorise.aspx"
};

export const LITE_MERCHANT_APP_ID = {
  ["dev"]: "753B138D-3720-4744-B075-89DF833433BC",
  ["uat"]: "753B138D-3720-4744-B075-89DF833433BC",
  ["prod"]: "753B138D-3720-4744-B075-89DF833433BC"
};

export const BANK_BRANCH_GUID = {
  ["dev"]: "F5D22457-30FC-8FC4-90DB-BC43DC258464",
  ["uat"]: "f696fd88-3c18-debf-5b04-b3447caa076d",
  ["prod"]:"7b9d8f0d-62fe-cf02-d6cf-0e5dafccef01"
};

export const NETWORK_ID = {
  ["dev"]: "6de8373c-c45b-f623-b1e7-5b63fb594da2",
  ["uat"]: "f696fd88-3c18-debf-5b04-b3447caa076d",
  ["prod"]: "b7aba668-7f2f-30bc-c721-efad7d786544" 
};
 
export const COMPANY_ID = {
  ["dev"]: "f9ddb0de-fa0d-7496-6c8e-98d357ede7da",
  ["uat"]: "3B6B26B7-C881-2FBF-A0AA-2BDF3A1C045B",
  ["prod"]: "f9ddb0de-fa0d-7496-6c8e-98d357ede7da"
};

export const COMPANY_CODE = {
  ["dev"]: "i2",
  ["uat"]: "i2",
  ['prod']:"i2",
  // ['prod']:"47233"
};

export const MIDDLEWARE_URL = {
  ['dev']: 'https://react-middleware-dev.azurewebsites.net',
    // ['dev']: 'http://localhost:5000',
  ['uat']: 'https://react-middleware-uat.azurewebsites.net',
  ['prod']: 'https://react-middleware-prod.azurewebsites.net',
  // ['prod']: 'http://localhost:8000',
}

export const RECAPTCHA_SITE_KEY = {
  ['dev']: '6LcbuCIeAAAAAGkQsiHqYkfAGqUT3_StbKKo6Q-7',
  ['uat']: '6LfwwSIeAAAAAGADWlrwmETdNbtq_ypIE6Yo-Xp-',
  ['prod']: '6LcbuCIeAAAAAGkQsiHqYkfAGqUT3_StbKKo6Q-7' 
}
/* eslint-disable */
