// default imports for react project
import React from "react";

// import classname package to combine module css and normal css
import classNames from "classnames";

//   import custom component
import style from "./Button.module.css";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import useWindowDimensions from "utils/useWindowDimension";

// React functional component starts here..
function ButtonWithDownArrow({
  name,
  bg,
  buttonType,
  color,
  float,
  fontSize,
  onClick,
  type,
}) {
  const { width } = useWindowDimensions();

  return (
    <button
      onClick={onClick}
      type={buttonType && buttonType}
      style={{
        background: `${bg}`,
        color: `${color}`,
        borderRadius: "5px",
        boxShadow: "0px 3px 8px #00000059",
        float: float,
        fontSize: fontSize,
        marginRight: width > 770 ? "1rem" : "",
      }}
      className={classNames(
        "px-3 py-2 d-flex",
        style.body__btn__hover,
        style.button__header
      )}
    >
      {type && <div className="mx-2">{type === "left" && "←"}</div>}
      <div>
        {name} {!type && <ArrowDownwardIcon />}
      </div>
      {type && <div className="mx-2">{type === "right" && "→"}</div>}
    </button>
  );
}

export default ButtonWithDownArrow;
