// <----------- Importing global style sheets --------->
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/common.css";

// <----------- Importing Reac-router dom --------->
import { BrowserRouter, Switch, Route, useLocation, useHistory } from "react-router-dom";

// <----------- Importing Essential components --------->
import Navbar from "./components/Navbar/Navbar";
import Homepage from "screens/Homepage";
import TopNavbar from "components/Navbar/TopNavbar";
import Footer from "components/Navbar/Footer";
import FastFacts from "screens/FastFacts";
import About from "screens/About";
import Claims from "screens/Claims";
import PrivacyPolicy from "screens/CompanyPolicies/PrivacyPolicy";
import Disclosures from "screens/CompanyPolicies/Disclosures";
import TermsAndConditions from "screens/CompanyPolicies/TermsAndConditions";
import FAQ from "screens/FastFacts/FAQ";
import LeisureFacts from "screens/FastFacts/Leisure_facts";
import CommercialFacts from "screens/FastFacts/Commercial_facts";
import useWindowDimensions from "utils/useWindowDimension";
import Contact from "screens/Contact";
import { useDispatch, useSelector } from "react-redux";
import { login } from "store/actions/auth";
import { useEffect } from "react";
import Form from "./screens/Forms/Forms";
import PaymentSuccess from "screens/Forms/PaymentSuccess";
import { getAgentDetails, getAgentOnboarding } from "store/actions/auth";
import GIFLoader from "./assets/images/loader.gif";
import { updateLoading } from "store/actions/auth";
import DebitMandate from "screens/CompanyPolicies/DebitMandate";
import { clearState } from "store/actions/products";
import PaymentTermsAndConditions from "screens/CompanyPolicies/PaymentTermsAndConditions";
import { parseQuery, setParam } from "utils/utils";

// <---------- Initialize the App component ---------->

function App() {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const history = useHistory();
  const companyDetails = useSelector((state) => state.auth.companyDetails);
  const location = useLocation();
  const currentToken = useSelector((state) => state.auth.currentUserToken);
  const isLoading = useSelector((state) => state.auth.loading);

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  function parseQuery(queryString) {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }

  const agentCode = useSelector((state) => state.auth.agentCode);
  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  useEffect(() => {
    (async () => {
      await dispatch(login("DRONE"));
      // if (agentCode !== parseQuery(location.search).token) {
      if (parseQuery(location.search).token) {
        await dispatch(getAgentDetails(parseQuery(location.search).token));
      }
      // else if (Object.keys(companyDetails).length === 0) {
      else if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentDetails(agentCode));
      }
      // }
      else {
        dispatch(updateLoading(false));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(login("DRONE"));
        if (parseQuery(location.search).token) {
          await dispatch(
            getAgentOnboarding(parseQuery(location.search).token, "DRONE")
          );
        }
        if (Object.keys(agentOnBoarding || {}).length === 0) {
          await dispatch(getAgentOnboarding(agentCode, "DRONE"));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [dispatch]);

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     (async () => {
  //       await dispatch(login());
  //       if (agentCode !== parseQuery(location.search).token) {
  //         if (parseQuery(location.search).token) {
  //           await dispatch(getAgentDetails(parseQuery(location.search).token));
  //         } else {
  //           if (Object.keys(companyDetails).length === 0) {
  //             await dispatch(getAgentDetails(process.env.REACT_APP_ENV_TYPE === 'PROD' ? "SOFS-4" : "TPI-1"));
  //           }
  //         }
  //       } else {
  //         dispatch(updateLoading(false));
  //       }
  //     })();
  //   } else {
  //     dispatch(updateLoading(false));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (
      location.pathname !== "/forms" &&
      location.pathname !== "/drone-covered" &&
      location.pathname !== "/Commercial-drone" &&
      location.pathname !== "/payment-success"
    ) {
      dispatch(clearState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Loader state form
  // const isLoading = useSelector((state) => state.auth.loading);

  return !isLoading ? (
    // return (
    <div
      className="main__container"
      style={{ paddingBottom: width > 770 ? "280px" : "620px" }}
    >
      <BrowserRouter>
        {width > 430 ? <TopNavbar /> : null}
        <Navbar />
        <Switch>
          {/* <------- Forms --------> */}
          <Route path="/forms" exact component={Form} />
          <Route path="/drone-covered" exact component={Form} />
          <Route path="/Commercial-drone" exact component={Form} />
          {/* <------- Payment Success --------> */}
          <Route path="/payment-success" exact component={PaymentSuccess} />

          {/* <------- About page --------> */}
          <Route path="/about" component={About} />

          {/* <------- Contact page --------> */}
          <Route path="/contact" component={Contact} />

          {/* <------- Claims page --------> */}
          <Route path="/claims" component={Claims} />

          {/* <------- Disclosures page --------> */}
          <Route path="/disclosure" component={Disclosures} />

          {/* <------- Privacy policy page --------> */}
          <Route path="/privacy-policy" component={PrivacyPolicy} />

          {/* <------- Terms & Conditions page --------> */}
          <Route path="/terms-and-conditions" component={TermsAndConditions} />

          {/* <------- Payment Terms & Conditions page --------> */}
          <Route
            path="/payment-terms-and-conditions"
            component={PaymentTermsAndConditions}
          />

          {/* <------- FAQ page --------> */}
          <Route path="/faq" component={FAQ} />

          {/* <------- Fast facts page --------> */}
          <Route path="/fast-facts" component={FastFacts} />
          <Route path="/leisure-facts" component={LeisureFacts} />
          <Route path="/commercial-facts" component={CommercialFacts} />

          {/* <------- Product page --------> */}
          {/* <Route path="/product" component={Product} /> */}

          {/* <------- Debit Mandate --------> */}
          <Route path="/debit-mandate" exact component={DebitMandate} />

          {/* <------- Home page --------> */}
          <Route path="/" component={Homepage} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  ) : (
    <div className="gif-loader-full">
      <img src={GIFLoader} alt="GIF LOADER"></img>
    </div>
  );
}

export default App;
