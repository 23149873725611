// default imports for react project
import classNames from "classnames";
import React from "react";

// default import for routing in react
import { useHistory } from "react-router";

import style from "./Button.module.css";
import { useDispatch, useSelector } from "react-redux";


// React functional component starts here..
function BodyButton({
  name,
  color,
  bg,
  size,
  marginL,
  weight,
  marginT,
  black,
  marginR,
  width,
  align,
  link,
  hover,
  isCommercial
}) {
  // initialized history using react hook used to navigate
  const history = useHistory();
  const dispatch = useDispatch();

  // function to redirect page
  const handleClick = () => {
    if(isCommercial){
      dispatch({type: "IS_COMMERCIAL",
      payload:true,})
    }
    history.push(link);
  };

  // return jsx to display on screen
  return (
    <div
      style={{
        background: `${bg}`,
        color: `${color}`,
        marginRight: `${marginR}px`,
        borderRadius: "5px",
        fontSize: size,
        width: black ? "65%" : width ? width :"auto",
        marginLeft: marginL,
        fontWeight: weight,
        cursor: "pointer",
        marginTop: marginT,
        textAlign: "center",
      }}
      className={classNames(
        "px-2 py-2 d-flex",
        hover === "none" ? null : style.body__btn__hover
      )}
      onClick={handleClick}
    >
      <div className="w-100">{name}</div>
    </div>
  );
}

export default BodyButton;
