import React, { useCallback, useState, useLayoutEffect, useEffect } from "react";

// <--------- MUI Components --------->
import { Divider } from "@mui/material";

// <--------- React Bootstrap -------->
import { Col, Row } from "react-bootstrap";

// <--------- Essential Components -------->
import {
  DatePicker,
  Select,
  SelectWithRadioHorizontal,
  TextInput,
} from "../../../components";
import Button from "../../../components/Buttons/Button";

// Redux Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import { executeCalculator, updateValues } from "store/actions/products";

import GIFLoader from "../../../assets/images/loader.gif";
import validate from "validate.js";
import { toast } from "react-toastify";
import moment from "moment";

const Step1 = ({ handleNext, data }) => {
  // Attributes  i.e Account holder or Account number
  const [attributes, setAttributes] = useState();
  const [droneTheory, setDroneTheory] = useState();

  // Redux Hooks
  const dispatch = useDispatch();

  // Risk attributes from the Product.
  const riskAttributes = useSelector((state) => state.products.riskAttributes);

  // Loader state
  const [isLoading, setIsLoading] = useState(false);

  // Type ID
  const typeID = useSelector(
    (state) =>
      state.products.types.types && state.products.types.types[0].instanceId
  );

  useLayoutEffect(() => {
    // Setting up the risk attributes for Step 1
    setAttributes(
      data?.attributes?.length &&
        data?.attributes?.map((d) => ({ ...d, value: d.value ?? null }))
    );
    setDroneTheory(
      data?.attributes?.length &&
        data?.attributes?.find(
          (d) => d.name === "Drone Proficiency Theory Course"
        ).value
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.attributes]);

  // handling synthetic events
  const handleChange = (evt, instanceID) => {

    if (evt.target.name === "DroneProficiencyTheoryCourse") {
      setDroneTheory(evt.target.value);
    }
    const elementIndex = attributes?.findIndex(
      (ele) => ele.instanceId === instanceID
    );
    var arr = [...attributes];
    arr[elementIndex] = {
      ...arr[elementIndex],
      value: evt.target.value
    };
    setAttributes(arr);
  };

  const coverStartDate = attributes?.length && attributes?.find((x) => x.name === 'Cover Start Date');
  const [coverDateValidation, setCoverDateValidation] = useState(false);

  useEffect(() => {
    var maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 4)
    if(coverStartDate){
      if(new Date(coverStartDate.value) >= maxDate){
        setCoverDateValidation(new Date(coverStartDate.value));
      }else{
        var date = new Date();
        date.setDate(date.getDate()+5)
        setCoverDateValidation(date);
      }
    }
  },[coverStartDate])

  // Render fields
  const renderComponent = useCallback(
    (type, arr, i) => {
      switch (arr[i]) {
        case 7:
          return (
            <Col key={i} sm={6}>
              <Select
                errors={type.errors}
                label={type.name}
                name={type.name.replace(" ", "")}
                required={type.isRequired}
                value={type.value || ""}
                placeholder={type.description}
                options={JSON.parse(type?.settings)?.items}
                onChange={(evt) => handleChange(evt, type.instanceId)}
                droneTheory={droneTheory}
              />
            </Col>
          );

        case 2:
          return (
            <Col key={i} sm={6}>
              <SelectWithRadioHorizontal
                vertical={false}
                errors={type.errors}
                label={type.name}
                required={type.isRequired}
                name={type.name.replaceAll(" ", "")}
                value={type.value || ""}
                placeholder={type.description}
                options={JSON.parse(type?.settings)?.items}
                onChange={(evt) => handleChange(evt, type.instanceId)}
              />
            </Col>
          );
        case 1:
          return (
            <Col key={i} sm={6}>
              <TextInput
                required={type.isRequired}
                errors={type.errors}
                placeholder={type.description}
                name={type.name.replaceAll(" ", "")}
                label={type.name}
                value={type.value || ""}
                onChange={(evt) => handleChange(evt, type.instanceId)}
              />
            </Col>
          );

        case 3:
          return (
            <Col key={i} sm={6}>
              <TextInput
                required={type.isRequired}
                errors={type.errors}
                placeholder={type.description}
                label={type.name}
                name={type.name.replaceAll(" ", "")}
                value={type.value || ""}
                onChange={(evt) => handleChange(evt, type.instanceId)}
              />
            </Col>
          );

        case 5:
          return (
            <Col key={i} sm={6}>
              <DatePicker
                required={type.isRequired}
                placeholder={type.description}
                value={type.value}
                coverDate={type.name === 'Collection Date' ? attributes.find((x) => x.name === 'Cover Start Date')?.value : null}
                minDate={type.name === 'Collection Date' ? coverDateValidation : null}
                errors={type.errors}
                onChange={(evt) => handleChange(evt, type.instanceId)}
                label={type.name}
              />
              {(type.name === 'Collection Date' && type.value) && <div style={{ marginBottom: '20px' }}>
                With your selected deduction date, the first premium will be  <br/> deducted on {moment(type.value).format('LL')}  
              </div>}
            </Col>
          );

        default:
          return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributes, riskAttributes, coverDateValidation]
  );

  const handleSubmit = async (e) => {
    // Setting up the risk attributes constraints for form validations
    var obj = {};
    data.attributes.forEach((ex, i) => {
      if (ex.isRequired && ex.name !== "Payment Frequency") {
        obj = {
          ...obj,
          [ex.name.replaceAll(" ", "")]: { presence: { allowEmpty: false } },
        };
      }
      if(ex.name === 'Collection Date'){
        obj = {
          ...obj,
          [ex.name.replaceAll(" ", "")]: { presence: { allowEmpty: false, numericality: {
            greaterThan: data.attributes.find((x) => x.name === 'Cover Start Date')?.value,
          } } },
        };
      }
      if (ex.name.replaceAll(" ", "") === 'DroneRetailValue') {
        obj = {
          ...obj,
          [ex.name.replaceAll(" ", "")]: {
            presence: { allowEmpty: false },
            numericality: {
              greaterThanOrEqualTo: 2000,
              lessThanOrEqualTo: 150000,
              notGreaterThanOrEqualTo:"^We only insure drones with a minimum value of R 2 000",
              notLessThanOrEqualTo:"^We only insure drones to a maximum value of R 150 000 ",
             // message: parseInt(ex.value) < 2000 ? "^We only ensure drones with a minimum value of R 2 000 " : "^We only ensure drones to a maximum value of R 150 000 ",
            },
          },
        };
      }
    });

    // <---- Consolidating Errors values ---->
    var values = {};
    attributes
      .filter((f) => f.isRequired && f.name !== "Payment Frequency")
      .forEach((ex, i) => {
        if (ex.isRequired) {
          values = { ...values, [ex.name.replaceAll(" ", "")]: ex.value };
        }
      });

    var obj2 = { ...data, attributes };
    // <---- Errors check ---->
    const errors = validate(values, obj);
    if (errors || errors !== undefined) {
      setAttributes((attributes) =>
        attributes.map((ex) => ({
          ...ex,
          errors: errors[ex.name.replaceAll(" ", "")],
        }))
      );
      return;
    } else {
      setAttributes((attributes) =>
        attributes.map((ex) => ({
          ...ex,
          errors: "",
        }))
      );
    }

   
    dispatch(updateValues(obj2));

    try {
      setIsLoading(true);

      var dup = attributes.map((d) => ({
        dataType: d.dataType,
        name: d.name,
        instanceId: d.instanceId,
        value: d.name === "Payment Frequency" ? "Monthly" : d.value,
      }));

      var dup2 = attributes.map((d) => ({
        dataType: d.dataType,
        name: d.name,
        instanceId: d.instanceId,
        value: d.name === "Payment Frequency" ? "Annually" : d.value,
      }));
      // <---- Dispatching values for executeCalculators ---->
      await dispatch(
        executeCalculator(
          {
            factors: [],
            risks: [
              {
                attributes: dup,
                riskReference: data.instanceId,
                code: data.code,
              },
            ],
            productTypeReference: typeID,
          },
          0
        )
      );
      // return;

      await dispatch(
        executeCalculator(
          {
            factors: [],
            risks: [
              {
                attributes: dup2,
                riskReference: data.instanceId,
                code: data.code,
              },
            ],
            productTypeReference: typeID,
          },
          1
        )
      );

      setIsLoading(false);
      handleNext(1);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || err?.response?.data || "Error Occured"
      );
      setIsLoading(false);
    }
  };

  return attributes ? (
    // return (
    <div>
      {/* <---------- Title -------> */}
      <h4>{data?.name}</h4>

      {/* <---------- Title -------> */}
      <h6>{data?.description}</h6>

      <Divider row={2} />

      {/* <------- Attributes Starts ------> */}

      <Row>
        {attributes?.map((d, i) => renderComponent(d, [3, 2, 2, 10, 7, 5, 5], i))}
        <Col sm={6} xs={12}>
          <p style={{ fontWeight: "500" }}>
            To learn more about accredited drone proficiency training
            options, please visit <br />{" "}
            <strong>
              <a
                style={{ color: "inherit", textDecoration: "none" }}
                rel="noreferrer noopener"
                href="https://www.droneracingafrica.com"
                target="_blank"
              >
                www.droneracingafrica.com
              </a>
            </strong>
          </p>
        </Col>
        <Col sm={6} xs={12}>
          <Button
            type="submit"
            isLoading={isLoading}
            containerStyle={{ width: 200 }}
            onClick={handleSubmit}
            name="Get Quote"
            bg="#DA291C"
            color="white"
          />
        </Col>
      </Row>
      {/* <Row
        className={
          width > 430 ? "justify-content-end" : "justify-content-center"
        }
      >
        
      </Row> */}

      {/* <------- Attributes Ends ------> */}
    </div>
  ) : (
    // );
    <div className="gif-loader">
      <img src={GIFLoader} alt="GIF LOADER"></img>
    </div>
  );
};

export default Step1;
