import { capitalize, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePickerExpiry from "@mui/lab/DatePicker";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../App.css';

// <------ Divider (Dividing line) -------->

const Divider = ({ row }) => {
  return <div className="divider"></div>;
};

// <------ Select -------->

const Select = ({
  label,
  options,
  errors,
  onChange,
  value,
  placeholder,
  required,
  name,
  droneTheory,
}) => {
  return (
    <div className="form-group mt-4 mb-4">
      <label className="form-label">
        {label}
      
      </label>
      <select
        style={{
          height: "38px",
        }}
        className={`form-select ${!value && 'select-empty'} ${errors?.length && "error-input"}`}
        value={value}
        defaultValue={null}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
      >
      <option disabled selected hidden value="" >{placeholder}</option>
        {droneTheory === "YES" ? (
          options?.map((items, index) => (
            <option style={{color:"black"}} value={items} key={index}>
              {items}
            </option>
          ))
        
        ) : (
           <option style={{color:"black"}} value={options[0]}>{options[0]}</option>
        )}
      </select>
      {errors && (
        <span className="errors">
          {errors.map((error) => error).join(", ")}
        </span>
      )}
    </div>
  );
};

// <------ Select with Radio buttons -------->

const SelectWithRadio = ({
  label,
  errors,
  options,
  onChange,
  value,
  required,
}) => {
  return (
    <div className="form-group mt-4 mb-4">
      <label className="form-label">
        {label}
      
      </label>
      <div className="ml-3">
        <RadioGroup
          defaultValue={value}
          name="radio-buttons-group"
          value={value}
          onChange={onChange}
        >
          {options?.map((items, index) => (
            <FormControlLabel
              value={items}
              key={index}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#DA291C",
                      fontSize: 14,
                    },
                  }}
                />
              }
              label={items}
            />
          ))}
        </RadioGroup>
        {errors && (
          <span className="errors">
            {errors.map((error) => error).join(", ")}
          </span>
        )}
      </div>
    </div>
  );
};

const SelectWithRadioHorizontal = ({
  label,
  extras,
  onExtraChange,
  options,
  onChange,
  name,
  value,
  required,
  errors,
  tabindex,
}) => {
  return (
    <div className="form-group mt-4 mb-4">
      <label className="form-label">
        {label}
        
      </label>
      <div className="ml-3">
        <RadioGroup
          defaultValue={value}
          name={name}
          value={value}
          onChange={onChange}
        >
          <div className="row">
            {options?.length ? (
              options?.map((items, index) => (
                <div className="col-sm-6 col-md-4" key={index}>
                  <FormControlLabel
                    key={index}
                    value={items || ""}
                    control={
                      <Radio
                      //  tabIndex={index}
                        required={required}
                        sx={{
                          "&.Mui-checked": {
                            color: "#DA291C",
                            fontSize: 14,
                          },
                        }}
                      />
                    }
                    // label={capitalize(items.toLowerCase())}
                    label={capitalize(items)}
                    style={{width:"max-Content"}}
                  />
                </div>
              ))
            ) : (
              <div style={{ opacity: 0.5 }}>No Options found</div>
            )}
          </div>
        </RadioGroup>
      </div>
      {extras?.length &&
        extras.map((ex, index) => (
          <div className="col-12 mt-3" key={index}>
            <input
              className="form-control"
              value={ex.value || ""}
              name={ex.name}
              onChange={onExtraChange}
              placeholder={ex.label}
              // tabIndex={ex.tabindex}
            />
            {ex.errors && (
              <span className="errors">
                {ex.errors.map((error) => error).join(", ")}
              </span>
            )}
          </div>
        ))}
      {errors && (
        <span className="errors">
          {errors.map((error) => error).join(", ")}
        </span>
      )}
    </div>
  );
};

// <-------- Date Picker --------->

const DatePicker = ({ label, value, minDate, onChange, coverDate, errors, required, placeholder }) => {
  var maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 60);
  var collectionMaxDate = new Date(coverDate);
  collectionMaxDate.setDate(collectionMaxDate.getDate() + 30);
  return (
    <div className="form-group mt-4 mb-4">
      <label className="form-label">
        {label}       
      </label>
      <ReactDatePicker 
            className="form-control"
            onChange={(date) => {
                onChange({ target: { value: moment(date).format('YYYY-MM-DD'), name: label } })
            }}
            dateFormat="dd-MM-yyyy"
            placeholderText={placeholder}
            minDate={minDate || new Date()}
            maxDate={new Date( coverDate ? collectionMaxDate : maxDate)}
            selected={value ? new Date(moment(value)) : null}
      />
      {errors && <span className="errors">{errors.map((error) => error)}</span>}
    </div>
  );
};

const Question = ({
  question,
  detailedValue,
  errors,
  detailed,
  detailedErrors,
  onChange,
  value,
  required,
  labelStyle,
  label,
  onDetailedChange,
}) => {
  return (
    <div className="form-group mt-4">
      <label className="form-label" style={labelStyle}>
        {question}
        
      </label>
      <RadioGroup
        name="radio-buttons-group"
        value={value || ""}
        onChange={onChange}
        required={required}
      >
        <Row>
          <Col xs={6} md={6}>
            <FormControlLabel
              value={"Yes"}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#DA291C",
                      fontSize: 14,
                    },
                  }}
                />
              }
              label={"Yes"}
            />
          </Col>
          <Col xs={6} md={6}>
            <FormControlLabel
              value={"No"}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#DA291C",
                      fontSize: 14,
                    },
                  }}
                />
              }
              label={"No"}
            />
          </Col>
        </Row>
      </RadioGroup>

      {detailed && (
        <div className="form-group mb-1">
          <label className="form-label textarea-label">
            {label ? label : "Provide details:"}
          </label>
          <textarea
            className="form-control"
            required={true}
            onChange={onDetailedChange}
            value={detailedValue}
            rows={4}
          />
        </div>
      )}
      {errors?.length
        ? errors.map((ex) => (
            <span className="errors">This field can't be un-selected.</span>
          ))
        : null}
      {/* {detailedErrors && <span className="errors">This field can't be blank.</span>} */}
    </div>
  );
};

const Header = ({ title, sub, titleSize, textAlign, subStyle }) => {
  return (
    <div>
      <h4 className="header__title" style={{ fontSize: `${titleSize}px`, textAlign: textAlign }}>
        {title}
      </h4>
      {sub && (
        <h6 className="header__sub" style={subStyle}>
          {sub}
        </h6>
      )}
    </div>
  );
};

const VerticalSpacer = ({ rows }) => {
  return <div style={{ height: `${rows * 10}px` }}></div>;
};

const TextInput = ({
  label,
  errors,
  onChange,
  value,
  placeholder,
  removeMarginBottom,
  disabled,
  required,
  // tabIndex,
  name,
}) => {
  return (
    <div className={`form-group mt-4 ${!removeMarginBottom && "mb-4"} `}>
      {label && (
        <label className="form-label">
          {label}
        
        </label>
      )}
      <input
        className={`form-control ${errors?.length && "error-input"}`}
        name={name}
        disabled={disabled && disabled}
        required={required}
        placeholder={placeholder}
        type="text"
        onChange={onChange} 
        value={value || ""}
        // tabindex={tabIndex}
      />
      {errors && (
        <span className="errors">
          {errors.map((error) => error).join(", ")}
        </span>
      )}
    </div>
  );
};

const ExpiryInput = ({
  label,
  errors,
  onChange,
  value,
  placeholder,
  removeMarginBottom,
  disabled,
  required,
  name,
}) => {
  return (
    <div className={`form-group mt-4 ${!removeMarginBottom && "mb-4"} `}>
      {label && (
        <label className="form-label">
          {label}
        
        </label>
      )}
      <br />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePickerExpiry
          className={`form-control ${errors?.length && "error-input"}`}
          views={["year", "month"]}
          label={null}
          minDate={new Date()}
          maxDate={new Date("2029-06-01")}
          value={value ?? ""}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
      </LocalizationProvider>
      {/* {label && (
        <label className="form-label">
          {label}
          {required && "*"}
        </label>
      )}
      <input
        className={`form-control ${errors?.length && "error-input"}`}
        name={name}
        disabled={disabled && disabled}
        required={required}
        placeholder={placeholder}
        type="text"
        pattern="([0-9]{2}[/]?){2}"
        onChange={onChange}
        value={value || ""}
      /> */}
      {errors && (
        <span className="errors">
          {errors.map((error) => error).join(", ")}
        </span>
      )}
    </div>
  );
};

const DescriptionPlaceholders = ({ value, breaks, textStyle }) => {
  return (
    <div className="form-group mt-4 mb-4">
      {value.map((ele, index) => (
        <div key={index}>
          <h6 className="description_placeholders" style={textStyle}>
            {" "}
            {ele}
          </h6>{" "}
          {breaks && <br />}
        </div>
      ))}
    </div>
  );
};

const List = ({ label, items }) => {
  return (
    <div>
      <label className="form-label">{label}</label>
      <ul className="list">
        {items.map((item, index) => (
          <li key={index}> {item} </li>
        ))}
      </ul>
    </div>
  );
};

const UndefineComponent = ({ label }) => {
  return (
    <div>
      <label className="form-label">{label}</label>
      <p>No Attribute found.</p>
    </div>
  );
};

const SocialIcons = ({ label, icon, link }) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <a href={link} rel="noreferrer" target={link !== "#" && "_blank"}>
        {" "}
        <img src={icon} alt="icon" style={{ width: '50px' }} />
      </a>
      <h6
        className="mt-4"
        style={{ width: "200px", textAlign: "center", height: "auto" }}
      >
        {label}
      </h6>
    </div>
  );
};

// Exporting all the components

export {
  Divider,
  Select,
  SocialIcons,
  SelectWithRadio,
  DatePicker,
  UndefineComponent,
  Question,
  SelectWithRadioHorizontal,
  Header,
  VerticalSpacer,
  TextInput,
  DescriptionPlaceholders,
  List,
  ExpiryInput,
};
