import React from "react";

// <------- MUI Components  ---------->
import { Divider } from "@mui/material";

// <------ React-bootstrap Components ------->
import { Col, Row } from "react-bootstrap";

// Redux Hooks
import { useDispatch, useSelector } from "react-redux";

// <-------  Components  ---------->
import ProductCard from "../../../components/Forms/ProductCard";
import ButtonWithDownArrow from "components/Buttons/ButtonWithArrow";

// Actions
import { selectProduct } from "store/actions/products";
import { paymentFrequency } from "store/actions/products";

const Step2 = ({ handleNext, handleBack }) => {
  // Selectors from Store
  const productOptions = useSelector((state) => state.products?.productOptions);

  // Redux Hooks
  const dispatch = useDispatch();

  // Dispatching selected products
  const handleSelectQuote = (evt, type) => {
    try {
      dispatch(selectProduct(evt));
      dispatch(paymentFrequency(type))
      handleNext(2);
    } catch (err) {}
  };

  const decimalCheck = (string) => {
    if (string.split(".").length <= 1) {
      return `${string}.00`;
    } else  if(string.split(".")[1].length <= 1){
      return `${string}0`;
    }else {
      return string;
    }
  };

  return (
    <div>
      {/* <---------- Title -------> */}
      <h4>{productOptions?.[0]?.product?.name}</h4>

      {/* <------- Sub title ------> */}
      <h6>Select a quote</h6>

      <Divider row={2} />

      {/* <------- Product Cards Starts ------> */}
      <Row>
        {productOptions?.map((productOption, index) =>
          productOption?.productOptions?.map((ele, ind) => (
            <Col key={index} sm={6} className="mb-5 mt-3">
              <ProductCard
                title={ele.name}
                sub={
                  index === 0
                    ? "Total monthly payment"
                    : "One off annual payment"
                }
                liability={ele.body.risks[0].attributes[4]}
                theoryCourse={ele.body.risks[0].attributes[1]}
                practicalCourse={ele.body.risks[0].attributes[2]}
                price={`R ${decimalCheck(parseFloat(ele.rating.price.toFixed(2))
                  .toLocaleString("fi-FI")
                  .replaceAll(",", "."))}
                                    ${index === 0 ? "pm" : "pa"}`}
                onPress={() => handleSelectQuote(ele, index === 0 ? 'Monthly' : 'Annually')}
                description={ele.description}
                footer={true}
                //TODO for monthly --> price --> total (Display for this card) For payment --> prorataTotal to send paymentGateway as well summary card

                //TODO for annual --> price --> annualPremium (Display for this card) For payment --> prorataAnnualTotal to send paymentGateway as  well summary card
              />
            </Col>
          ))
        )}
      </Row>
      {/* <------- Product Cards Ends ------> */}

      {/* <------- Navigator button starts ------> */}
      <Row className="mt-5">
        <Col sm={2}>
          <ButtonWithDownArrow
            onClick={handleBack}
            type="left"
            name="Back"
            bg="#dd302a"
            color="white"
          />
        </Col>
        <Col sm={10} />
      </Row>
      {/* <------- Navigator button ends ------> */}
    </div>
  );
};

export default Step2;
