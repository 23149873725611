import moment from "moment";
import queryString from "query-string";


export const dateFormatter = (date) => {
    return moment(`${date.slice(7,11)}-${date.slice(4,6)}-${date.slice(1,3)}`).format("YYYY-MM-DD");
}

export const parseQuery = (queryString) => {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }


  export const setParam = (history,token) => {
    history.replace({
      search: `?${queryString.stringify({
        token: token ? token : process.env.REACT_APP_ENV_TYPE === 'PROD' ? "SOFS-4" : "TPI-1", 
        ...queryString.parse(history.location.search),
      })}`,
      state: history.location.state,
    });
  };