// default imports for react project
import React from "react";

// import styled components from material ui package
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//   import custom component
import Style from "./accordion.module.css";

// React functional component starts here..
function CustomAccordion({ title, children }) {
  return (
    <div className="mt-2">
      <Accordion className={Style.main}>
        <AccordionSummary
          className={Style.title}
          expandIcon={<ExpandMoreIcon className={Style.expand} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}

export default CustomAccordion;
