// initial state of the reducer
const initialState = {
  risks: {},
  riskAttributes: [],
  types: {},
  selectedOption: null,
  companyDetails: {},
};

// function to update state
function prevState(state = initialState, action) {
  // destructuring the action from function
  const { type, payload } = action;

  // switch to match type and update payload
  switch (type) {
    case "STATE":
      return {
        ...state,
        ...payload,
      };

    // return default state if the type doesnt match any case
    default:
      return state;
  }
}

export default prevState;
