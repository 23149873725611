// default imports for react project
import React, { useLayoutEffect, useEffect } from "react";

// default import for routing in react
import { useDispatch, useSelector } from "react-redux";

// import default style component from react-bootstrap package
import { Container, Row } from "react-bootstrap";

// import classname package to combine module css and normal css
import classNames from "classnames";

//   import custom component
import BreadCrumb from "components/Breadcrumb";
import { H2Red, H1Grey, H2Grey, H4Grey } from "components/Heading";

// import function from actions
import { addCrumb } from "store/actions/common";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

// React functional component starts here..
function TermsAndConditions() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentToken =  useSelector(state => state.auth.currentUserToken)

  // react layout hook to perform initial layout operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(
      addCrumb([
        { link: "/terms-and-conditions", name: "Terms And Conditions" },
      ])
    );
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload:parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])


  return (
    <div>
      <Container className={classNames("content")}>
        <BreadCrumb />
        <Row className={classNames("mt-5")}>
          <H1Grey className={classNames("mb-5")}>
            We’re always thinking about tomorrow,
          </H1Grey>
          <H2Grey style={{fontWeight: 600}}>so you can concentrate on making today count.</H2Grey>
        </Row>
        <Row className={classNames("mt-5")}>
          <H2Red>Drone Covered Terms and conditions</H2Red>
          <br />
          <div className={classNames("mt-2")}>
           
              Website Terms and Conditions of Use
          
            <br />
            General Disclaimer
          </div>
        </Row>
        <Row className={classNames("mt-4 ml-3")}>
          <ol>
            <li>
              Information contained on the website is of a general nature and is
              not intended to be specific advice or an exhaustive review of any
              subject. Please contact us for advice before relying on anything
              contained on the website as it may not suit your personal
              circumstances. If we use any calculations on the website these are
              similarly intended to be general guidelines and should not be
              taken as absolutes. Should you wish to rely on any calculation you
              are advised to check the calculations before relying on them.
              Drone Covered will not be liable for any inaccuracy in the
              calculation.
            </li>
            <li>
              Anyone may use any information presented on this website for
              non-commercial purposes, subject to any specific terms of use that
              might appear with such information, provided that the use of such
              information is accompanied by an acknowledgment that Drone Covered
              is the source. If you choose to access and use any of the
              information made available to you on this site, you do so subject
              to these Website Terms and Conditions of Use and any specific
              terms that the owner or provider of that information has imposed
              on its use.
            </li>
            <li>
              Please take note of all Website Terms and Conditions of Use. Your
              use of this website implies an acceptance of the Website Terms and
              Conditions of Use and any other specific terms and an agreement to
              be bound by them. In the event of a conflict between the Website
              Terms and Conditions of Use of this website and any specific terms
              applicable, and only to the extent of the conflict, the more
              specific term will apply.
            </li>
            <li>
              Drone Covered grants you a limited, revocable license to use this
              website subject to the Terms. The license is a personal,
              non-transferable, non-sublicensable, revocable license to access
              and use the website only as expressly permitted in these Website
              Terms and Conditions of Use. Except for this limited license, we
              do not grant you any other rights or license with respect to this
              website.
            </li>
            <li>
              Unless we have granted you permission in advance and in writing,
              you may use the website only for your personal, non-commercial
              use, and not to provide services to a third party. Any person
              wishing to use this web site contrary to the Website Terms and
              Conditions of Use must obtain prior written consent from Drone
              Covered.
            </li>
            <li>
              Drone Covered makes every effort to ensure, but cannot and does
              not guarantee, and makes no warranties as to, the accuracy,
              accessibility, integrity and timeliness of this information. Drone
              Covered assumes no liability or responsibility for any errors or
              omissions in the content of this site and further disclaims any
              liability of any nature for any loss howsoever arising in
              connection with using this website or any content contained
              herein. Furthermore, Drone Covered reserves the right to make
              changes to these materials at any time without notice. If you find
              any inaccurate, out of date, or incomplete information or material
              on this website, or if you suspect that something is an
              infringement of intellectual property rights, you must let us know
              immediately by contacting Drone Covered or the owner or provider
              of the information to which the issue relates.
            </li>
            <li>
              Unless otherwise stated, all right, title, interest, and ownership
              (including all rights under all copyright, patent, and other
              intellectual property laws) in, to or of this website are the sole
              property of or will vest in Drone Covered or a third party
              licensor. All moral rights are reserved.
            </li>
            <li>
              Trademarks. Drone Covered’s logo and sub-logos, marks, and trade
              names are the trademarks of Drone Covered and no person may use
              them without permission.  Any other trademark or trade name that
              may appear on this web site or other marketing material of Drone
              Covered is the property of its respective owner.
            </li>
            <li>
              You should independently verify any information and material on
              this website before relying upon it. The information and material
              on this website are not substitutes for the exercise of
              professional judgment.  If you are not qualified or experienced
              enough to make that judgment, you should take professional advice
              or contact the relevant information owner or provider for further
              information and advice.
            </li>
            <li>
              Hyperlinks to other websites are provided as a convenience only,
              and imply neither responsibility for, nor approval of, the
              information contained in those other websites on the part of Drone
              Covered. Drone Covered makes no warranty, either express or
              implied, as to the accuracy, availability, reliability or content
              of such information, text, graphics and hyperlinks. Drone Covered
              will not be liable for any indirect or consequential loss, or for
              any loss of business, profit, revenue, goodwill or data, lost or
              wasted management time or the lost time of other employees arising
              from your use of this website or any information or material on
              it, or your inability to use it (whether that loss is direct or
              indirect).
            </li>
            <li>
              While Drone Covered makes every effort to ensure that any
              executable material available to be downloaded from the Drone
              Covered website is free of any virus, it cannot guarantee that the
              material is free from any or all viruses. Drone Covered is not
              responsible for any loss or damage howsoever caused by the
              executable material and potentially malicious code contained
              therein.
            </li>
            <li>
              You are responsible for ensuring that your computer systems are
              suitable to access and use this website. You are responsible for
              implementing sufficient anti-virus and other security checks to
              ensure the accuracy of data input and output.
            </li>
            <li>
              The collection and retention of personal information by Drone
              Covered through its website and through the registration process
              is subject to the terms of the Drone Covered currently in force
              and available for review on this website.
            </li>
            <li>
               You specifically may not:
              <ul>
                <li>
                  Copy, reproduce, upload, post, display, republish, distribute,
                  transmit, any part of the content in any form whatsoever;
                </li>
                <li>
                  Modify, translate into any language or computer language, or
                  create derivative works from, any content or any part of this
                  website;
                </li>
                <li>Reverse engineer any part of this website;</li>
                <li>
                  Use the website other than to make legitimate policy
                  purchases;
                </li>
                <li>
                  Use the website to make any false, fraudulent, or speculative
                  policy purchases;
                </li>
                <li>
                  Disguise the information transmitted through the website;
                </li>
                <li>
                  Sell, offer for sale, transfer, or license any portion of the
                  website in any form to any third parties;
                </li>
                <li>
                  Use or access the website in any way that, in our reasonable
                  judgment, adversely affects the performance or function of the
                  website or any other computer systems or networks used by
                  Drone Covered’s other website users;
                </li>
                <li>
                  Upload or transmit to the website or use any device, software
                  or routine that contains viruses, Trojan horses, worms, time
                  bombs, or other computer programming routines that may damage,
                  interfere or attempt to interfere with, intercept, the normal
                  operation of our website, or appropriate the website or any
                  system, or take any action that imposes an unreasonable load
                  on our computer equipment, or that infringes upon the rights
                  of a third party;
                </li>
                <li>
                  Use any device, software, or routine that interferes, or
                  attempts to interfere, with the normal operation of our
                  website, or take any action that imposes an unreasonable load
                  on our equipment; or
                </li>
                <li>
                  disguise the origin of the information transmitted through the
                  website.
                </li>
              </ul>
            </li>
            <li>
              All warranties, representations, terms, conditions and
              undertakings, whether implied by statute, common law, custom,
              trade usage, course of dealing or otherwise (including any implied
              warranty, representation, term, condition or undertaking of
              satisfactory quality or fitness for a particular purpose) are
              excluded to the fullest extent allowed by law by Drone Covered and
              the information owners and providers.
            </li>
            <li>
              If the use of any information or material on this website is
              unlawful in any jurisdiction (because of your nationality,
              residence or for some other reason), that information or material
              is not offered.  If you are outside the Republic of South Africa
              you must satisfy yourself that you are lawfully able to use any
              such information and materials.  We accept no liability, to the
              extent allowed by the law, for any costs, losses or damages
              resulting from or related to the access or attempted access of any
              information or materials by anyone outside the Republic of South
              Africa.
            </li>
            <li>
              No delay, neglect or forbearance on the part of Drone Covered in
              enforcing any of their rights under the Website Terms and
              Conditions of Use will be, or be deemed to be, a waiver and it
              will not prejudice any right of Drone Covered.
            </li>
            <li>
              If any of the Website Terms and Conditions of Use is held to be
              unenforceable, illegal or in some other way invalid, the
              unenforceable, illegal or invalid provision will be deemed to be
              severable and will not affect the remainder of the Website Terms
              and Conditions of Use which will continue to be of full force and
              effect.
            </li>
            <li>
              All conditions and terms of use contained in this website are
              governed by South African law and you agree to submit to the
              non-exclusive jurisdiction of the courts of South Africa.
            </li>
            <li>
              No addition to or modification of any provision of these Website
              Terms and Conditions of Use will be binding on Drone Covered
              unless made in writing and signed by their duly authorised
              representatives.
            </li>
            <li>
              Drone Covered may modify, suspend, or discontinue providing this
              web site (with or without notice) and will not be liable.
            </li>
          </ol>
        </Row>
      </Container>
    </div>
  );
}

export default TermsAndConditions;
