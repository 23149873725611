// default imports for react project
import React from "react";

// import default style components from react-bootstrap package
import { Row } from "react-bootstrap";

// import util to get current window dimensions
import useWindowDimensions from "utils/useWindowDimension";

// React functional component starts here..
function CardHeader({ image, title, imageStyle }) {
  const { width } = useWindowDimensions();

  // return jsx to display on screen
  return (
    <div>
      <Row className={width < 430 ? `m-0 justify-content-center` : `m-0`}>
        {width > 430 ? (
          <img
            src={image}
            style={imageStyle}
            alt=""
            className="card_header_img p-2"
          />
        ) : null}
        <span className="w-auto card__header text-center">{title}</span>
      </Row>
    </div>
  );
}

export default CardHeader;
