import React, { useState } from "react";
import style from "./Form.module.css";
// <--------- Essential Components -------->
import {
  Header,
  Divider,
  VerticalSpacer,
  DescriptionPlaceholders,
} from "../../components";
import GIFLoader from "../../assets/images/loader.gif";

// <---------- Redux Hooks ----------->
import {useSelector } from "react-redux";

import SummaryCard from "components/Forms/SummaryCard";
import classNames from "classnames";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

const PaymentSuccess = () => {
  // Company details from the store

  // Selected product from the store
  const selectedProduct = useSelector(
    (state) => state.products.selectedProduct
  );

  // Policy response from the store
  const policyResponse = useSelector((state) => state.products.policyResponse);

  // Risk attributes from the store
  const [isLoading, setIsLoading] = useState(false);

  // Redux hooks for dispatching
  // const dispatch = useDispatch();
  // const location = useLocation();

  const companyDetails = useSelector((state) => state.products.companyDetails);

  const paymentFrequency = useSelector((state) => state?.products?.paymentFrequency);
  // External reference
  
  const getProrate = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => { const val = a.split(":"); obj[val[0]] = val[1]; })
    return obj;
  }

  const decimalCheck = (string) => {
    if (string.split(".").length <= 1) {
      return `${string}.00`;
    } else  if(string.split(".")[1].length <= 1){
      return `${string}0`;
    }else {
      return string;
    }
  };


  return (
    <div className="container mt-5 mb-5">
      <div className="d-flex justify-content-center flex-column">
        {/* <---------- Title -------> */}
        <h3 className={style.header}>Just like that and you are RTF!</h3>
        <VerticalSpacer rows={5} />
        {isLoading ? (
          <div className="gif-loader">
            <img src={GIFLoader} alt="Loader"></img>
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-center align-items-center w-100 text-center">
              <Header
                titleSize={28}
                title={`Thank you for the purchase of your Drone Covered policy`}
              />
            </div>

            <div className={classNames("container", style.boxBorder)}>
              <DescriptionPlaceholders
                breaks={false}
                textStyle={{
                  fontWeight: 700,
                  fontSize: "1.1rem",
                }}
                value={[
                  `An Email has be sent to ${companyDetails.eMail}`,
                  "Keep the document safe as they will be needed if you make a claim (please check your spam folder).",
                ]}
              />

              <Divider />


              <Row>

                <Col>
                <div className="d-flex mt-3 justify-content-center align-items-center">
                <SummaryCard
                  title={selectedProduct?.name}
                  type={
                    paymentFrequency.toLowerCase().includes("monthly")
                      ? "debit"
                      : "credit"
                  }
                  liability={selectedProduct?.body.risks[0].attributes[4]}
                  policyNumber={policyResponse.policies[0].policyReference}
                  policyHolder={
                    companyDetails.firstName + " " + companyDetails.lastName
                  }
                 
                  theoryCourse={selectedProduct?.body.risks[0].attributes[1]}
                  practicalCourse={selectedProduct?.body.risks[0].attributes[2]}
                  prorataPremiumFirstMonth={`R ${decimalCheck(parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message)?.prorataPremium)?.toLocaleString("fi-FI")?.replaceAll(",", "."))}`}
                  monthlyPremium={`R ${decimalCheck(
                    parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message)?.monthlyPremium).toLocaleString("fi-FI").replaceAll(",", ".")
                  )} pm`}
                  prorataPremium={`R ${decimalCheck(
                    parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message)?.annualPremium).toLocaleString("fi-FI").replaceAll(",", ".")
                  )}`}
                  premiumPaid={`R ${decimalCheck(
                    parseFloat(
                      getProrate(selectedProduct?.risks && selectedProduct?.risks[0]?.rating?.message)?.firstCollectionAmount
                    )
                      .toLocaleString("fi-FI")
                      .replaceAll(",", ".")
                  )}
                ${
                  selectedProduct?.body.risks[0].attributes[3].value ===
                  "Monthly"
                    ? "pm"
                    : "pa"
                }`}
                policyStartDate={
                  getProrate(
                    selectedProduct.risks &&
                      selectedProduct.risks[0].rating.message
                  )?.policyStartDate
                }
                policyEndDate={
                  getProrate(
                    selectedProduct.risks &&
                      selectedProduct.risks[0].rating.message
                  )?.policyEndDate
                }
                policyRenewalDate={
                  getProrate(
                    selectedProduct.risks &&
                      selectedProduct.risks[0].rating.message
                  )?.policyRenewalDate
                }
                  debitDate={getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).firstCollectionDate}
                  selectedProduct={selectedProduct}
                  companyDetails={companyDetails}
                  policyResponse={policyResponse}
                />
              </div>

                </Col>
                <Col>
                <VerticalSpacer rows={2} />
                <div>
                <h4 className="header__title">
                  As a future-focussed business, we are continuously researching
                  new developments to deliver the very best specialist products
                  that our customers’ complex and growing businesses need.
                  <a className="header__title" href="https://itoo.co.za">
                    [ See our products ]
                  </a>
                </h4>
              </div>

              <VerticalSpacer rows={2} />

              <Header
                title="SureStart"
                sub="1st Floor, Block B, North Park, Black River Park 2 Fir Street, Observatory, Cape Town, Western Cape, 7925 Phone: +27 87 550 9796 | Email: info@dronecovered.com"
                subStyle={{ fontWeight: 400 }}
              />
                </Col>
              </Row>

            
           

            
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;
