// Commercial
import ButtonWithDownArrow from "components/Buttons/ButtonWithArrow";
import React, { useState } from "react";

// <--------- React Bootstrap -------->
import { Col, Row } from "react-bootstrap";

// <---------- Redux Hooks ----------->
import { useDispatch, useSelector } from "react-redux";

// <---------- Actions ----------->
import { updateCompanyDetails, CreatePolicy } from "store/actions/products";

// <---------- Global Variable ----------->
import CONSTANTS from "utils/constants";


import { updateValues } from "store/actions/products";
import { Link } from "react-router-dom";
import useWindowDimensions from "utils/useWindowDimension";
import validate from "validate.js";
import GIFLoader from "../../../assets/images/loader.gif";

// <--------- Essential Components -------->
import {
  SelectWithRadioHorizontal,
  Divider,
  Select,
  VerticalSpacer,
  TextInput,
} from "../../../components";
import { H4Red } from "components/Heading";
import { useEffect } from "react";
import moment from "moment"; 

const Step4 = ({ data, handleNext, handleBack }) => {
  // <--------- Company details from store ---------->
  const companyDetails = useSelector((state) => state?.products?.companyDetails);

  // <--------- Assigning company details to the local state --->
  const [attributes, setAttributes] = useState({
    ...companyDetails,
  });

  const [drd, setDrd] = useState(data);

  const [submitClicked, setSubmitClicked] = useState(false);


  // <--------- product from the store --->
  const product = useSelector(
    (state) => state?.products?.productOptions?.product
  );
  const commercialStep = useSelector((state) => state.products.isCommercial);  
  const [isLoading, setIsLoading] = useState(false);
  const banks = useSelector((state) => state?.products?.bankDetails);
  const companyOrSoleProprietor = useSelector((state) => state?.products?.riskAttributes?.find((x) => x.code === 'APD')?.attributes?.find((y) => y.name === 'Applicant Type'))


  useEffect(() => {
  if(attributes){  
    setAttributes({
    ...attributes,
    country: 'South Africa',
  });
  }
  },[])

  // <---------  Synthetic events --->
  const handleChange = async (evt) => {
    const obj = {
      ...attributes,
      [evt.target.name.replaceAll(" ", "")]: evt.target.type === 'checkbox' ? evt.target.checked :  evt.target.value,
    };
    setAttributes(obj);

    if (submitClicked) {
      let constraints = getConstraints(obj);

      if (obj.workCompanyOptions !== "Company") {
        delete constraints.companyRegNo;
        delete constraints.vatNumber;
        delete constraints.workCompany;
      }

      // <---- Consolidating Errors values ---->
      const errors = validate(obj, constraints);
      if (errors) {
        setErrors(errors);
        return;
      } else {
        setErrors({});
      }
    }
  };

  // <---------  Synthetic events --->
  // Redux Hooks
  const dispatch = useDispatch();

  // Risk attributes from the Product
  const riskAttributes = useSelector((state) => state?.products?.riskAttributes);

  const { width } = useWindowDimensions();

  const [errors, setErrors] = useState({});

  // External reference
  const uniqueId = () => parseInt(Date.now() * Math.random()).toString();

  const getConstraints = (attributes) => {
    // constraints for the form values
    let constraints = {
      ...companySchema,
      companyRegNo: {
        presence: {
          allowEmpty:
            attributes.workCompanyOptions === "Company" ? true : true,
        },
      },
      vatNumber: {
        presence: {
          allowEmpty:
            attributes.workCompanyOptions === "Company" ? true : true,
        },
      },
    
      idNumber: {
        presence: {
          allowEmpty:
            attributes.workCompanyOptions === "Company" ? false : false,
        },
        format: {
          pattern: /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/,
          message: "^ Invalid South African ID Number",
        },
      },
    };

    return constraints;
  };
 
  const getProrate = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => { const val = a.split(":"); obj[val[0]] = val[1]; })
    return obj;
  }


  const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);

  // Submitting the form
  const handleSubmit = async () => {
    var date = new Date();
    setSubmitClicked(true);
    // Updating the company details values to the store.
    dispatch(updateCompanyDetails(attributes));

    const constraints = getConstraints(attributes);
    delete constraints.companyRegNo;
    delete constraints.vatNumber;
    delete constraints.workCompany;

    // <---- Consolidating Errors values ---->
    const errors = validate(attributes, constraints);
    if (errors) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    setIsLoading(true);
    const policyParams = {
      policies: [
        {
          // PolicyStatus: "Quoted",
          PolicyStatus: "Lead",
          currencyCode: "ZAR",
          members: [
            {
              ...attributes,
              customerIdentityType: 0,
              nationalityId: attributes.idNumber,
              // companyName: `${attributes.title} ${attributes.firstName} ${attributes.lastName}`,
              companyName: attributes["workCompany"],
              pin: attributes.postalStateCode
            },
          ],
          attributes: [],
          risks: [
            {
              externalReference: uniqueId(),
              attributes: riskAttributes?.find((x) => x.code === 'APD').attributes.map((d) => ({
                      name: d.name,
                      value: d.name === "Applicant Type" ? `${attributes.workCompanyOptions}`: d.name === "Application Date" ? moment(date).format('YYYY-MM-DD') :d.value,
                    })),
                valuations: [],
                productRiskReference: riskAttributes?.[0]?.instanceId,
                price: 0.0,
                tax: 0.0,
                discount: 0.0,
                total: 0.0,
            },
          ],
          bankAccounts: null,
          // productOptionReference:"b7d525e6-473d-4929-ba04-17d3e135dea9",
          productOptionReference:CONSTANTS.COMMERCIAL_PRODUCT_OPTION_REF,
          // productOptionReference: commercialStep ? `${CONSTANTS.COMMERCIAL_PRODUCT_INSTANCE_ID}` : `${CONSTANTS.PRODUCT_INSTANCE_ID}`,
          // status: 15,
          status: 17,
        },
      ],
      networkId: agentOnBoarding?.network?.instanceId,
      companyId: agentOnBoarding?.company?.code,
      agentId: agentOnBoarding?.agent?.code,
    };

    // return;
    try {
      // Dispatching the values and call the API for Create policy.
      const res = await dispatch(CreatePolicy(policyParams));
      setIsLoading(false);
      handleNext(res); 
    } catch (err) {
      console.log(err);
    }
  };
  

  return (
    <div>
      {/* <---------- Title -------> */}
      {/* <h4>{product?.name}</h4> */}
      <h6>Commercial Drone Cover</h6>
      {/* <------- Sub title ------> */}
      <h6>Add your details</h6>

      <Divider row={2} />

      {/* <------- Attributes Starts ------> */}
      <Row className="mt-4">
        <Col sm={6}>
          <SelectWithRadioHorizontal
            onChange={handleChange}
            onExtraChange={handleChange}
            required={false}
            name="workCompanyOptions"
            options={companyOrSoleProprietor?.settings ? JSON.parse(companyOrSoleProprietor?.settings)?.items : []}
            // options={[]}
            value={attributes.workCompanyOptions}
            label="Company or Sole Proprietor"
            // ////tabIndex = {1}
            extras={
              attributes.workCompanyOptions === "Company"
                ? [
                    {
                      type: "text",
                      label: "- Company name",
                      name: "workCompany",
                      id: "workCompany",
                      value: attributes["workCompany"],
                      errors: errors.workCompany,
                      //tabIndex:"1",
                    },
                    {
                      type: "text",
                      label: "- Company Reg No, if registered",
                      name: "companyRegNo",
                      id: "companyRegNo",
                      value: attributes["companyRegNo"],
                      errors: errors.companyRegNo,
                      ////tabIndex:"2"
                    },
                    {
                      type: "text",
                      label: "- VAT No, if registered",
                      name: "vatNumber",
                      id: "vatNumber",
                      value: attributes["vatNumber"],
                      errors: errors.vatNumber,
                      ////tabIndex:"3"
                    },
                  ]
                : [
                    {
                      type: "text",
                      label: "- VAT Number, if registered",
                      name: "vatNumber",
                      id: "vatNumber",
                      value: attributes["vatNumber"],
                      ////tabIndex:"1"
                    },
                    {
                      type: "text",
                      label: "- ID No",
                      name: "idNumber",
                      id: "idNumber",
                      errors: errors.idNumber ?  [errors.idNumber[0]]: null,
                      value: attributes.idNumber,
                      ////tabIndex:"2"
                    },
                  ]
            }
          />
        </Col>
      </Row>

      <VerticalSpacer rows={2} />

      <Row>
       

        <Col sm={3}>
           <div className="form-group mt-4 mb-4">
            <label className="form-label">
              Title
            </label>
            <select
              style={{
                height: "38px",
              }}
              className={`form-select ${!attributes.title && 'select-empty'} ${errors.title?.length && "error-input"}`}
              value={attributes.title}
              required={true}
              onChange={handleChange}
              placeholder="Title"
              name="title"
              ////tabIndex="4"
            >
               <option hidden selected>Title</option>
              {
                titles?.map((items, index) => (
                  <option style={{color:"black"}} value={items} key={index}>
                    {items}
                  </option>
                ))
              }
            </select>
            {errors?.title && (
              <span className="errors">
                {errors?.title?.map((error) => error).join(", ")}
              </span>
            )}
          </div>
          <TextInput
            label="First name"
            placeholder="Contact person"
            name="firstName"
            errors={errors.firstName}
            onChange={handleChange}
            required={true}
            value={attributes.firstName}
            ////tabIndex="5"
          />
        </Col>

        <Col sm={3}>
          <TextInput
            label="Last name"
            placeholder="Contact person"
            name="lastName"
            errors={errors.lastName}
            onChange={handleChange}
            required={true}
            value={attributes.lastName}
            ////tabIndex="6"
          />

          <TextInput
            label="ID Number"
            placeholder="ID Number"
            name="idNumber"
            errors={errors.idNumber}
            onChange={handleChange}
            required={true}
            value={attributes.idNumber}
            ////tabIndex="7"
          />
        
        </Col>
        <Col sm={3}>     
          <TextInput
            label="Mobile number"
            placeholder="Contact person mobile"
            name="mobilePhone"
            errors={errors.mobilePhone}
            onChange={handleChange}
            required={true}
            value={attributes.mobilePhone}
            ////tabIndex="8"
          />
        </Col>

        <Col sm={3}>
        <TextInput
            label="Email"
            placeholder="Contact person email"
            name="eMail"
            errors={errors.eMail}
            onChange={handleChange}
            required={true}
            value={attributes.eMail}
            ////tabIndex="9"
          />
        </Col>
      </Row>

      
      <Row className="mt-5">
        <Col xs={6} md={2} className="d-flex justify-content-start">
          <Link to="/" style={{textDecoration: 'none'}}>
          <ButtonWithDownArrow
            // onClick={handleBack}
            type="left"
            name="Back"
            bg="#dd302a"
            color="white"
          /></Link>
        </Col>
        {width > 430 && <Col xs={0} md={8} />}
        <Col xs={6} md={2} className="d-flex justify-content-end">
          <ButtonWithDownArrow
            onClick={handleSubmit}
            type="right"
            name="Next"
            bg="#dd302a"
            color="white"
          />
        </Col>
      </Row>

      {/* <------- Attributes Starts ------> */}

      {isLoading && (
        <div className="gif-loader-fullscreen">
          <img src={GIFLoader} alt="GIFLoader"></img>
        </div>
      )}
    </div>
  );
};

export default Step4;

const companySchema = {
  title: {
    presence: { allowEmpty: false },
  },
  country: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z ]+",
      flags: "i",
      message: "^ Country can only contain alphabets",
    },
  },
  firstName: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z ]+",
      flags: "i",
      message: "^ Firstname can only contain alphabets",
    },
  },
  idNumber: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/,
      message: "^ ID number can only contain alphabets",
    },
  },
  eMail: {
    presence: { allowEmpty: false },
    email: true,
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z ]+",
      flags: "i",
      message: "^ Lastname can only contain alphabets",
    },
  },
  mobilePhone: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/,
      message: "^Mobile phone can only contain number",
    },
  },
};

const titles = [
  'Adv',
  'Dr',
  'Miss',
  'Mr',
  'Mrs',
  'Ms',
  'Prof',
  'Rev',
  'Sir',
  'NONE'
]

//Commercial Ends--///
