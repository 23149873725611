// default imports for react project
import React from "react";

// default import for routing in react
import { useHistory } from "react-router";

// import classname to combine module css and normal css
import classNames from "classnames";

// import stylesheet
import Style from "./Heading.module.css";

// custom component for heading text h1 and primary color
export const H1Red = (props) => {
  return (
    <span {...props} className={classNames(Style.h1Red)}>
      {props.children}
    </span>
  );
};

// custom component for heading text h2 and primary color
export const H2Red = (props) => {
  return (
    <span {...props} className={classNames(Style.h2Red)}>
      {props.children}
    </span>
  );
};

// custom component for heading text h3 and primary color
export const H3Red = (props) => {
  return (
    <span {...props} className={classNames(Style.h3Red)}>
      {props.children}
    </span>
  );
};

// custom component for heading text h4 and primary color
export const H4Red = (props) => {
  return (
    <span {...props} className={classNames(Style.h4Red)}>
      {props.children}
    </span>
  );
};

// custom component for heading text h5 and primary color
export const H5Red = (props) => {
  return (
    <span {...props} className={classNames(Style.h5Red)}>
      {props.children}
    </span>
  );
};

// custom component for heading text h6 and primary color
export const H6Red = (props) => {
  return (
    <span {...props} className={classNames(Style.h6Red)}>
      {props.children}
    </span>
  );
};

// custom component for heading text h1 and secondary color
export const H1Grey = (props) => {
  return (
    <span {...props} className={classNames(Style.h1Grey)}>
      {props.children}
    </span>
  );
};

// custom component for heading text h1 and secondary color
export const H2Grey = (props) => {
  return (
    <span {...props} className={classNames(Style.h2Grey)}>
      {props.children}
    </span>
  );
};

// custom component for heading text h1 and secondary color
export const H3Grey = (props) => {
  return (
    <span {...props} className={classNames(Style.h3Grey)}>
      {props.children}
    </span>
  );
};

// custom component for heading text h1 and secondary color
export const H4Grey = (props) => {
  return (
    <span {...props} className={classNames(Style.h4Grey)}>
      {props.children}
    </span>
  );
};

// custom component for heading text h1 and secondary color
export const H5Grey = (props) => {
  return (
    <span {...props} className={classNames(Style.h5Grey)}>
      {props.children}
    </span>
  );
};

// custom component for heading text h1 and secondary color
export const H6Grey = (props) => {
  return (
    <span {...props} className={classNames(Style.h6Grey)}>
      {props.children}
    </span>
  );
};

// custom component for see more text
export const BlackArrayText = (props) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(props.link);
  };
  return (
    <span
      {...props}
      className={classNames(Style.blackArrText)}
      onClick={handleClick}
    >
      {props.children}
    </span>
  );
};

// custom component for href element
export const Href = (props) => {
  // initializing history component used to redirect
  const history = useHistory();

  // function to handle redirection
  const handleFooterRedirect = () => {
    history.push(props.link);
  };

  // return jsx
  return (
    <div
      {...props}
      className={classNames(Style.href)}
      onClick={handleFooterRedirect}
    >
      {props.children}
    </div>
  );
};



// custom component for href element
export const RedirectToExternal = (props) => {
  return (
    <a
      {...props}
      className={classNames(Style.href)}
    >
      {props.children}
    </a>
  );
};
