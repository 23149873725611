import classNames from "classnames";
import { Divider } from "components";
import React from "react";
import { Col, Row } from "react-bootstrap";


// import stylesheet
import style from "./ProductCard.module.css";
import Button from "../../components/Buttons/Button";
import ApplicatonForm from "../../assets/documents/applicationForm.pdf";
import useWindowDimensions from 'utils/useWindowDimension';


const SummaryCommercial = ({
  containerStyle,
  title,
  titleCommercial,
  policyNumber,
  onClick,
  policyHolder,
  premiumPaid,
  policyStartDate,
  type,
  policyRenewalDate,
  liability,
  theoryCourse,
  practicalCourse,
  policyEndDate,
  proratePremium,
  monthlyProratePremium,
  prorataPremiumFirstMonth,
  premiumPaidMonthly,
  selectedProduct,
  monthlyPremium,
  prorataPremium,
  policyResponse,
  companyDetails,
  debitDate,
}) => {
  const { width } = useWindowDimensions();

  return (
    <div
      className={classNames(style.container)}
      style={containerStyle && containerStyle}
    >
      <h3 className={style.titleCommercial}>Commercial Drone Cover</h3>
      <div
        className={classNames(
          "d-flex justify-content-between",
          style.policyPrimaryContainer
        )}
      >
        <h6 className={style.policyPrimary}>Reference Number: </h6>
        <h6 className={style.policyPrimary}>{policyNumber}</h6>
      </div>
    
      <Row>
                <div className="d-flex justify-content-center align-items-center w-100 text-left mt-4">
                <span style={{color:"#5A727A",fontWeight:"700"}}>{`Download Commercial Drone application form. Once completed please email to info@dronecovered.com`},</span>
                </div>
        </Row>

        <Row>
                <div className="d-flex justify-content-center align-items-center w-100 text-left mt-4 mb-5">
                <a style={{ color: '#353f4e',textDecoration: 'none' }} rel="noreferrer" href={ApplicatonForm} target="_blank"> 
                <Button
                // type="submit"
                // isLoading={isLoading}
                containerStyle={{width: 240}}
                onClick={onClick}
                name="Download application form"
                bg="#5A727A"
                color="white"
             /></a>
                </div>
        </Row>

        
      <Divider />

      <div className={style.cover}>Contact Details:-</div>
      <ul className="mt-2">
        <li className={style.description_1}>
          +27 87 550 9796 | info@dronecovered.com
        </li>
      </ul>
    </div>
  );
};

export default SummaryCommercial;
