import CONSTANTS from "utils/constants";
import axios from "axios";
import { store } from "store/store";
import { PRODUCTION_CONSTANTS } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";

//********************************* NOTES **********************************//
// 1. Please refer the TYPE from the according reducers.                    //
// 2. All the actions will be exporting to the components.                  //
// 3. Each action may call API Calls and returning the response to store.   //
//********************************* NOTES **********************************//

// Headers
const config = {
  headers: {
    "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY
  },
};

export const login = (loginStep) => async (dispatch) => {
  try {
    // Calling the login API
    const response = await axios.get(`${CONSTANTS.MIDDLEWARE_URL}/auth/login/${loginStep}`,config);
    // Dispatching ----> data to the store
    return dispatch({ type: "LOGIN", payload: response.data });
  } catch (e) {
    throw e;
  }
};

export const getCompanyDetails = () => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.jwtToken;

    const response = await axios.get(
      `${CONSTANTS.MIDDLEWARE_URL}/company/getCompanyDetails/${CONSTANTS.COMPANY_ID}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );

    return dispatch({
      type: "STORE_COMPANY_DETAILS",
      payload: response.data.data
    });
  } catch (err) {}
};

export const getAgentDetails = (agent_code) => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.jwtToken;

    dispatch({
      type: "LOADING",
      payload: true,
    });

    const response = await axios.get(
      `${CONSTANTS.MIDDLEWARE_URL}/auth/getRefererAgentData/${agent_code}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );

    const {
      partnerCode,
      brokerName,
      brokerCode,
      brokerEmail,
      agentCode,
      agentFirstName,
      agentLastName,
      agentEMail,
    } = response.data.response;

    const response1 = await axios.post(
      `${CONSTANTS.MIDDLEWARE_URL}/auth/agentOnboarding`,
      {
        headers: {
          subscriptionKey: CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
        body: {
          companyCode: partnerCode,
          brokerName,
          brokerCode,
          brokerEmail,
          agentCode,
          agentFirstName,
          agentLastName,
          agentEMail,
        },
      }
    );


    dispatch({
      type: 'AGENT_ONBOARDING',
      payload: response1.data.response
    });

    const response2 = await axios.get(
      `${CONSTANTS.MIDDLEWARE_URL}/company/getCompanyDetails/${response1.data.response.company.instanceId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );

    dispatch({
      type: "LOADING",
      payload: false,
    });

    return dispatch({
      type: "STORE_COMPANY_DETAILS",
      payload: response2.data.data,
      agentCode: agent_code,
    });
  } catch (err) {
    console.log(err);
  }
};


export const getAgentOnboarding = (agent_code,loginStep) => async (dispatch) => {
  try {
    const res = await axios.get(`${CONSTANTS.MIDDLEWARE_URL}/auth/login/${loginStep}`,config);

    const token = res.data.jwtToken;

    const response = await axios.get(
      `${CONSTANTS.MIDDLEWARE_URL}/auth/getRefererAgentData/${agent_code}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );

    const {
      partnerCode,
      brokerName,
      brokerCode,
      brokerEmail,
      agentCode,
      agentFirstName,
      agentLastName,
      agentEMail,
    } = response.data.response;

    const response1 = await axios.post(
      `${CONSTANTS.MIDDLEWARE_URL}/auth/agentOnboarding`,
      {
        headers: {
          subscriptionKey: CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
        body: {
          companyCode: partnerCode,
          brokerName,
          brokerCode,
          brokerEmail,
          agentCode,
          agentFirstName,
          agentLastName,
          agentEMail,
        },
      }
    );


    dispatch({
      type: 'AGENT_ONBOARDING',
      payload: response1.data.response
    });

    const response2 = await axios.get(
      `${CONSTANTS.MIDDLEWARE_URL}/company/getCompanyDetails/${response1.data.response.company.instanceId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
    
    return dispatch({
      type: "STORE_COMPANY_DETAILS",
      payload: response2.data.data,
      agentCode: agent_code,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateLoading = (payload) => (dispatch) => {
  dispatch({
    type: "LOADING",
    payload,
  });
};

