// default import to combine all separate reducers
import { combineReducers } from "redux";

// import custom reducers
import auth from "./auth";
import products from "./products";
import prevState from "./prevState";
import common from "./common";

// import persistor to persist the data in local storage
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

// configuration to store the values in localstorage
const prevPersistConfig = {
  key: "prevState",
  storage: storage,
  blacklist: [
    "companyDetails",
    "selectedProduct",
    "policyResponse",
    "riskAttributes",
  ],
};

const prodPersistConfig = {
  key: "products",
  storage: storage,
  blacklist: [
    "companyDetails",
    "selectedProduct",
    "policyResponse",
    "riskAttributes",
  ],
};

// combining all reducers together
const reducer = combineReducers({
  products: persistReducer(prodPersistConfig, products),
  auth,
  prevState: persistReducer(prevPersistConfig, prevState),
  common,
});

export default reducer;
