// default imports for react project
import React from "react";

// default import for routing in react
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import default styles from react-bootstrap package
import { Container } from "react-bootstrap";

// import function to add breadcrumb from actions file
import { addCrumb } from "store/actions/common";

// Import icon from assets folder
import { DoubleArrowRoundedIcon } from "assets/icons";

// React functional component starts here..
function BreadCrumb() {
  // getting breadcrumb data from store
  const breadcrumbs = useSelector((state) => state.common.breadcrumbs);

  // initialized dispatch using react hook
  const dispatch = useDispatch();

  // react layout hook to perform init operations
  const updateCrumb = (index) => {
    breadcrumbs.splice(index);
    // dispatching an action to update breadcrumb
    dispatch(addCrumb(breadcrumbs));
  };

  // return jsx to display on screen
  return (
    <Container className="breadcrumbs">
      <Link to="/">Home </Link>
      {breadcrumbs?.map(({ link, name }, i) => (
        <Link key={i} to={link} onClick={() => updateCrumb(i)}>
          <DoubleArrowRoundedIcon /> {name}
        </Link>
      ))}
    </Container>
  );
}

export default BreadCrumb;
