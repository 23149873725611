import ButtonWithDownArrow from "components/Buttons/ButtonWithArrow";
import React, { useState } from "react";

// <--------- React Bootstrap -------->
import { Col, Row } from "react-bootstrap";

// <---------- Redux Hooks ----------->
import { useDispatch, useSelector } from "react-redux";

// <---------- Actions ----------->
import { updateCompanyDetails, CreatePolicy } from "store/actions/products";

// <---------- Global Variable ----------->
import CONSTANTS from "utils/constants";

import { updateValues } from "store/actions/products";

import useWindowDimensions from "utils/useWindowDimension";
import validate from "validate.js";
import GIFLoader from "../../../assets/images/loader.gif";

// <--------- Essential Components -------->
import {
  SelectWithRadioHorizontal,
  Divider,
  Select,
  VerticalSpacer,
  TextInput,
} from "../../../components";
import { H4Red } from "components/Heading";
import { useEffect } from "react";
import moment from "moment"; 

const Step4 = ({ data, handleNext, handleBack }) => {
  // <--------- Company details from store ---------->
  const companyDetails = useSelector((state) => state.products.companyDetails);

  // <--------- Assigning company details to the local state --->
  const [attributes, setAttributes] = useState({
    ...companyDetails,
  });

  const [drd, setDrd] = useState(data);

  const [submitClicked, setSubmitClicked] = useState(false);


  // <--------- product from the store --->
  const product = useSelector(
    (state) => state.products?.productOptions?.product
  );
  const practicalCourse = useSelector(state => state?.products?.riskAttributes?.find((x)=> x.code === 'DIR')?.attributes?.find((y)=>y.name === 'Drone Proficiency Practical Course'))
  const theoryCourse = useSelector(state => state?.products?.riskAttributes?.find((x)=> x.code === 'DIR')?.attributes?.find((y)=>y.name === 'Drone Proficiency Theory Course'))

  const [isLoading, setIsLoading] = useState(false);
  const banks = useSelector((state) => state.products.bankDetails);

  useEffect(() => {
  if(attributes){  setAttributes({
    ...attributes,
    country: 'South Africa',
    YearofManufacture: companyDetails?.YearofManufacture ? companyDetails?.YearofManufacture : '2022'
  });}
    if(drd){
      setDrd({
        ...drd,
        attributes: drd.attributes.map((x) => ({ ...x, value: x.name === 'Year of Manufacture' ? "2022" : (x.value || null) }))
      })
    }
  },[])

  // <---------  Synthetic events --->
  const handleChange = async (evt) => {
    const obj = {
      ...attributes,
      [evt.target.name.replaceAll(" ", "")]: evt.target.type === 'checkbox' ? evt.target.checked :  evt.target.value,
    };
    setAttributes(obj);

    if (submitClicked) {
      let constraints = getConstraints(obj);

      if (obj.workCompanyOptions !== "Company") {
        delete constraints.companyRegNo;
        delete constraints.vatNumber;
        delete constraints.workCompany;
      }

      if(practicalCourse?.value === 'NO' && theoryCourse?.value === 'NO'){
        delete constraints['Proficiencycoursecertificatenumber']
      }
      // <---- Consolidating Errors values ---->
      const errors = validate(obj, constraints);
      if (errors) {
        setErrors(errors);
        return;
      } else {
        setErrors({});
      }
    }
  };

  // <---------  Synthetic events --->
  const handleChangeDetails = (evt) => {
    handleChange(evt);
    const obj = {
      ...drd,
      attributes: drd.attributes.map((attr) =>
        attr.name === evt.target.name
          ? { ...attr, value: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value }
          : attr
      ),
    };
    setDrd(obj);
  };
  // Redux Hooks
  const dispatch = useDispatch();

  // Risk attributes from the Product
  const riskAttributes = useSelector((state) => state.products.riskAttributes.sort((a, b) => (a.sequence > b.sequence ? 1 : -1)));

  const products = useSelector((state) => state.products);

  const auth = useSelector((state) => state.auth);

  const { width } = useWindowDimensions();

  const [errors, setErrors] = useState({});

  // External reference
  const uniqueId = () => parseInt(Date.now() * Math.random()).toString();

  const getConstraints = (attributes) => {
    // constraints for the form values
    let constraints = {
      ...companySchema,
      companyRegNo: {
        presence: {
          allowEmpty:
            attributes.workCompanyOptions === "Company" ? true : true,
        },
      },
      vatNumber: {
        presence: {
          allowEmpty:
            attributes.workCompanyOptions === "Company" ? true : true,
        },
      },
    
      idNumber: {
        presence: {
          allowEmpty:
            attributes.workCompanyOptions === "Company" ? false : false,
        },
        format: {
          pattern: /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/,
          message: "^ Invalid South African ID Number",
        },
      },
    };

    drd?.attributes?.map((d) => {
      constraints = {
        ...constraints,
        [d.name.replaceAll(" ", "")]: {
          presence: {
            allowEmpty: false,
          },
        },
      };
      return null;
    });
    return constraints;
  };
 
  const getProrate = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => { const val = a.split(":"); obj[val[0]] = val[1]; })
    return obj;
  }

  const paymentFrequency = useSelector(state => state.products.paymentFrequency);

  const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);


  // Submitting the form
  const handleSubmit = async () => {
    setSubmitClicked(true);
    // Updating the company details values to the store.
    dispatch(updateValues(drd));
    dispatch(updateCompanyDetails(attributes));

    const constraints = getConstraints(attributes);
    delete constraints.companyRegNo;
    delete constraints.vatNumber;
    delete constraints.workCompany;
    if(practicalCourse?.value === 'NO' && theoryCourse?.value === 'NO'){
      delete constraints['Proficiencycoursecertificatenumber']
    }


    // <---- Consolidating Errors values ---->
    const errors = validate(attributes, constraints);
    if (errors) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    const riskIndex = riskAttributes.findIndex((risk) => risk.code === "DRD");
    riskAttributes[riskIndex] = drd;

    setIsLoading(true);
    const policyParams = {
      policies: [
        {
          PolicyStatus: "Quoted",
          currencyCode: "ZAR",
          members: [
            {
              ...attributes,
              customerIdentityType: 0,
              nationalityId: attributes.idNumber,
              companyName: `${attributes.title} ${attributes.firstName} ${attributes.lastName}`,
              pin: attributes.postalStateCode
            },
          ],
          beneficiaries: [
            {
              ...attributes,
              customerIdentityType: 0,
              nationalityId: attributes.idNumber,
              companyName: `${attributes.title} ${attributes.firstName} ${attributes.lastName}`,
              pin: attributes.postalStateCode
            },
          ],
          attributes: [],
          risks: [
            {
              externalReference: uniqueId(),
              attributes: riskAttributes?.find((x) => x.code === 'DIR').attributes.map((d) => ({
                      name: d.name,
                      value: d.name === 'Payment Frequency' ? paymentFrequency :  d.value,
                    })),
              valuations: products.selectedProduct.valuations.values,
              productRiskReference: riskAttributes[1]?.instanceId,
              price: products.selectedProduct?.rating.price,
              tax: products.selectedProduct?.rating.tax,
              discount: products.selectedProduct?.rating.discount,
              total: products.selectedProduct?.rating.total
            },
            {
              externalReference: uniqueId(),
              attributes: riskAttributes?.find((x) => x.code === 'DRD').attributes.map((d) => ({
                      name: d.name,
                      value: d.value,
                    })),
                valuations: [],
                productRiskReference: riskAttributes[2]?.instanceId,
                price: 0.0,
                tax: 0.0,
                discount: 0.0,
                total: 0.0,
            },
            {
              externalReference: uniqueId(),
              attributes: [
                {
                  name: 'Payment Frequency',
                  value: paymentFrequency
                },
                {
                  name: 'Annual Premium',
                  value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).annualPremium
                },
                {
                  name: 'Pro-Rata Annual Premium',
                  value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).prorataAnnualPremium
                },
                {
                  name: 'Monthly premium',
                  value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).monthlyPremium
                },
                {
                  name: 'Pro-Rata Premium',
                  value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).prorataPremium
                },
                {
                  name: 'Excess Structure',
                  value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).excess
                },
                {
                  name: 'First Collection Date',
                  value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).firstCollectionDate
                },
                {
                  name: '1st Collection Amount',
                  value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).firstCollectionAmount
                },
                {
                  name: 'Policy Start Date',
                  value: moment(getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message)?.policyStartDate).format("DD/MM/YYYY")
                },
                {
                  name: 'Policy End Date',
                  value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message)?.policyEndDate
                },
                {
                  name: 'Policy Renewal Date',
                  value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message)?.policyRenewalDate
                }
              ],
              valuations: [],
              productRiskReference: riskAttributes[0]?.instanceId ,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            }
          ],
          bankAccounts: null,
          productOptionReference:
          products.selectedProduct.productOptionReference,
          status: 15,
        },
      ],
      networkId: agentOnBoarding?.network?.instanceId,
      companyId: agentOnBoarding?.company?.code,
      agentId: agentOnBoarding?.agent?.code,
    };

    // return;
    try {
      // Dispatching the values and call the API for Create policy.
      const res = await dispatch(CreatePolicy(policyParams));
      setIsLoading(false);
      handleNext(res);
    } catch (err) {
      console.log(err);
    }
  };
  const renderComponents = (data, index) => {
    switch (data.dataType) {
      case 1:
        return (
          <Col key={index} sm={4}>
            <TextInput
              label={`${data.name} of the Drone`}
              placeholder={`- ${data.description}`}
              name={data.name}
              errors={errors[data.name.replaceAll(" ", "")]}
              onChange={handleChangeDetails}
              required={true}
              value={data.value ?? attributes[data.name.replaceAll(" ", "")]}
            />
          </Col>
        );
      case 2:
        return (
          <Col key={index} sm={4}>
            <Select
              label={`${data.name} of the Drone`}
              placeholder={`- ${data.description}`}
              droneTheory="YES"
              options={Array(new Date().getFullYear() + 1 - 1990)
                .fill()
                .map((_, i) => 1990 + i)}
              name={data.name}
              errors={errors[data.name.replaceAll(" ", "")]}
              onChange={handleChangeDetails}
              required={true}
              
              value={data.value ?? attributes[data.name.replaceAll(" ", "")]}
            />
          </Col>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {/* <---------- Title -------> */}
      {/* <h4>{product?.name}</h4> */}
      <h6>Drone Covered</h6>

      {/* <------- Sub title ------> */}
      <h6>Add your details</h6>

      <Divider row={2} />

      {/* <------- Attributes Starts ------> */}
    

      <VerticalSpacer rows={2} />

      <Row>
       

        <Col sm={3}>
           <div className="form-group mt-4 mb-4">
            <label className="form-label">
              Title
            </label>
            <select
              style={{
                height: "38px",
              }}
              className={`form-select ${!attributes.title && 'select-empty'} ${errors.title?.length && "error-input"}`}
              value={attributes.title}
              required={true}
              onChange={handleChange}
              placeholder="Title"
              name="title"
            >
               <option hidden selected>Title</option>
              {
                titles?.map((items, index) => (
                  <option style={{color:"black"}} value={items} key={index}>
                    {items}
                  </option>
                ))
              }
            </select>
            {errors?.title && (
              <span className="errors">
                {errors?.title?.map((error) => error).join(", ")}
              </span>
            )}
          </div>

          <TextInput
            label="First name"
            placeholder="Contact person"
            name="firstName"
            errors={errors.firstName}
            onChange={handleChange}
            required={true}
            value={attributes.firstName}
          />

          <TextInput
            label="Last name"
            placeholder="Contact person"
            name="lastName"
            errors={errors.lastName}
            onChange={handleChange}
            required={true}
            value={attributes.lastName}
          />
        </Col>

        <Col sm={3}>
          <TextInput
            label="Email"
            placeholder="Contact person email"
            name="eMail"
            errors={errors.eMail}
            onChange={handleChange}
            required={true}
            value={attributes.eMail}
          />

          <TextInput
            label="Mobile number"
            placeholder="Contact person mobile"
            name="mobilePhone"
            errors={errors.mobilePhone}
            onChange={handleChange}
            required={true}
            value={attributes.mobilePhone}
          />

          <TextInput
            label="ID Number"
            placeholder="ID Number"
            name="idNumber"
            errors={errors.idNumber}
            onChange={handleChange}
            required={true}
            value={attributes.idNumber}
          />
        </Col>
        <Col sm={3}>
          <TextInput
            label="Address"
            name="workAddress"
            placeholder="Add business address"
            onChange={handleChange}
            errors={errors.workAddress}
            required={true}
            value={attributes.workAddress}
          />

          <TextInput
            label="Suburb"
            placeholder="Suburb"
            name="suburb"
            errors={errors.suburb}
            onChange={handleChange}
            required={true}
            value={attributes.suburb}
          />

          <TextInput
            label="City"
            placeholder="Add city"
            name="workCity"
            onChange={handleChange}
            errors={errors.workCity}
            required={true}
            value={attributes.workCity}
          />
        </Col>

        <Col sm={3}>
          <TextInput
            label="Province"
            placeholder="Add province"
            name="workProvince"
            errors={errors.workProvince}
            onChange={handleChange}
            required={true}
            value={attributes.workProvince}
          />

          <TextInput
            label="Postal code"
            placeholder="Add postal code"
            name="postalStateCode"
            errors={errors.postalStateCode}
            onChange={handleChange}
            required={true}
            value={attributes.postalStateCode}
          />

          <Select
            label="Country"
            placeholder="Add country"
            options={["South Africa"]}
            name="country"
            errors={errors.country}
            onChange={handleChange}
            required={true}
            value={attributes.country}
          />
        </Col>
      </Row>

      <Row>
        <Row>
          <H4Red>Drone/Qualification Details*</H4Red>
        </Row>
        {/* <Col lg={6}>
          <div className="d-flex flex-row align-items-start mt-3 ">
            <div style={{ marginLeft: "10px" }}>
             <FormGroup>
             <FormControlLabel
                className="ml-5"
                value={attributes[confirmatiomStatement.name.replaceAll(" ", "")]}
                name={confirmatiomStatement.name}
                onClick={handleChangeDetails}
                control={
                  <Checkbox
                  disabled={false}
                  defaultChecked={attributes[confirmatiomStatement.name.replaceAll(" ", "")]}
                    sx={{
                      "&.Mui-checked": {
                        color: "red",
                        fontSize: 14,
                      },
                    }}
                   
                  />
                }
                label={""}
              />
             </FormGroup>
            </div>
            <div>
              <b> {confirmatiomStatement.name}</b>
              <ul className="dashed">
                {confirmatiomStatement.description
                  .split(".")
                  .filter((f, i) => i < 4)
                  .map((li) => (
                    <li>{li}.</li>
                  ))}
              </ul>
            </div>
          </div>
        </Col> */}
        <Col lg={12}>
          <Row>
            {drd && drd?.attributes?.map((d, i) => renderComponents(d, i))}
          </Row>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col xs={6} md={2} className="d-flex justify-content-start">
          <ButtonWithDownArrow
            onClick={handleBack}
            type="left"
            name="Back"
            bg="#dd302a"
            color="white"
          />
        </Col>
        {width > 430 && <Col xs={0} md={8} />}
        <Col xs={6} md={2} className="d-flex justify-content-end">
          <ButtonWithDownArrow
            onClick={handleSubmit}
            type="right"
            name="Next"
            bg="#dd302a"
            color="white"
          />
        </Col>
      </Row>

      {/* <------- Attributes Starts ------> */}

      {isLoading && (
        <div className="gif-loader-fullscreen">
          <img src={GIFLoader} alt="GIFLoader"></img>
        </div>
      )}
    </div>
  );
};

export default Step4;

const companySchema = {
  workAddress: {
    presence: { allowEmpty: false },
  },
  workCity: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z ]+",
      flags: "i",
      message: "^ City can only contain alphabets",
    },
  },
  workCompany: {
    presence: { allowEmpty: false },
  },
  postalStateCode: {
    presence: { allowEmpty: false },
    format: {
      pattern: "^[0-9]+$",
      message: "^ Postal code can only contain number",
    },
  },
  suburb: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z ]+",
      flags: "i",
      message: "^ Suburb can only contain alphabets",
    },
  },
  workProvince: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z ]+",
      flags: "i",
      message: "^ Province can only contain alphabets",
    },
  },

  title: {
    presence: { allowEmpty: false },
  },
  country: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z ]+",
      flags: "i",
      message: "^ Country can only contain alphabets",
    },
  },
  firstName: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z ]+",
      flags: "i",
      message: "^ Firstname can only contain alphabets",
    },
  },
  idNumber: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/,
      message: "^ ID number can only contain alphabets",
    },
  },
  eMail: {
    presence: { allowEmpty: false },
    email: true,
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z ]+",
      flags: "i",
      message: "^ Lastname can only contain alphabets",
    },
  },
  mobilePhone: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/,
      // pattern:/^(\+\d{1,3}[- ]?)?\d{9,12}$/,
      message: "^Mobile phone can only contain number",
    },
  },
};

const titles = [
  'Adv',
  'Dr',
  'Miss',
  'Mr',
  'Mrs',
  'Ms',
  'Prof',
  'Rev',
  'Sir',
  'NONE'
]



////////////-------Commercial------///////////
// import ButtonWithDownArrow from "components/Buttons/ButtonWithArrow";
// import React, { useState } from "react";

// // <--------- React Bootstrap -------->
// import { Col, Row } from "react-bootstrap";

// // <---------- Redux Hooks ----------->
// import { useDispatch, useSelector } from "react-redux";

// // <---------- Actions ----------->
// import { updateCompanyDetails, CreatePolicy } from "store/actions/products";

// // <---------- Global Variable ----------->
// import CONSTANTS from "utils/constants";

// import { updateValues } from "store/actions/products";

// import useWindowDimensions from "utils/useWindowDimension";
// import validate from "validate.js";
// import GIFLoader from "../../../assets/images/loader.gif";

// // <--------- Essential Components -------->
// import {
//   SelectWithRadioHorizontal,
//   Divider,
//   Select,
//   VerticalSpacer,
//   TextInput,
// } from "../../../components";
// import { H4Red } from "components/Heading";
// import { useEffect } from "react";
// import moment from "moment"; 

// const Step4 = ({ data, handleNext, handleBack }) => {
//   // <--------- Company details from store ---------->
//   const companyDetails = useSelector((state) => state.products.companyDetails);

//   // <--------- Assigning company details to the local state --->
//   const [attributes, setAttributes] = useState({
//     ...companyDetails,
//   });

//   const [drd, setDrd] = useState(data);

//   const [submitClicked, setSubmitClicked] = useState(false);


//   // <--------- product from the store --->
//   const product = useSelector(
//     (state) => state.products?.productOptions?.product
//   );
//   const practicalCourse = useSelector(state => state?.products?.riskAttributes?.find((x)=> x.code === 'DIR')?.attributes?.find((y)=>y.name === 'Drone Proficiency Practical Course'))
//   const theoryCourse = useSelector(state => state?.products?.riskAttributes?.find((x)=> x.code === 'DIR')?.attributes?.find((y)=>y.name === 'Drone Proficiency Theory Course'))

//   const [isLoading, setIsLoading] = useState(false);
//   const banks = useSelector((state) => state.products.bankDetails);

//   useEffect(() => {
//   if(attributes){  setAttributes({
//     ...attributes,
//     country: 'South Africa',
//     YearofManufacture: companyDetails?.YearofManufacture ? companyDetails?.YearofManufacture : '2022'
//   });}
//     if(drd){
//       setDrd({
//         ...drd,
//         attributes: drd.attributes.map((x) => ({ ...x, value: x.name === 'Year of Manufacture' ? "2022" : (x.value || null) }))
//       })
//     }
//   },[])

//   // <---------  Synthetic events --->
//   const handleChange = async (evt) => {
//     const obj = {
//       ...attributes,
//       [evt.target.name.replaceAll(" ", "")]: evt.target.type === 'checkbox' ? evt.target.checked :  evt.target.value,
//     };
//     setAttributes(obj);

//     if (submitClicked) {
//       let constraints = getConstraints(obj);

//       if (obj.workCompanyOptions !== "Company") {
//         delete constraints.companyRegNo;
//         delete constraints.vatNumber;
//         delete constraints.workCompany;
//       }

//       if(practicalCourse?.value === 'NO' && theoryCourse?.value === 'NO'){
//         delete constraints['Proficiencycoursecertificatenumber']
//       }
//       // <---- Consolidating Errors values ---->
//       const errors = validate(obj, constraints);
//       if (errors) {
//         setErrors(errors);
//         return;
//       } else {
//         setErrors({});
//       }
//     }
//   };

//   // <---------  Synthetic events --->
//   const handleChangeDetails = (evt) => {
//     handleChange(evt);
//     const obj = {
//       ...drd,
//       attributes: drd.attributes.map((attr) =>
//         attr.name === evt.target.name
//           ? { ...attr, value: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value }
//           : attr
//       ),
//     };
//     setDrd(obj);
//   };
//   // Redux Hooks
//   const dispatch = useDispatch();

//   // Risk attributes from the Product
//   const riskAttributes = useSelector((state) => state.products.riskAttributes.sort((a, b) => (a.sequence > b.sequence ? 1 : -1)));

//   const products = useSelector((state) => state.products);

//   const auth = useSelector((state) => state.auth);

//   const { width } = useWindowDimensions();

//   const [errors, setErrors] = useState({});

//   // External reference
//   const uniqueId = () => parseInt(Date.now() * Math.random()).toString();

//   const getConstraints = (attributes) => {
//     // constraints for the form values
//     let constraints = {
//       ...companySchema,
//       companyRegNo: {
//         presence: {
//           allowEmpty:
//             attributes.workCompanyOptions === "Company" ? true : true,
//         },
//       },
//       vatNumber: {
//         presence: {
//           allowEmpty:
//             attributes.workCompanyOptions === "Company" ? true : true,
//         },
//       },
    
//       idNumber: {
//         presence: {
//           allowEmpty:
//             attributes.workCompanyOptions === "Company" ? false : false,
//         },
//         format: {
//           pattern: /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/,
//           message: "^ Invalid South African ID Number",
//         },
//       },
//     };

//     drd?.attributes?.map((d) => {
//       constraints = {
//         ...constraints,
//         [d.name.replaceAll(" ", "")]: {
//           presence: {
//             allowEmpty: false,
//           },
//         },
//       };
//       return null;
//     });
//     return constraints;
//   };
 
//   const getProrate = (string, name) => {
//     const arr = string.split(",");
//     let obj = {};
//     arr.forEach((a) => { const val = a.split(":"); obj[val[0]] = val[1]; })
//     return obj;
//   }

//   const paymentFrequency = useSelector(state => state.products.paymentFrequency);

//   const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);


//   // Submitting the form
//   const handleSubmit = async () => {
//     setSubmitClicked(true);
//     // Updating the company details values to the store.
//     dispatch(updateValues(drd));
//     dispatch(updateCompanyDetails(attributes));

//     const constraints = getConstraints(attributes);
//     delete constraints.companyRegNo;
//     delete constraints.vatNumber;
//     delete constraints.workCompany;
//     if(practicalCourse?.value === 'NO' && theoryCourse?.value === 'NO'){
//       delete constraints['Proficiencycoursecertificatenumber']
//     }


//     // <---- Consolidating Errors values ---->
//     const errors = validate(attributes, constraints);
//     if (errors) {
//       setErrors(errors);
//       return;
//     } else {
//       setErrors({});
//     }

//     const riskIndex = riskAttributes.findIndex((risk) => risk.code === "DRD");
//     riskAttributes[riskIndex] = drd;

//     setIsLoading(true);
//     const policyParams = {
//       policies: [
//         {
//           PolicyStatus: "Quoted",
//           currencyCode: "ZAR",
//           members: [
//             {
//               ...attributes,
//               customerIdentityType: 0,
//               nationalityId: attributes.idNumber,
//               companyName: `${attributes.title} ${attributes.firstName} ${attributes.lastName}`,
//               pin: attributes.postalStateCode
//             },
//           ],
//           beneficiaries: [
//             {
//               ...attributes,
//               customerIdentityType: 0,
//               nationalityId: attributes.idNumber,
//               companyName: `${attributes.title} ${attributes.firstName} ${attributes.lastName}`,
//               pin: attributes.postalStateCode
//             },
//           ],
//           attributes: [],
//           risks: [
//             {
//               externalReference: uniqueId(),
//               attributes: riskAttributes?.find((x) => x.code === 'DIR').attributes.map((d) => ({
//                       name: d.name,
//                       value: d.name === 'Payment Frequency' ? paymentFrequency :  d.value,
//                     })),
//               valuations: products.selectedProduct.valuations.values,
//               productRiskReference: riskAttributes[1]?.instanceId,
//               price: products.selectedProduct?.rating.price,
//               tax: products.selectedProduct?.rating.tax,
//               discount: products.selectedProduct?.rating.discount,
//               total: products.selectedProduct?.rating.total
//             },
//             {
//               externalReference: uniqueId(),
//               attributes: riskAttributes?.find((x) => x.code === 'DRD').attributes.map((d) => ({
//                       name: d.name,
//                       value: d.value,
//                     })),
//                 valuations: [],
//                 productRiskReference: riskAttributes[2]?.instanceId,
//                 price: 0.0,
//                 tax: 0.0,
//                 discount: 0.0,
//                 total: 0.0,
//             },
//             {
//               externalReference: uniqueId(),
//               attributes: [
//                 {
//                   name: 'Payment Frequency',
//                   value: paymentFrequency
//                 },
//                 {
//                   name: 'Annual Premium',
//                   value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).annualPremium
//                 },
//                 {
//                   name: 'Pro-Rata Annual Premium',
//                   value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).prorataAnnualPremium
//                 },
//                 {
//                   name: 'Monthly premium',
//                   value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).monthlyPremium
//                 },
//                 {
//                   name: 'Pro-Rata Premium',
//                   value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).prorataPremium
//                 },
//                 {
//                   name: 'Excess Structure',
//                   value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).excess
//                 },
//                 {
//                   name: 'First Collection Date',
//                   value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).firstCollectionDate
//                 },
//                 {
//                   name: '1st Collection Amount',
//                   value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message).firstCollectionAmount
//                 },
//                 {
//                   name: 'Policy Start Date',
//                   value: moment(getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message)?.policyStartDate).format("DD/MM/YYYY")
//                 },
//                 {
//                   name: 'Policy End Date',
//                   value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message)?.policyEndDate
//                 },
//                 {
//                   name: 'Policy Renewal Date',
//                   value: getProrate(products.selectedProduct.risks && products.selectedProduct.risks[0].rating.message)?.policyRenewalDate
//                 }
//               ],
//               valuations: [],
//               productRiskReference: riskAttributes[0]?.instanceId ,
//               price: 0.0,
//               discount: 0.0,
//               tax: 0.0,
//               total: 0.0,
//             }
//           ],
//           bankAccounts: null,
//           productOptionReference:
//           products.selectedProduct.productOptionReference,
//           status: 15,
//         },
//       ],
//       networkId: agentOnBoarding?.network?.instanceId,
//       companyId: agentOnBoarding?.company?.code,
//       agentId: agentOnBoarding?.agent?.code,
//     };

//     // return;
//     try {
//       // Dispatching the values and call the API for Create policy.
//       const res = await dispatch(CreatePolicy(policyParams));
//       setIsLoading(false);
//       handleNext(res);
//     } catch (err) {
//       console.log(err);
//     }
//   };
  

//   return (
//     <div>
//       {/* <---------- Title -------> */}
//       <h4>{product?.name}</h4>

//       {/* <------- Sub title ------> */}
//       <h6>Add your details</h6>

//       <Divider row={2} />

//       {/* <------- Attributes Starts ------> */}
//       {/* <Row className="mt-4">
//         <Col sm={6}>
//           <SelectWithRadioHorizontal
//             onChange={handleChange}
//             onExtraChange={handleChange}
//             required={false}
//             name="workCompanyOptions"
//             options={JSON.parse(companyOrSoleProprietor.settings).items}
//             value={attributes.workCompanyOptions}
//             label="Company or Sole Proprietor"
//             extras={
//               attributes.workCompanyOptions === "Company"
//                 ? [
//                     {
//                       type: "text",
//                       label: "- Company name",
//                       name: "workCompany",
//                       id: "workCompany",
//                       value: attributes["workCompany"],
//                       errors: errors.workCompany,
//                     },
//                     {
//                       type: "text",
//                       label: "- Company Reg No, if registered",
//                       name: "companyRegNo",
//                       id: "companyRegNo",
//                       value: attributes["companyRegNo"],
//                       errors: errors.companyRegNo,
//                     },
//                     {
//                       type: "text",
//                       label: "- VAT No, if registered",
//                       name: "vatNumber",
//                       id: "vatNumber",
//                       value: attributes["vatNumber"],
//                       errors: errors.vatNumber,
//                     },
//                   ]
//                 : [
//                     {
//                       type: "text",
//                       label: "- VAT Number, if registered",
//                       name: "vatNumber",
//                       id: "vatNumber",
//                       value: attributes["vatNumber"],
//                     },
//                     {
//                       type: "text",
//                       label: "- ID No",
//                       name: "idNumber",
//                       id: "idNumber",
//                       errors: errors.idNumber ?  [errors.idNumber[0]]: null,
//                       value: attributes.idNumber,
//                     },
//                   ]
//             }
//           />
//         </Col>
//       </Row> */}

//       <VerticalSpacer rows={2} />

//       <Row>
       

//         <Col sm={3}>
//            <div className="form-group mt-4 mb-4">
//             <label className="form-label">
//               Title
//             </label>
//             <select
//               style={{
//                 height: "38px",
//               }}
//               className={`form-select ${!attributes.title && 'select-empty'} ${errors.title?.length && "error-input"}`}
//               value={attributes.title}
//               required={true}
//               onChange={handleChange}
//               placeholder="Title"
//               name="title"
//             >
//                <option hidden selected>Title</option>
//               {
//                 titles?.map((items, index) => (
//                   <option style={{color:"black"}} value={items} key={index}>
//                     {items}
//                   </option>
//                 ))
//               }
//             </select>
//             {errors?.title && (
//               <span className="errors">
//                 {errors?.title?.map((error) => error).join(", ")}
//               </span>
//             )}
//           </div>
//           <TextInput
//             label="Mobile number"
//             placeholder="Contact person mobile"
//             name="mobilePhone"
//             errors={errors.mobilePhone}
//             onChange={handleChange}
//             required={true}
//             value={attributes.mobilePhone}
//           />
//         </Col>

//         <Col sm={3}>
//         <TextInput
//             label="First name"
//             placeholder="Contact person"
//             name="firstName"
//             errors={errors.firstName}
//             onChange={handleChange}
//             required={true}
//             value={attributes.firstName}
//           />

//           <TextInput
//             label="Email"
//             placeholder="Contact person email"
//             name="eMail"
//             errors={errors.eMail}
//             onChange={handleChange}
//             required={true}
//             value={attributes.eMail}
//           />
//         </Col>
//         <Col sm={3}>
//         <TextInput
//             label="Last name"
//             placeholder="Contact person"
//             name="lastName"
//             errors={errors.lastName}
//             onChange={handleChange}
//             required={true}
//             value={attributes.lastName}
//           />
          
//         </Col>

//         <Col sm={3}>
//           <TextInput
//             label="ID Number"
//             placeholder="ID Number"
//             name="idNumber"
//             errors={errors.idNumber}
//             onChange={handleChange}
//             required={true}
//             value={attributes.idNumber}
//           />
//         </Col>
//       </Row>

      
//       <Row className="mt-5">
//         <Col xs={6} md={2} className="d-flex justify-content-start">
//           <ButtonWithDownArrow
//             onClick={handleBack}
//             type="left"
//             name="Back"
//             bg="#dd302a"
//             color="white"
//           />
//         </Col>
//         {width > 430 && <Col xs={0} md={8} />}
//         <Col xs={6} md={2} className="d-flex justify-content-end">
//           <ButtonWithDownArrow
//             onClick={handleSubmit}
//             type="right"
//             name="Next"
//             bg="#dd302a"
//             color="white"
//           />
//         </Col>
//       </Row>

//       {/* <------- Attributes Starts ------> */}

//       {isLoading && (
//         <div className="gif-loader-fullscreen">
//           <img src={GIFLoader} alt="GIFLoader"></img>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Step4;

// const companySchema = {
//   workAddress: {
//     presence: { allowEmpty: false },
//   },
//   workCity: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: "[a-zA-Z ]+",
//       flags: "i",
//       message: "^ City can only contain alphabets",
//     },
//   },
//   workCompany: {
//     presence: { allowEmpty: false },
//   },
//   postalStateCode: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: "^[0-9]+$",
//       message: "^ Postal code can only contain number",
//     },
//   },
//   suburb: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: "[a-zA-Z ]+",
//       flags: "i",
//       message: "^ Suburb can only contain alphabets",
//     },
//   },
//   workProvince: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: "[a-zA-Z ]+",
//       flags: "i",
//       message: "^ Province can only contain alphabets",
//     },
//   },

//   title: {
//     presence: { allowEmpty: false },
//   },
//   country: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: "[a-zA-Z ]+",
//       flags: "i",
//       message: "^ Country can only contain alphabets",
//     },
//   },
//   firstName: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: "[a-zA-Z ]+",
//       flags: "i",
//       message: "^ Firstname can only contain alphabets",
//     },
//   },
//   idNumber: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/,
//       message: "^ ID number can only contain alphabets",
//     },
//   },
//   eMail: {
//     presence: { allowEmpty: false },
//     email: true,
//   },
//   lastName: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: "[a-zA-Z ]+",
//       flags: "i",
//       message: "^ Lastname can only contain alphabets",
//     },
//   },
//   mobilePhone: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/,
//       message: "^Mobile phone can only contain number",
//     },
//   },
// };

// const titles = [
//   'Adv',
//   'Dr',
//   'Miss',
//   'Mr',
//   'Mrs',
//   'Ms',
//   'Prof',
//   'Rev',
//   'Sir',
//   'NONE'
// ]