import React from "react";

// <--------- Importing Components from React-bootstrap --------->
import { Container, Dropdown,  Nav, Navbar, NavDropdown } from "react-bootstrap";

// <--------- Importing Images --------->
import { logofooter, logoSS } from "../../assets/images/images";

// <--------- Importing Essential components --------->
import ButtonWithDownArrow from "../Buttons/ButtonWithArrow";
import DropdownButton from "../Buttons/DropdownButton";

import Button from "../Buttons/Button";

// <--------- Importing Navbar style sheets ---------->
import Style from "./Navbar.module.css";
import { useDispatch } from "react-redux";
import { addCrumb } from "store/actions/common";
import { useLocation } from "react-router-dom";
import { clearState } from "store/actions/products";
import classNames from "classnames";

// Get window dimensions
import useWindowDimensions from "utils/useWindowDimension";

const NavbarComponent = ({isCommercial}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { width } = useWindowDimensions();

  return (
    <Navbar bg="white" expand="lg">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/*<--------- App Logo Starts ----------> */}

        <Navbar.Brand href="/">
          <img
            className={Style.logo}
            src={logofooter}
            alt="logo"
            width={width < 770 ? "130px" : "120px"}
          />
        </Navbar.Brand>

        {width < 770 && (
          // <ButtonWithDownArrow
          //   name="Buy Insurance"
          //   bg="var(--tertiary)"
          //   color="white"
          //   onClick={() => {
          //     dispatch(clearState()).then(() => {
          //       if (location.pathname === "/forms") {
          //         window.location.reload();
          //       } else {
          //         window.location.href = "/forms";
          //       }
          //     });
          //   }}
          // />
          <DropdownButton
             bg="var(--tertiary)"
             color="white"
             name="Buy Insurance"
         // isCommercial={isCommercial}
             link="/forms"
           />
        )}
        {/*<--------- App Logo Ends ------------> */}

        {/*<--------- Menus Starts -------------> */}

        <Navbar.Collapse className="navbar-expand-md" id="basic-navbar-nav">
          <Nav className="inline-flex justify-content-end w-100">
            <div
              className={classNames(
                width > 770 ? `d-flex` : ``,
                Style.header_navs
              )}
              style={{
                width: width > 770 ? (width < 1025 ? "50%" : "40%") : "100%",
              }}
            >
              <Nav.Link href="/" active={location.pathname === "/"}>
                Home
              </Nav.Link>
              <Nav.Link href="/about" active={location.pathname === "/about"}>
                About
              </Nav.Link>
              {/* <Nav.Link
                href="/fast-facts"
                active={location.pathname === "/fast-facts"}
                onClick={() => {
                  dispatch(
                    addCrumb([{ link: "/fast-facts", name: "Fast Facts" }])
                  );
                }}
              >
                Fast Facts
              </Nav.Link> */}
              <NavDropdown title="Fast Facts" id="basic-nav-dropdown">
 <NavDropdown.Item href="/contact" active={location.pathname === "/contact"}>
                  Leisure Drone
              </NavDropdown.Item>

                <NavDropdown.Item href="/commercial-facts" active={location.pathname === "/commercial-facts"} onClick={() => {
                  dispatch(
                    addCrumb([{ link: "/commercial-facts", name: "Commercial Fast Facts" }])
                  );
                }}>Commercial Drone</NavDropdown.Item>
            </NavDropdown>
              <Nav.Link
                href="/contact"
                active={location.pathname === "contact"}
              >
                Contact
              </Nav.Link>
            </div>
            <div className={width > 374 ? "d-flex" : ""}>
              {width > 770 && (
                // <ButtonWithDownArrow
                //   name="Buy Insurance"
                //   bg="var(--tertiary)"
                //   color="white"
                //   onClick={() => {
                //     dispatch(clearState()).then(() => {
                //       if (location.pathname === "/forms") {
                //         window.location.reload();
                //       } else {
                //         window.location.href = "/forms";
                //       }
                //     });
                //   }}
                // />
                <DropdownButton
                bg="var(--tertiary)"
                color="white"
                name="Buy Insurance"
                // isCommercial={isCommercial}
                link="/forms"
                />
              )}
              <Button
                name="Submit Claim"
                shadow={true}
                bg="white"
                color="#6e6f6c"
                onClick={() => (window.location.href = "/claims")}
              />
            </div>
          </Nav>
        </Navbar.Collapse>

        {/*<--------- Menus Ends ----------> */}
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;

