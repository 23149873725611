// // default imports for react project
// import React from "react";

// // import default styles from react-bootstrap package
// import { Col, Row } from "react-bootstrap";

// // Import images from assets folder
// import { main } from "assets/images/images";
// import { card_content_img, card_content_img2,   card_content_img3} from "assets/images/images";

// //   import custom component
// import { BlackArrayText } from "components/Heading";
// import BodyButton from "../Buttons/BodyButton";
// import CardHeader from "./CardHeader";
// import { card_header_img } from "assets/images/images";

// const DATA = [
//   {
//     title: "Drone Insurance 101",
//     sub: [
//       "Covers loss, theft and damage to your drone",
//       "Third party legal liability",
//       "Offered to Qualified & Unqualified persons",
//       "Competency qualifications will enable access to enhanced benefits",
//     ],
//     imp: "Acquire your competency qualification to get the best cover",
//     amount: "R 43 per Month",
//     icon: card_content_img,
//   },
//   {
//     title: "Drone Proficiency Insurance",
//     sub: [
//       "Covers loss, theft and damage to your drone",
//       "Third party legal liability",
//       "Offered to Qualified & Unqualified persons",
//       "Competency qualifications will enable access to enhanced benefits",
//     ],
//     imp: "Acquire your competency qualification to get the best cover",
//     amount: "R 513 Annually",
//     icon: card_content_img2,
//   },
// ];

// const COMMERCIAL_DATA = [
//   {
//     title: "Commercial Drone Insurance",
//     sub: [
//       "Third party legal liability",
//       "Covers loss and damage to RPAS",
//       "Hull and Spares",
//       "Additional optional extensions: Transit and Theft and more...",
//       "What is required for quote and cover?",
//       "Valid remote pilots license (RPL)",
//       "RPAS operator certificate (ROC)",
//       "Completed, signed and dated proposal form"
//     ],
//     imp: "Requirements to request quote: ",
//     imp1:"Completed proposal form, RPL and ROC licenses",
//     amount: "Premium payable monthly or annually",
//     icon: card_content_img3,
//   },
// ];


// // React functional component starts here..
// function Card2({ width ,isCommercial}) {
//   return (
//     <Col lg xs={12}>
//       <div className="m-0 pb-5 card__homepage">
//         <Row className="m-0 mt-3 card__header__container">
//           <Col lg={7} md={7} xs={12} className={"p-0"}>
//             <CardHeader image={card_header_img} title="Leisure Drone Insurance" />
//           </Col>
//           <Col lg={5} md={5} className="p-0">
//             <div
//               className={`card__footer__bottom  ${
//                 width < 430 ? "mt-2 text-center" : "d-flex justify-content-end"
//               }`}
//             >
//               <div className="amount">
//                 <span>From:</span>
//                 <span> R 43 pm</span>
//               </div>
//               <div
//                 className={width < 430 ? `d-flex justify-content-center` : ""}
//               >
//                 <BodyButton
//                   color="white"
//                   bg="#0b749c"
//                   name="Get a Quote"
//                   size={16}
//                   marginR={20}
//                   marginL={width > 770 && 100}
//                   weight={900}
//                   width={180}
//                   marginT={0}
//                   black={width > 430 ? false : true}
//                   // link="/forms"
//                   link="/drone-covered"
//                 />
//               </div>
//             </div>
//           </Col>
//         </Row>
//         <Row className="m-0">
//           <div className="card__list__header text-left mt-3">
//             With the considerable growth of the drone industry in recent years
//             and its continued expansion expected, Drone Covered is proud to be
//             the first specialist drone insurance available to non-commercial
//             drone pilots in South Africa, offering hull, loss and theft cover,
//             as well as third party and liability cover up to R2,5 million.
//           </div>
//           <div>
//             <Row className={`${width > 770 ? 'mt-5' : 'mt-4'} text-center`}>
//               {DATA.map((x, i) => (
//                 <Col
//                   lg={6}
//                   key={i}
//                   sm={12}
//                   className={width < 770 && width > 430 ? "mt-4" : ""}
//                 >
//                   <Row>
//                     <Col
//                       lg={4}
//                       sm={4}
//                       className={
//                         width < 430
//                           ? "mt-3 d-flex flex-column"
//                           : "d-flex flex-column"
//                       }
//                     >
//                       <img
//                         src={x.icon} 
//                         alt="avatar"
//                         style={
//                           width < 430 ? { width: "150px", margin: "auto" }  : {width: "192px", height:"192px"}
//                         }
//                       />
//                       <BlackArrayText
//                         style={
//                           width > 430
//                             ? width < 770
//                               ? { marginTop: "2rem", color: "#0B749C" }
//                               : { marginTop: "3rem", color: "#0B749C" }
//                             : { marginBottom: "2rem", color: "#0B749C" }
//                         }
//                         link="/faq"
//                       >
//                         [ See FAQ's ]
//                       </BlackArrayText>
//                     </Col>
//                     <Col lg={8} sm={8}>
//                       <div className="card__avatar__text">{x.title}</div>
//                       <div
//                         style={{ textAlign: width < 430 ? "center" : "left" }}
//                       >
//                         <ul style={{ paddingLeft: "1.2rem" }}>
//                           {x.sub.map((s, i) => (
//                             <li key={i}>{s}</li>
//                           ))}
//                         </ul>
//                       </div>
//                       <div className="card__imp__text">*{x.imp}</div>
//                       <div className="card__amount">
//                         From: <strong>{x.amount}</strong>
//                       </div>
//                       <div className={width < 430 ? `d-flex justify-content-center` : ""}>
//                         <BodyButton
//                           color="white"
//                           bg="#0b749c"
//                           name="Get cover now"
//                           size={16}
//                           width={200}
//                           weight={700}
//                           marginT={20}
//                           black={width > 430 ? false : true}
//                           // link="/forms"
//                           link="/drone-covered"
//                         />
//                       </div>
//                     </Col>
//                   </Row>
//                 </Col>
//               ))}
//             </Row>
//           </div>
//         </Row>
//         {/* Commercial drone */}
//         <Row className="m-0 mt-5 card__header__container">
//           <Col lg={7} md={7} xs={12} className={"p-0"}>
//             <CardHeader image={card_header_img} title="Commercial Drone Insurance" />
//           </Col>
//           <Col lg={5} md={5} className="p-0">
//             <div
//               className={`card__footer__bottom  ${
//                 width < 430 ? "mt-2 text-center" : "d-flex justify-content-end"
//               }`}
//             >
//               <div style={{color:"white"}}>
//                 <span>Premium payable monthly or annually</span>
//               </div>
//               <div
//                 className={width < 430 ? `d-flex justify-content-center` : ""}
//               >
//                 <BodyButton
//                   color="white"
//                   bg="#0b749c"
//                   name="Get a Quote"
//                   size={16}
//                   marginR={20}
//                   marginL={width > 770 && 62}
//                   weight={900}
//                   width={180}
//                   marginT={0}
//                   black={width > 430 ? false : true}
//                   // link="/forms"
//                   link="/Commercial-drone"
//                   isCommercial={isCommercial}
//                 />
//               </div>
//             </div>
//           </Col>
//         </Row>
//         <Row className="m-0">
//           <div className="card__list__header text-left mt-3">
//           Entities have realised the benefits of applying drones in their businesses and how they can improve their operational efficiencies, save costs and be used to add new streams of revenue. We can help you to protect such a valuable asset.
//           </div>
//           <div>
//             <Row className={`${width > 770 ? 'mt-5' : 'mt-4'} text-center`}>
//               {COMMERCIAL_DATA.map((x, i) => (
//                 <Col
//                   lg={6}
//                   key={i}
//                   sm={12}
//                   className={width < 770 && width > 430 ? "mt-4" : ""}
//                 >
//                   <Row>
//                     <Col
//                       lg={4}
//                       sm={4}
//                       className={
//                         width < 430
//                           ? "mt-3 d-flex flex-column"
//                           : "d-flex flex-column"
//                       }
//                     >
//                       <img
//                         src={x.icon} 
//                         alt="avatar"
//                         style={
//                           width < 430 ? { width: "150px", margin: "auto" }  : {width: "192px", height:"192px"}
//                         }
//                       />
//                       <BlackArrayText
//                         style={
//                           width > 430
//                             ? width < 770
//                               ? { marginTop: "2rem", color: "#0B749C" }
//                               : { marginTop: "3rem", color: "#0B749C" }
//                             : { marginBottom: "2rem", color: "#0B749C" }
//                         }
//                         link="/faq"
//                       >
//                         [ See FAQ's ]
//                       </BlackArrayText>
//                     </Col>
//                     <Col lg={8} sm={8}>
//                       <div className="card__avatar__text">{x.title}</div>
//                       <div
//                         style={{ textAlign: width < 430 ? "center" : "left" }}
//                       >
//                         <ul style={{ paddingLeft: "1.2rem"}} >
//                           {x.sub.map((s, i) => 
//                           (
//                             <li key={i}>{s}</li>
//                           )
//                           )}
//                         </ul>
//                       </div>
//                       <div className="card__imp__text">*{x.imp}</div>
//                       <div className="card__imp__text">{x.imp1}</div>
//                       <div className="card__amount">
//                         {x.amount}
//                       </div>
//                       <div className={width < 430 ? `d-flex justify-content-center` : ""}>
//                         <BodyButton
//                           color="white"
//                           bg="#0b749c"
//                           // bg="#da291c" 
//                           name="Get a Quote"
//                           size={16}
//                           width={200}
//                           weight={700}
//                           marginT={20}
//                           black={width > 430 ? false : true}
//                           // link="/forms"
//                           link="/Commercial-drone"
//                           isCommercial={isCommercial}
//                           // onClick={onClick}
//                         />
//                       </div>
//                     </Col>
//                   </Row>
//                 </Col>
//               ))}
//             </Row>
//           </div>
//         </Row>
//         {/* Commercial drone--ends--- */}
//       </div>
//     </Col>
//   );
// }

// export default Card2;


// default imports for react project
import React from "react";

// import default styles from react-bootstrap package
import { Col, Row } from "react-bootstrap";

// Import images from assets folder
import { main } from "assets/images/images";
import { card_content_img, card_content_img2,   card_content_img3} from "assets/images/images";

//   import custom component
import { BlackArrayText } from "components/Heading";
import BodyButton from "../Buttons/BodyButton";
import CardHeader from "./CardHeader";
import { card_header_img } from "assets/images/images";

const DATA = [
  {
    title: "Drone Insurance 101",
    sub: [
      "Covers loss, theft and damage to your drone",
      "Third party legal liability",
      "Offered to Qualified & Unqualified persons",
      "Competency qualifications will enable access to enhanced benefits",
    ],
    imp: "Acquire your competency qualification to get the best cover",
    amount: "R 43 per Month",
    icon: card_content_img,
  },
  {
    title: "Drone Proficiency Insurance",
    sub: [
      "Covers loss, theft and damage to your drone",
      "Third party legal liability",
      "Offered to Qualified & Unqualified persons",
      "Competency qualifications will enable access to enhanced benefits",
    ],
    imp: "Acquire your competency qualification to get the best cover",
    amount: "R 513 Annually",
    icon: card_content_img2,
  },
];

const COMMERCIAL_DATA = [
  {
    title: "Commercial Drone Insurance",
    sub: [
      "Third party legal liability",
      "Covers loss and damage to RPAS",
      "Hull and Spares",
      "Additional optional extensions: Transit and Theft and more...",
      "What is required for quote and cover?",
      "Valid remote pilots license (RPL)",
      "RPAS operator certificate (ROC)",
      "Completed, signed and dated proposal form"
    ],
    imp: "Requirements to request quote: ",
    imp1:"Completed proposal form, RPL and ROC licenses",
    amount: "Premium payable monthly or annually",
    icon: card_content_img3,
  },
];


// React functional component starts here..
function Card2({ width ,isCommercial}) {
  return (
    <Col lg xs={12}>
      <div className="m-0 pb-5 card__homepage">
        <Row className="m-0 mt-3 card__header__container">
          <Col lg={7} md={7} xs={12} className={"p-0"}>
            <CardHeader image={card_header_img} title="Leisure Drone Insurance" />
          </Col>
          <Col lg={5} md={5} className="p-0">
            <div
              className={`card__footer__bottom  ${
                width < 430 ? "mt-2 text-center" : "d-flex justify-content-end"
              }`}
            >
              <div className="amount">
                <span>From:</span>
                <span> R 43 pm</span>
              </div>
              <div
                className={width < 430 ? `d-flex justify-content-center` : ""}
              >
                <BodyButton
                  color="white"
                  bg="#0b749c"
                  name="Get a Quote"
                  size={16}
                  marginR={20}
                  marginL={width > 770 && 100}
                  weight={900}
                  width={180}
                  marginT={0}
                  black={width > 430 ? false : true}
                  // link="/forms"
                  link="/contact"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="m-0">
          <div className="card__list__header text-left mt-3">
            With the considerable growth of the drone industry in recent years
            and its continued expansion expected, Drone Covered is proud to be
            the first specialist drone insurance available to non-commercial
            drone pilots in South Africa, offering hull, loss and theft cover,
            as well as third party and liability cover up to R2,5 million.
          </div>
          <div>
            <Row className={`${width > 770 ? 'mt-5' : 'mt-4'} text-center`}>
              {DATA.map((x, i) => (
                <Col
                  lg={6}
                  key={i}
                  sm={12}
                  className={width < 770 && width > 430 ? "mt-4" : ""}
                >
                  <Row>
                    <Col
                      lg={4}
                      sm={4}
                      className={
                        width < 430
                          ? "mt-3 d-flex flex-column"
                          : "d-flex flex-column"
                      }
                    >
                      <img
                        src={x.icon} 
                        alt="avatar"
                        style={
                          width < 430 ? { width: "150px", margin: "auto" }  : {width: "80%"}
                        }
                      />
                      <BlackArrayText
                        style={
                          width > 430
                            ? width < 770
                              ? { marginTop: "2rem", color: "#0B749C" }
                              : { marginTop: "3rem", color: "#0B749C" }
                            : { marginBottom: "2rem", color: "#0B749C" }
                        }
                        link="/faq"
                      >
                        [ See FAQ's ]
                      </BlackArrayText>
                    </Col>
                    <Col lg={8} sm={8}>
                      <div className="card__avatar__text">{x.title}</div>
                      <div
                        style={{ textAlign: width < 430 ? "center" : "left" }}
                      >
                        <ul style={{ paddingLeft: "1.2rem" }}>
                          {x.sub.map((s, i) => (
                            <li key={i}>{s}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="card__imp__text">*{x.imp}</div>
                      <div className="card__amount">
                        From: <strong>{x.amount}</strong>
                      </div>
                      <div className={width < 430 ? `d-flex justify-content-center` : ""}>
                        <BodyButton
                          color="white"
                          bg="#0b749c"
                          name="Get cover now"
                          size={16}
                          width={200}
                          weight={700}
                          marginT={20}
                          black={width > 430 ? false : true}
                          // link="/forms"
                          link="/contact"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </div>
        </Row>
        {/* Commercial drone */}
        <Row className="m-0 mt-5 card__header__container">
          <Col lg={7} md={7} xs={12} className={"p-0"}>
            <CardHeader image={card_header_img} title="Commercial Drone Insurance" />
          </Col>
          <Col lg={5} md={5} className="p-0">
            <div
              className={`card__footer__bottom  ${
                width < 430 ? "mt-2 text-center" : "d-flex justify-content-end"
              }`}
            >
              <div style={{color:"white"}}>
                <span>Premium payable monthly or annually</span>
              </div>
              <div
                className={width < 430 ? `d-flex justify-content-center` : ""}
              >
                <BodyButton
                  color="white"
                  bg="#0b749c"
                  name="Get a Quote"
                  size={16}
                  marginR={20}
                  marginL={width > 770 && 62}
                  weight={900}
                  width={180}
                  marginT={0}
                  black={width > 430 ? false : true}
                  // link="/forms"
                  link="/Commercial-drone"
                  isCommercial={isCommercial}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="m-0">
          <div className="card__list__header text-left mt-3">
          Entities have realised the benefits of applying drones in their businesses and how they can improve their operational efficiencies, save costs and be used to add new streams of revenue. We can help you to protect such a valuable asset.
          </div>
          <div>
            <Row className={`${width > 770 ? 'mt-5' : 'mt-4'} text-center`}>
              {COMMERCIAL_DATA.map((x, i) => (
                <Col
                  lg={6}
                  key={i}
                  sm={12}
                  className={width < 770 && width > 430 ? "mt-4" : ""}
                >
                  <Row>
                    <Col
                      lg={4}
                      sm={4}
                      className={
                        width < 430
                          ? "mt-3 d-flex flex-column"
                          : "d-flex flex-column"
                      }
                    >
                      <img
                        src={x.icon} 
                        alt="avatar"
                        style={
                          width < 430 ? { width: "150px", margin: "auto" }  : {width: "80%"}
                        }
                      />
                      <BlackArrayText
                        style={
                          width > 430
                            ? width < 770
                              ? { marginTop: "2rem", color: "#0B749C" }
                              : { marginTop: "3rem", color: "#0B749C" }
                            : { marginBottom: "2rem", color: "#0B749C" }
                        }
                        link="/faq"
                      >
                        [ See FAQ's ]
                      </BlackArrayText>
                    </Col>
                    <Col lg={8} sm={8}>
                      <div className="card__avatar__text">{x.title}</div>
                      <div
                        style={{ textAlign: width < 430 ? "center" : "left" }}
                      >
                        <ul style={{ paddingLeft: "1.2rem"}} >
                          {x.sub.map((s, i) => 
                          (
                            <li key={i}>{s}</li>
                          )
                          )}
                        </ul>
                      </div>
                      <div className="card__imp__text">*{x.imp}</div>
                      <div className="card__imp__text">{x.imp1}</div>
                      <div className="card__amount">
                        {x.amount}
                      </div>
                      <div className={width < 430 ? `d-flex justify-content-center` : ""}>
                        <BodyButton
                          color="white"
                          bg="#0b749c"
                          // bg="#da291c" 
                          name="Get a Quote"
                          size={16}
                          width={200}
                          weight={700}
                          marginT={20}
                          black={width > 430 ? false : true}
                          // link="/forms"
                          link="/Commercial-drone"
                          isCommercial={isCommercial}
                          // onClick={onClick}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </div>
        </Row>
        {/* Commercial drone--ends--- */}
      </div>
    </Col>
  );
}
export default Card2;

