// default imports for react project
import React, { useLayoutEffect, useEffect } from "react";

// import for dispatching action in react
import { useDispatch, useSelector } from "react-redux";

// import default style component from react-bootstrap package
import { Container, Row } from "react-bootstrap";

// import classname package to combine module css and normal css
import classNames from "classnames";

// import function from actions
import { addCrumb } from "store/actions/common";

//   import custom component
import BreadCrumb from "components/Breadcrumb";
import { H1Red, H1Grey } from "components/Heading";
import { H3Grey } from "components/Heading";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

// React functional component starts here..
function Claims() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentToken =  useSelector(state => state.auth.currentUserToken)

  // react layout hook to perform init operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(addCrumb([{ link: "/claims", name: "Claims" }]));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload:parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])


  return (
    <div>
      <Container className={classNames("content")}>
        <BreadCrumb />
        <Row className={classNames("mt-5")}>
          <div>
            <H1Grey style={{ fontFamily: "var(--primary-font)" }}>
              Submit A Claim
            </H1Grey>
            <br />
            <H3Grey>
            The following initial information will be required and must be
            submitted by you:
            </H3Grey>
          </div>
        </Row>
        <Row className={classNames("mt-5")}>
          <H1Red>Tell us about the claim</H1Red>
          <div>
           <b>
           We must receive notice of your claim as soon as is reasonably
            possible </b> but no later than 30 days after the insured event, loss or
            damage occurred. If you do not do so, you lose your right to bring a
            claim against us. Our contact details appear in the policy.
           
          </div>
          <br />
          <br />
          <br />
          <div>
            <strong>You must give us:</strong>
          </div>
          <br />
          <div>
            <ol>
              <li>full details of the claim;</li>
              <li>
                details of any other insurance you may have for the insured
                event
              </li>
              <li>
                proof, statements, and any other information we ask for; and
              </li>
              <li>
                any correspondence or other documents (for example, court papers
                or legal letters you have received in relation to the claim)
              </li>
              <li>
                Data captured by inbuilt processors, of your drone, the access
                details of which must also be supplied
              </li>
            </ol>
          </div>
          <div>
            <strong>Email:</strong> info@dronecovered.com
          </div>
          <div>
            <strong>Tel:</strong> +27 87 550 9796
          </div>
          <br />
          <br />
          <div>
          <b>
          We will provide you with the claim form, this will be completed by
            you and submitted with the relevant supporting documentation.{" "}
          </b>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Claims;
